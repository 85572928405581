

<div class="d-flex flex-column">                    
    <div class="contravention-recent-activity-summary-header">
        <div>
            All Activity
            <app-access-control
                    [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.VIEW_DRIVER_LOGS">
                <driver-log [getSearchRequestCallback]="getDriverLogSearchRequest" (onSelectionChanged)="onDriverLogsSelectionChanged($event)"></driver-log>
            </app-access-control>
        </div>
        <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.ACTIVITY_NOTE">
            <div class="d-flex justify-content-end align-items-center">
                <div class="add-note-icon">
                    <i class="fas fa-plus-circle"></i>
                </div>
                <div class="add-note-link"  (click)="onAddNoteLinkClick()">Add a note</div>
            </div>
        </app-access-control>
    </div>
   
    <div class="mb-3 contravention-recent-activity-group" >                 
        <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
            <div class="contravention-recent-activity-connector"></div>
            <div *ngFor="let recentActivity of recentActivities; let i=index;" class="contravention-recent-activity-container">
                <div class="contravention-recent-activity-dot" [ngClass]="{ 'contravention-recent-activity-dot-submitted': i==0}"></div>
                <div class="contravention-recent-activity-header">
                    <div>{{recentActivity.date}}</div>
                    <div class="d-flex">by {{recentActivity.by}} <span class="log-flag" *ngIf="recentActivity.isFromDriverLogs">Log</span></div>
                </div>
                <div class="contravention-recent-activity-title">{{recentActivity.title}}</div>
                <div *ngIf="recentActivity.body != null && isCancelledByLEArequest(recentActivity)" class="contravention-recent-activity-content">
                    This contravention has been cancelled as the result of   
                    <a [routerLink]="['/requests', recentActivity.body]"
                            [queryParams]="{type: requestType}">
                            {{recentActivity.body}}
                    </a>                       
                </div>                        
                <div *ngIf="recentActivity.body != null && !isCancelledByLEArequest(recentActivity)" class="contravention-recent-activity-content">{{recentActivity.body}}</div>
                <ng-container *ngIf="recentActivity.documents?.length > 0">
                    <div class="contravention-recent-activity-document" *ngFor="let document of recentActivity.documents">
                        <img class="download-file-icon" src="assets/images/download-file.png" />
                        <a (click)="onRecentActivityDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                    </div>
                </ng-container>
                <app-access-control 
                    *ngIf="canModifyNote(recentActivity)"
                    [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="contravention-recent-activity-edit">
                        <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                        <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                    </div>
                </app-access-control>
            </div>                    
        </ng-container>                                      
    </div>
    
</div>
