import { DateUtil } from './../../../../../../../libs/shared/helpers/date-util';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DocumentTypes, EventTypes, ReviewMethodTypes, ReviewOutcomeTypes, ReviewStatusTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Document } from '@apis/shared/models/document.model';
import { ReviewOutcome } from '@apis/shared/models/review-outcome.model';
import { Review } from '@apis/shared/models/review.model';
import { Event } from '@apis/shared/models/event.model';
import { ReviewOutcomeType } from '@apis/shared/models/types/review-outcome-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from "file-saver";
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReviewService } from '../../../shared/services/review.service';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { AdjudicationService } from '../../../shared/services/adjudication.service';

declare var $: any

@Component({
  selector: 'review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit, OnChanges {
  @Input() review: Review;
  @Output() outcomeUpdated: EventEmitter<Review> = new EventEmitter();
  @Output() documentPublished: EventEmitter<Review> = new EventEmitter();
  @Output() fileClosureCompleted: EventEmitter<Review> = new EventEmitter();

  reviewOutcomeTypes: ReviewOutcomeType[];
  reviewUploads: Document[];
  reviewUploadsDocumentTypeId: number = DocumentTypes.ReviewUploads;
  canEditReviewOutcome: boolean;

  tempFileFolder: string;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  document: Document;
  outcomeErrorMessage: string;
  canEdit: boolean;

  reviewMethodType: number;
  meetingKey: string;
  reviewMethodTypes = ReviewMethodTypes;

  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;
  constructor(private readonly documentService: DocumentService,
    private readonly reviewService: ReviewService,
    readonly localStorageService: LocalStorageService,
    private readonly adjudicationService: AdjudicationService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.reviewOutcomeTypes = this.localStorageService.getReviewOutcomeTypes();

    this.canEdit = this.localStorageService.hasPermission(this.Resource.REVIEW, this.Permission.REVIEW_UPLOADS_EDIT);
    
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.review?.currentValue != null) {

      this.documentRefTypeName = "Reviews";
      this.documentRefTypeNumber = this.review.reviewNumber;
      this.canEditReviewOutcome = (this.review.reviewItems && this.review.reviewItems[0].decisionTypeId == null && this.review.reviewStatusTypeId != ReviewStatusTypes.Cancelled);

      // For written reviews hide review outcome converted
      if (this.review.reviewMethodTypeId == ReviewMethodTypes.Written) {
        this.reviewOutcomeTypes = this.reviewOutcomeTypes.filter(o => o.id != ReviewOutcomeTypes.Converted);
      }

      if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
        this.reviewOutcomeTypes = this.reviewOutcomeTypes.filter(o => o.id != ReviewOutcomeTypes.AbandonedLateEvidence && o.id != ReviewOutcomeTypes.DeemedAbandoned);
      }

      if (this.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview) {
        this.reviewOutcomeTypes = this.reviewOutcomeTypes.filter(o => o.id != ReviewOutcomeTypes.AbandonedNoConsent);
      }

      // Abandoned options are for IRS reviews and third-party seizure reviews only. 
      if (this.review.reviewTypeId != ReviewTypes.IrsReview && this.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview ) {
        this.reviewOutcomeTypes = this.reviewOutcomeTypes.filter(o => o.id != ReviewOutcomeTypes.AbandonedLateEvidence && o.id != ReviewOutcomeTypes.AbandonedOther);
      }

      this.reviewMethodType = this.review.reviewMethodTypeId;
      this.meetingKey = this.review.scheduledEvent?.meetingKey;
      this.populateReviewUploads();
    }
    
  }

  private populateReviewUploads(): void {
    this.reviewUploads = this.review.documents.filter(d => +d.documentTypeId == DocumentTypes.ReviewUploads);
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    this.documentService.downloadDocument("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, document.documentName);
      },
        (error: any) => { });
  }

  onDeleteReviewUploadClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    this.documentService.deleteFinalizedDocument(storageFileName, this.documentRefTypeName, this.documentRefTypeNumber)
      .subscribe((result: any) => { });

    this.reviewService.deleteDocument(this.review.reviewId, document.documentId)
      .subscribe((review: Review) => {
        if (review != null) {
          this.review = review;
          this.populateReviewUploads();
        }
      });
  }

  onReviewUploadAdded(document: Document): void {
    document.isPublished = false;
    this.reviewService.postDocument(this.review.reviewId, document)
      .subscribe((result: Review) => {
        this.review = result;
        this.populateReviewUploads();
      });
  }

  onPublishCheckboxClick(document: Document): void {
    this.document = document;

    if (document.isPublished == false) {
      $('#publishReviewUploadModal').modal('show');
    } else {
      this.document.isPublished = false;
      this.spinner.show();

      this.reviewService.unPublishDocument(this.review.reviewId, this.document.documentId)
        .subscribe((result: Review) => {
          this.review = result;
          this.documentPublished.emit(result);
          this.spinner.hide();
        },
          (error: any) => {
            this.spinner.hide();
          });
    }
  }

  onFileClosureCompleteClick() {
    this.spinner.show();
    this.reviewService.updateFileClosureFlagAsync(this.review.reviewId)
      .subscribe((result: Review) => {
        this.review = result;
        this.fileClosureCompleted.emit(result);
        this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
        });
  }

  onSubmitClick() {
    this.spinner.show();
    this.document.isPublished = true;

    this.reviewService.publishDocument(this.review.reviewId, this.document.documentId)
      .subscribe((result: Review) => {
        $('#publishReviewUploadModal').modal('hide');
        this.review = result;
        this.documentPublished.emit(result);
        this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
        });
  }

  onOutcomeChange(outcome: ReviewOutcome): void {
    this.spinner.show();
    this.reviewService.putOutcome(outcome)
      .subscribe((response: any) => {
        if (response.errorCode == "9001") {
          this.outcomeErrorMessage = "Review Outcome cannot be modified before the scheduled review date & time has passed.";
        }
        this.review = response.result;
        this.populateReviewUploads();
        this.outcomeUpdated.emit(response.result);
        this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
        });
  }

  getReviewMethodTypeName(reviewMethodTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(reviewMethodTypeId, TypeTable.ReviewMethodType);
  }

  getReviewOutcomeTypeName(index: number): string {
    const outcome = this.review.outcomes[index];
    const nextOutcome = this.review.outcomes[index + 1 >= this.review.outcomes.length ? index : index + 1];

    if (outcome.reviewOutcomeTypeId == null) {
      if (nextOutcome.reviewDate != outcome.reviewDate || nextOutcome.reviewTime != outcome.reviewTime) {
        return 'Rescheduled';
      }
      if (nextOutcome.reviewMethodTypeId != outcome.reviewMethodTypeId) {
        return 'Review method changed';
      }
    }
    return this.localStorageService.getTypeItemDescriptionById(outcome.reviewOutcomeTypeId, TypeTable.ReviewOutcomeType);
  }
}
