<div class="d-flex flex-column">
    <div class="heading">Contravention Details</div>
    <div class="expand-all">
        <div class="form-check" >
            <input type="checkbox" class="form-check-input" id="expandAll" (change)="onExpandAllChange($event)">
            <label class="form-check-label section-label" for="expandAll">Expand all</label>
        </div>
    </div>
    <div class="accordion">
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgIssuingPoliceService', 'divIssuingPoliceService')">
                <div>Issuing Police Service</div>                                
                <div class="plus-minus-icon">
                    <img id="imgIssuingPoliceService" src="assets/images/plus.svg" />
                </div>                               
            </div>            
            <div id="divIssuingPoliceService" class="collapse">
                <div class="card-body">                    
                    <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
                    <div class="row">
                        <div class='col-12 col-sm-8'>
                            <div class="label">Detachment</div>
                            <div class="value">{{detachmentName}}</div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Police File Number</div>
                                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editPoliceFileNumberClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isPoliceFileNumberEdited" class="value">{{contravention?.policeFileNumber?contravention?.policeFileNumber:"-"}}</div>
                            <div *ngIf="isPoliceFileNumberEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required #policeFileNumberControl="ngModel" type="text" maxlength="20" class="form-control form-control-sm custom-text-box" 
                                    id="policeFileNumber" name="policeFileNumber" [(ngModel)]="contravention.policeFileNumber" 
                                    [ngClass]="{'is-invalid':policeFileNumberControl.dirty && policeFileNumberControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editPoliceFileNumberSaveClick(!policeFileNumberControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                        <div *ngIf="policeFileNumberControl.hasError('required')">Police File Number is required</div>
                                        <div *ngIf="!policeFileNumberControl.hasError('required')">Invalid Police File Number</div>
                                    </div>
                                </div>
                                <span class="error">{{policeFileNumberErrorMessage}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgContraventionType', 'divContraventionType')">
                <div>Contravention Information</div>
                <div class="plus-minus-icon">
                    <img id="imgContraventionType" src="assets/images/plus.svg" />
                </div>  
            </div>
            <div id="divContraventionType" class="collapse">
                <div class="card-body">
                    <label class="bold-text" *ngIf="showAdditionalConditionsMessage">There may be additional conditions for reinstatement of the recipient's operator's licence.</label>
                    <app-section-details sectionTitle="" [sectionDetails]="contraventionType"></app-section-details>
                </div>
            </div>
        </div>            
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgOccurrenceInformation', 'divOccurrenceInformation')">
                <div>Occurrence Details</div>  
                <div class="plus-minus-icon">
                    <img id="imgOccurrenceInformation" src="assets/images/plus.svg" />
                </div>                                
            </div>            
            <div id="divOccurrenceInformation" class="collapse">
                <div class="card-body">    
                    <div class="row">
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Occurrence Date</div>
                                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editOccurrenceDateClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isOccurrenceDateEdited" class="value">{{ contravention?.occurrenceDate | date:"mediumDate"}}</div>
                            <div *ngIf="isOccurrenceDateEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required [isAfterMinimumAgeDate]="contravention?.recipient?.recipientIdentification.dateOfBirth" #occurrenceDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control form-control-sm custom-text-box" id="occurrenceDate" name="occurrenceDate" placeholder="yyyy/mm/dd" [(ngModel)]="contravention.occurrenceDate" [ngClass]="{'is-invalid':occurrenceDateControl.dirty && occurrenceDateControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editOccurrenceDateSaveClick(!occurrenceDateControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                        <div *ngIf="occurrenceDateControl.hasError('required')">Occurrence Date is required</div>
                                        <div *ngIf="!occurrenceDateControl.hasError('required') && occurrenceDateControl.hasError('AfterMinimumAgeDate')">Recipient/Accused must be 12 or older</div>
                                        <div *ngIf="!occurrenceDateControl.hasError('required') && !occurrenceDateControl.hasError('AfterMinimumAgeDate')">Invalid Occurrence Date</div>
                                    </div>
                                </div>
                                <span class="error">{{occurrenceDateErrorMessage}}</span>
                            </div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Occurrence Time</div>
                                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editOccurrenceTimeClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isOccurrenceTimeEdited" class="value">{{ contravention?.occurrenceTime?.substring(0,5) }}</div>
                            <div *ngIf="isOccurrenceTimeEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required #occurrenceTimeControl="ngModel" type="text" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" mask="{{Constants.Mask.TIME_MASK}}" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control form-control-sm custom-text-box" id="occurrenceTime" name="occurrenceTime" placeholder="--:--" [(ngModel)]="contravention.occurrenceTime" [ngClass]="{'is-invalid':occurrenceTimeControl.dirty && occurrenceTimeControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editOccurrenceTimeSaveClick(!occurrenceTimeControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                        <div *ngIf="occurrenceTimeControl.hasError('required')">Occurrence Time is required</div>
                                        <div *ngIf="!occurrenceTimeControl.hasError('required')">Invalid Occurrence Time</div>
                                    </div>
                                </div>
                                <span class="error">{{occurrenceTimeErrorMessage}}</span>
                            </div>
                        </div>
                        <div class='col-12 col-sm-4'>
                            <div class="d-flex justify-content-start">
                                <div class="label">Issuance Date</div>
                                <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.DATA_EDITS">
                                    <div class="data-edit-button" (click)="editIssuanceDateClick()"></div>
                                </app-access-control>
                            </div>
                            <div *ngIf="!isIssuanceDateEdited" class="value">{{ contravention?.issueDate | date:"mediumDate"}}</div>
                            <div *ngIf="isIssuanceDateEdited">
                                <div class="d-flex justify-content-start editableField">
                                    <input required #issuanceDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control form-control-sm custom-text-box" id="issuanceDate" name="issuanceDate" placeholder="yyyy/mm/dd" [(ngModel)]="contravention.issueDate" [ngClass]="{'is-invalid':issuanceDateControl.dirty && issuanceDateControl.invalid}">
                                    <button [disabled]="isSaving" class="button-primary custom-button" (click)="editIssuanceDateSaveClick(!issuanceDateControl.invalid)">Ok</button>
                                    <div class="invalid-feedback">
                                        <div *ngIf="issuanceDateControl.hasError('required')">Issuance Date is required</div>
                                        <div *ngIf="!issuanceDateControl.hasError('required')">Invalid Issuance Date</div>
                                    </div>
                                </div>
                            </div>
                            <span class="error">{{issuanceDateErrorMessage}}</span>
                        </div>
                    </div>           
                    <app-section-details sectionTitle="" [sectionDetails]="occurrenceInformation"></app-section-details>     
                    <app-section-details sectionTitle="Witness Information" [sectionDetails]="witnessInformation"></app-section-details>                                                       
                    <div class="row" *ngIf="isPhase2Submission && contravention?.recipient?.recipientTypeId!=RecipientTypes.NotInVehicle">
                        <div class="col-12 col-sm-4">
                            <div class="label">Passengers</div>
                            <div class="value">{{contravention?.stopInformation?.isPassengerInVehicle? 'Yes': 'No'}}</div>
                        </div>
                        <ng-container *ngIf="contravention?.stopInformation?.isPassengerInVehicle">
                            <div class="col-12 col-sm-4">
                                <div class="label">Passenger Positions</div>
                                <div class="value ps-2">
                                    <app-passenger-position [stopInformation]="contravention?.stopInformation" [isReadOnly]="true"></app-passenger-position>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="label">Additional Passenger Location Details</div>
                                <div class="value">{{contravention?.stopInformation?.vehicleOccupantsNotes}}</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row" *ngIf="isBodycamVideoActive">
                        <div class="col-12 col-sm-4">
                            <div class="label">Is there any video in relation to this event?</div>
                            <div class="value" *ngIf="contravention?.stopInformation?.hasBodycamVideo == null; else bodycamVideoHasValue">-</div>
                            <ng-template #bodycamVideoHasValue>
                                <div class="value">{{contravention?.stopInformation?.hasBodycamVideo? 'Yes': 'No'}}</div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgOfficerInformation', 'divOfficerInformation')">
                <div>Officer Information</div>
                <div class="plus-minus-icon">
                    <img id="imgOfficerInformation" src="assets/images/plus.svg" />
                </div>  
            </div>
            <div id="divOfficerInformation" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="" [sectionDetails]="officerInformation"></app-section-details>                    
                </div>
            </div>
        </div>
        <div class="card" *ngIf="this.contravention?.isNoVehicleSeizureMade">
            <div class="card-header" (click)="onPlusMinusIconClick('imgVehicleDetails', 'divVehicleDetails')">
                <div>Vehicle Information</div>    
                <div class="plus-minus-icon">
                    <img id="imgVehicleDetails" src="assets/images/plus.svg" />
                </div>                              
            </div>            
            <div id="divVehicleDetails" class="collapse">
                <div class="card-body">                    
                    <label class="bold-text" *ngIf="this.vehicle?.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>               
                    <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                        <label class="bold-text">The following information is a description of the vehicle involved in the contravention</label>
                        <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>
                    <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>
                    <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgImpairmentScreening', 'divImpairmentScreening')">
                <div>Impairment Screening</div>
                <div class="plus-minus-icon">
                    <img id="imgImpairmentScreening" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divImpairmentScreening" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds"></app-section-details>
                    <app-section-details sectionTitle="Impairment Screening Information" [sectionDetails]="impairmentScreeningInformation"></app-section-details>
                    <app-section-details sectionTitle="Impairment Screening Details" [sectionDetails]="impairmentScreeningDetails"></app-section-details>
                    <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                        <app-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}" [sectionDetails]="additionalTest"></app-section-details>
                    </div>
                    <app-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds"></app-section-details>
                    <app-section-details *ngIf="contravention?.additionalNotes && contravention?.additionalNotes.length>0" sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>
                </div>
            </div>
        </div>
        <div *ngIf="contravention?.roadsideAppeal" class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgRoadsideAppeal', 'divRoadsideAppeal')">
                <div>Roadside Appeal</div>
                <div class="plus-minus-icon">
                    <img id="imgRoadsideAppeal" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divRoadsideAppeal" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="Roadside Appeal Information" [sectionDetails]="roadsideAppealInformation"></app-section-details>
                    <app-section-details sectionTitle="Roadside Appeal Details" [sectionDetails]="roadsideAppealDetails"></app-section-details>
                </div>
            </div>
        </div>        
        <div class="card">
            <div class="card-header" (click)="onPlusMinusIconClick('imgDeclarations', 'divDeclarations')">
                <div>Declarations</div>
                <div class="plus-minus-icon">
                    <img id="imgDeclarations" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divDeclarations" class="collapse">
                <div class="card-body">
                    <app-section-details *ngIf="contravention?.isReServeRequired == true" sectionTitle="" [sectionDetails]="reServe"></app-section-details>
                    <app-section-details sectionTitle="" [sectionDetails]="declarations"></app-section-details>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="showSupportingDocumentDeclarations">
            <div class="card-header" (click)="onPlusMinusIconClick('imgDeclarations', 'divSupportingDocumentDeclarations')">
                <div>Supporting Document Declarations</div>
                <div class="plus-minus-icon">
                    <img id="imgDeclarations" src="assets/images/plus.svg" />
                </div>
            </div>
            <div id="divSupportingDocumentDeclarations" class="collapse">
                <div class="card-body">
                    <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarations"></app-section-details>
                </div>
            </div>
        </div>
    </div>
    <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.VIEW_SUPPORTING_DOCUMENTS">
        <supporting-documents [contravention]="contravention"></supporting-documents> 
    </app-access-control>   
</div>

