import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { RefundStatus } from '@apis/shared/enums/refund-status.enum';
import { ContactMethodTypes, DocumentTypes, EventTypes, RepresentationTypes, ReviewMethodTypes, ReviewTypes, TransactionTypes, VehicleInterestTypes } from "@apis/shared/enums/app.enum";
import { Document } from '@apis/shared/models/document.model';
import { Event } from "@apis/shared/models/event.model";
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from "file-saver";
import { DocumentService } from '@apis/shared/services/document.service';
import { Representation } from '@apis/shared/models/representation.model';
import { Constants } from "@apis/shared/helpers/constants";
import { PaymentDataEditRequest } from '@apis/shared/models/payment-data-edit-request.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReviewService } from '../../../shared/services/review.service';
import { RepresentationType } from '@apis/shared/models/types/representation-type.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ContactMethodType } from '@apis/shared/models/types/contact-method-type.model';
import { ContactAddress } from '@apis/shared/models/contact-address.model';
import { CountryProvinceModel } from '@apis/shared/models/country-province.model';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RegistryAgentInformation } from '@apis/shared/models/registry-agent-information.model';
import { ReviewPayment } from '@apis/shared/models/review-payment.model';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';
import { LogRefundModalComponent } from '../../../shared/components/modals/log-refund-modal/log-refund-modal.component';

@Component({
  selector: 'applicant-submissions',
  templateUrl: './applicant-submissions.component.html',
  styleUrls: ['./applicant-submissions.component.scss']
})
export class ApplicantSubmissionsComponent implements OnInit, OnChanges {
  @Input() review: Review;
  @Input() applicantName: string;
  @Input() isRecipientYouth: boolean;
  @Output() viewReceiptClicked = new EventEmitter();
  @Output() reviewInfoEdited = new EventEmitter<Review>();

  supportingDocuments: Document[] = [];
  preferredContactMethodName: string;

  documentRefTypeName = "Reviews";
  documentRefTypeNumber: string;

  vehicleInterestTypeName: string;
  representation: Representation;
  representationTypeName: string;
  representationFullName: string;
  representationPracticeName: string;
  termsConditionsStatement: string;
  isThirdPartySeizure: boolean;
  isEmailPreferredContactMethod: boolean;
  displayFailedAttendanceDeclaration: boolean = false;
  declarationSafeHTML: SafeHtml;
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;

  ReviewTypes = ReviewTypes;
  VehicleInterestTypes = VehicleInterestTypes;
  RepresentationTypes = RepresentationTypes;
  ReviewMethodTypes = ReviewMethodTypes;
  ContactMethodTypes = ContactMethodTypes;
  TransactionTypes = TransactionTypes;
  paymentDataEditRequest: PaymentDataEditRequest;
  isSubmitClicked: boolean = false;

  representationTypes: RepresentationType[];
  isRepresntationInformationEdited: boolean=false;
  representationTypeId: number;
  repTitle: string;
  firstName: string ;
  lastName: string;
  practiceName: string ;
  otherRepresentationType: string;

  TypeTable = TypeTable;
  contactMethodTypeId: number;
  contactMethodTypes: ContactMethodType[];
  email: string;
  confirmEmail: string;
  phoneNumber: string;
  tempPhoneNumber: string;
  confirmPhoneNumber: string
  contactAddress: ContactAddress;
  contactCountryProvince: CountryProvinceModel;
  errorMessage: string;  
  eventDetails: string = "";
  maxCharacters: number = 250;
  registryAgentInformation: RegistryAgentInformation;
  canLogRefund: boolean = false;
  RefundStatus = RefundStatus;

  constructor(public readonly localStorageService: LocalStorageService,
              private readonly documentService: DocumentService,
              private readonly adjudicationService: AdjudicationService,
              private readonly spinner: NgxSpinnerService,
              private readonly reviewService: ReviewService,
              private sanitizer: DomSanitizer,
              private readonly viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {    
    this.representationTypes = this.localStorageService.getRepresentationTypes();
    this.contactMethodTypes = this.localStorageService.getContactMethodTypes();

    this.contactCountryProvince = new CountryProvinceModel();
    this.contactCountryProvince.isCountryRequired = true;
    this.contactCountryProvince.isProvinceRequired = true;  
    this.contactCountryProvince.countryLabel = "Country";
    this.contactCountryProvince.provinceLabel = "Province/State";
    this.contactCountryProvince.controlSuffixText = "";   

    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canLogRefund = this.localStorageService.hasPermission(Constants.Resource.REVIEW,Constants.Permission.LOG_REFUND);
    });
  } 
  
  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.review?.currentValue != null) {
      this.documentRefTypeNumber = this.review.reviewNumber;
      this.representation = this.review.representation;

      if (this.review.isSubmittedByRegistry)
        this.registryAgentInformation = this.review.registryAgentInformation[0];
      
      this.preferredContactMethodName = this.localStorageService.getTypeItemDescriptionById(this.review?.contactMethodTypeId, TypeTable.ContactMethodType);
    
      if (this.representation != null) {        
        this.representationTypeName = this.localStorageService.getRepresentationTypes()?.find(r => +r.id == this.representation?.representationTypeId)?.name;        
        this.representationFullName = `${this.representation.firstName} ${this.representation.lastName}`;

        if ([RepresentationTypes.Agent, RepresentationTypes.Lawyer].includes(+this.representation.representationTypeId)) {
          this.representationPracticeName = this.representation.practiceName;
        }
        else
        {
          this.representationPracticeName = null;
        }

        if (+this.representation.representationTypeId == RepresentationTypes.Other) {
          this.representationTypeName = `${this.representationTypeName}, ${this.representation.otherRepresentationType}`;
        }

        this.representationTypeId = this.representation.representationTypeId;
        this.repTitle = CommonUtil.getRepresentationTitle(this.representationTypeId);
        this.firstName = this.representation.firstName;
        this.lastName = this.representation.lastName;
        this.practiceName = this.representation.practiceName;
        this.otherRepresentationType = this.representation.otherRepresentationType;
        this.contactMethodTypeId = this.review.contactMethodTypeId;
        this.email = this.review.email;
        this.confirmEmail = this.review.email;
        this.phoneNumber = this.review.phoneNumber;
        this.tempPhoneNumber = this.review.phoneNumber?.slice(1);
        this.confirmPhoneNumber = this.review.phoneNumber?.slice(1);

        this.contactAddress = new ContactAddress({
          addressLine1: this.review.contactAddress?.addressLine1,
          addressLine2: this.review.contactAddress?.addressLine2,
          city: this.review.contactAddress?.city,
          countryId: this.review.contactAddress?.countryId,
          provinceId: this.review.contactAddress?.provinceId,
          province: this.review.contactAddress?.province,
          postalCode: this.review.contactAddress?.postalCode
        });
      }
      else
      {
        this.representationTypeId = RepresentationTypes.SelfRepresentation;
        this.representationTypeName = "Self-representation";
      }

      this.isThirdPartySeizure = +this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview;
      this.isEmailPreferredContactMethod = +this.review.contactMethodTypeId == ContactMethodTypes.Email;

      if (this.isThirdPartySeizure) {
        const vehicleInterestType = this.localStorageService.getVehicleInterestTypes().find(v => v.id == this.review.reviewApplicant.vehicleInterestTypeId);
        this.vehicleInterestTypeName = vehicleInterestType.name;
        if (+this.review.reviewApplicant.vehicleInterestTypeId == VehicleInterestTypes.Other) {
          this.vehicleInterestTypeName = `${this.vehicleInterestTypeName}, ${this.review.reviewApplicant.otherInterestType}`;
        }
      }

      let documentTypes = [DocumentTypes.ReviewDecisionLetter, DocumentTypes.AdditionalDecisionDocument, DocumentTypes.ReviewUploads, DocumentTypes.Note];      
      this.supportingDocuments = this.review.documents.filter((d: Document) => !documentTypes.includes(+d.documentTypeId));

      if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral) {
        const declarationText = this.localStorageService.getDeclarationTypes().find(x => x.id == this.review.failedAttendanceDeclarationTypeId)?.formattedDescription;
        if (declarationText) {
          this.declarationSafeHTML = this.sanitizer.bypassSecurityTrustHtml(declarationText);
          this.displayFailedAttendanceDeclaration = true; // Only display oral review election section if one exists
        }
      }
      else {
        this.declarationSafeHTML = null;
        this.displayFailedAttendanceDeclaration = false;
      }

      // Terms and conditions statement
      const termsConditionsList = this.localStorageService.getTermsAndConditionsList();
      if (this.review.termsConditionsVersion) {
        if (this.isThirdPartySeizure && +this.review.termsConditionsVersion <= 8) {
          this.termsConditionsStatement = "Agree to Terms & Conditions";
        }
        else {
          const reviewTerms = termsConditionsList.find(x => x.version === this.review.termsConditionsVersion);
          this.termsConditionsStatement = this.review.isSubmittedByRegistry ? reviewTerms.adminRegistryStatementFormatted : reviewTerms.adminDriverStatementFormatted;
        }
      }
      else { // Unscheduled re-reviews do not have a termsConditionVersion
        const latestTerms = termsConditionsList.find(x => x.expiryDate === null);
        this.termsConditionsStatement = latestTerms.adminDriverStatementFormatted;
      }

      // Set payment refund status
      this.review.payments.forEach(p => {
        if (p.financialTransaction.transactionTypeId == TransactionTypes.RefundReviewFee) {
          p.refundStatus = RefundStatus.NonRefundable;
        } else {
          var isPaymentRefunded = this.review.payments.some(rp => rp.financialTransaction.sourceTransactionId == p.financialTransaction.transactionId.toString());
          p.refundStatus = isPaymentRefunded ? RefundStatus.Completed : RefundStatus.Refundable;
        }
      });      
    }
    
    if (simpleChanges.isRecipientYouth?.currentValue != null) {
      if (this.isRecipientYouth)
        this.representationTypes = this.localStorageService.getRepresentationTypes();
      else
        this.representationTypes = this.localStorageService.getRepresentationTypes().filter(x => x.id != RepresentationTypes.ParentGuardian);
    }
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService.downloadDocument("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, document.documentName);
      },
        (error: any) => {});
  }

  getContraventionTypeName(contraventionTypeId: number): string {
    var contraventionType = this.localStorageService.getContraventionTypes().find(t => t.id == contraventionTypeId);
    if (contraventionType != null) {
      return `${contraventionType.formattedChargeCode} - ${contraventionType.description}`;
    }
  }

  getDocumentTypeName(documentTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(documentTypeId, TypeTable.DocumentType);
  }

  onViewReceiptClick() {
    this.viewReceiptClicked.emit();
  }

  onEditPaymentClick(payment: any)
  {
    this.paymentDataEditRequest = new PaymentDataEditRequest ();
    this.paymentDataEditRequest.financialTransactionId = payment.financialTransactionId;
    this.paymentDataEditRequest.transactionId = payment.financialTransaction.transactionId;
    
    payment.edited = true;
  }

  onEditPaymentSaveClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
    
    if (isValid)
    {
      this.spinner.show();
      this.reviewService.updateReviewPaymentInformation(this.review.reviewNumber, this.paymentDataEditRequest)
        .subscribe((result: Review) => {
          this.reviewInfoEdited.emit(result);
          this.spinner.hide();
        }, (error: any) => {
          this.spinner.hide();            
      });
    }
  }

  public refreshContactCountryProvince(e) {
    this.contactAddress.countryId = e.countryId;
    this.contactAddress.provinceId = e.provinceId;
    this.contactAddress.province = e.province;
  }

  onRepresentationTypeChange()
  {
    this.firstName = '';
    this.lastName = '';
    this.practiceName = '';
    this.otherRepresentationType = '';

    this.repTitle = CommonUtil.getRepresentationTitle(this.representationTypeId);
  }

  onContactMethodChange()
  {
    this.tempPhoneNumber = '';
    this.phoneNumber = '';
    this.confirmPhoneNumber = null;

    this.email = '';
    this.confirmEmail = '';

    if (this.contactMethodTypeId == ContactMethodTypes.Phone) {
      this.contactAddress = new ContactAddress();
      this.contactCountryProvince.countryId = null;
    } else {
      this.contactAddress = null;
    }
  }

  onEditPaymentCancelClick(p: any)
  {
    p.edited = false;
  }

  onLogRefundClick(payment: ReviewPayment) {
    this.viewContainerRef.clear();

    var logRefundRequest = new LogRefundRequest({
      recordNumber: this.review.reviewNumber,
      refundAmount: payment.amount,
      sourceTransactionId: payment.financialTransaction.transactionId,
      transactionTypeId: payment.financialTransaction.transactionTypeId
    });

    let componentRef = this.viewContainerRef.createComponent(LogRefundModalComponent);
    componentRef.instance.logRefundRequest = logRefundRequest;
    componentRef.instance.close.subscribe((result: any) => {
      componentRef.destroy();
      if (result != null) {
        this.reviewInfoEdited.emit(result);
      }
    });
  }

  editRepresentationInformationClick()
  {
    this.representationTypeId = this.representation?this.representation.representationTypeId:RepresentationTypes.SelfRepresentation;
    this.repTitle = CommonUtil.getRepresentationTitle(this.representationTypeId);
    this.firstName = this.representation?.firstName;
    this.lastName = this.representation?.lastName;
    this.practiceName = this.representation?.practiceName;
    this.otherRepresentationType = this.representation?.otherRepresentationType;
    this.contactMethodTypeId = this.review.contactMethodTypeId;
    this.email = this.review.email;
    this.confirmEmail = this.review.email;
    this.phoneNumber = this.review.phoneNumber;
    this.tempPhoneNumber = this.review.phoneNumber?.slice(1);
    this.confirmPhoneNumber = this.review.phoneNumber?.slice(1);

    this.contactAddress = new ContactAddress({
      addressLine1: this.review.contactAddress?.addressLine1,
      addressLine2: this.review.contactAddress?.addressLine2,
      city: this.review.contactAddress?.city,
      countryId: +this.review.contactAddress?.countryId,
      provinceId: +this.review.contactAddress?.provinceId,
      province: this.review.contactAddress?.province,
      postalCode: this.review.contactAddress?.postalCode
    });

    //Initialize registered owner country province control
    this.contactCountryProvince.countryId = this.contactAddress.countryId;
    this.contactCountryProvince.provinceId = this.contactAddress.provinceId;
    this.contactCountryProvince.province = this.contactAddress.province;

    this.isRepresntationInformationEdited = true;
  }

  saveEditedRepresentationInformation(isValid: boolean)
  {
    this.isSubmitClicked = true;
    if(isValid && this.maxCharacters >= 0) 
    {
      if (this.review.representation && this.representationTypeId == RepresentationTypes.SelfRepresentation)
      {
        this.review.representation = null;
      }
      else
      {
        if (!this.review.representation && this.representationTypeId != null && this.representationTypeId != RepresentationTypes.SelfRepresentation)
          this.review.representation = new Representation();

        if (this.review.representation)
        {
          this.review.representation.representationTypeId = this.representationTypeId;
          this.review.representation.firstName = this.firstName;
          this.review.representation.lastName = this.lastName;
          this.review.representation.practiceName = this.practiceName;
          this.review.representation.otherRepresentationType = this.otherRepresentationType;
        }
      }

      this.review.contactMethodTypeId = this.contactMethodTypeId;
      this.review.email = this.email;
      this.review.phoneNumber = this.phoneNumber;
      this.review.contactAddress = this.contactAddress;

      if (this.eventDetails.trim().length > 0)
      {
        let event = new Event({      
          eventTypeId: EventTypes.DataEdited,
          eventDetails: this.eventDetails    
        });

        this.review.events.push(event);
      }

      this.spinner.show();
      this.adjudicationService.updateReviewRepresentationInformation(this.review.reviewNumber, this.review)
        .subscribe((result: Review) => {
          this.cancelEditedRepresentationInformation();
          this.review = result;
          this.reviewInfoEdited.emit(result);
          this.spinner.hide();
        }, (error: any) => {

          if (error.status == 401 || error.status == 403) {
            this.errorMessage = "You are unauthorized to perform this operation";
          }          
          else  {
            this.showErrors(error);
          }    
          this.spinner.hide();            
        });
    }
  }

  cancelEditedRepresentationInformation()
  {
    this.isSubmitClicked = false;
    this.isRepresntationInformationEdited = false;
    this.clearEditedRepresentationInformation();
  }

  clearEditedRepresentationInformation()
  {
    this.representationTypeId = this.representation?this.representation.representationTypeId:RepresentationTypes.SelfRepresentation;
    this.firstName = "";
    this.lastName = "";
    this.practiceName = "";
    this.otherRepresentationType = "";
    this.contactMethodTypeId = null;
    this.email = "";
    this.confirmEmail = "";
    this.phoneNumber = "";
    this.tempPhoneNumber = "";
    this.confirmPhoneNumber = "";
    this.contactAddress = null;
    this.errorMessage=null;
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }
}