 <div class="d-flex flex-column h-100">
    <div class="header">
        Reviews
    </div>
    <div class="main">
        <div class="filter-section">            
            <div class="filter-by-header">Filter by:</div>
            <div class="mb-3">
                <div class="filter-type-header">Review Type</div>
                <div class="filter-type-container">                    
                    <select class="form-select w-75" id="reviewTypesDropdown" (change)="onReviewTypeChange($event)"
                    [(ngModel)]="selectedReviewTypeId">
                        <option class="review-type-option" value="0">All</option>
                        <option class="review-type-option" *ngFor="let reviewType of reviewTypes" value="{{reviewType.id}}">{{reviewType.name}}</option>                        
                    </select>                                        
                </div>
            </div>
            <div class="mb-3">
                <div class="filter-type-header">Review Method</div>
                <div class="filter-type-container">                    
                    <select class="form-select w-75" id="reviewMethodTypesDropdown" (change)="onReviewMethodTypeChange($event)"
                    [(ngModel)]="selectedReviewMethodTypeId">
                        <option class="review-type-option" value="0">All</option>
                        <option class="review-type-option" *ngFor="let reviewMethodType of reviewMethodTypes" value="{{reviewMethodType.id}}">{{reviewMethodType.name}}</option>                        
                    </select>                                        
                </div>
            </div>   
            <div class="mb-3">
                <div class="filter-type-header">Assigned To</div>
                <div class="filter-type-container">                    
                    <select class="form-select w-75" [(ngModel)]="selectedAdjudicatorId"                         
                        id="assignedToDropdown" 
                        (change)="onAssignedToChange($event)">
                        <option class="review-type-option" value="0">All</option>
                        <option class="review-type-option" *ngFor="let adjudicator of adjudicators" value="{{adjudicator.userId}}">
                            {{adjudicator.lastName}}, {{adjudicator.firstName}}
                        </option>
                    </select>                                        
                </div>
            </div>            
            <div class="mb-3">               
                <div class="filter-type-header d-flex align-items-center justify-content-between">
                    Status
                    <a class="select-all-link" (click)="onSelectAllLinkClick($event)">Select All</a>
                </div>
                <div class="filter-type-container">
                    <status-checkboxes 
                        [statusTypes]="reviewStatusTypes"
                        (change)="onReviewStatusTypeChange($event)"></status-checkboxes>                                                                              
                </div>
            </div>

            <action-checkboxes
                (change)="onActionCheckboxesChange($event)">
            </action-checkboxes>
            
            <a class="clear-all-link disabled" (click)="onClearAllLinkClick($event)">Clear All</a>     
        </div>

        <div class="w-100 ps-4 pe-4">
            <div class="d-flex justify-content-between align-items-center">
                <div class="search-box">
                    <input id="searchTextbox" type="text" class="form-control" placeholder="Search by name or number" 
                        (keydown)="onSearchTextboxKeyDown($event)" [(ngModel)]="searchText" />                
                    <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick()"><i class="fas fa-search fa-lg"></i></button>                
                </div>
                <div class="d-flex align-items-center">
                    <div class="paging align-self-end" >                                   
                        <label for="pageSizeTop" class="page-size-label">Page Size</label>
                        <select class="form-select page-size-select" id="pageSizeTop" (change)="onPageSizeChange($event)">
                            <option value="10" [selected]="+pageSize == 10">10</option>
                            <option value="25" [selected]="+pageSize == 25">25</option>
                            <option value="50" [selected]="+pageSize == 50">50</option>
                            <option value="100" [selected]="+pageSize == 100">100</option>
                        </select>                               
                        <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>
                        <div class="previous-link"
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                            (click)="onPreviousLinkClick($event)">Previous</div>
                      
                        <ng-container *ngFor="let page of pageNumbers; let i=index;">
                            <div id="pageNumberTop_{{page}}" class="page-number" 
                                [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                                (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                        </ng-container>
                        
                        <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                            <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                            <div id="pageNumberTop_{{totalPages}}" class="page-number ms-0"
                            [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                            (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                        </ng-container>

                        <div class="next-link"
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                            (click)="onNextLinkClick($event)">Next</div>                        
                        <img class="next-link-icon"                             
                            src="assets/images/paging-left-arrow.svg"/>                        
                    </div>
                    <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.EXPORT_LIST">
                        <div class="export-list" (click)="onExportListClick($event)">
                            <img class="export-list-icon" src="assets/images/export-list.svg" />
                            <div class="ms-2">Export List</div>
                        </div>
                    </app-access-control>
                </div>
            </div>
            <div class="d-flex mt-3">
                <table id="reviewsTable" class="table table-hover">
                    <thead>
                        <tr>
                            <th style="width: 85px !important;">                                
                                <div class="d-flex">
                                    <span>Review</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('reviewNumber')"></i></span>
                                </div>                                
                            </th>
                            <th style="width: 128px !important;">                                
                                <div class="d-flex">
                                    <span>Decision<br />Due Date</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('decisionDueDate')"></i></span>
                                </div>

                            </th> 
                            <th style="width: 128px !important; position: relative; padding-left: 10px;">                               
                                <div class="d-flex">
                                    <span> Review<br />Date/Time </span>
                                    <span class="sort-icon review-date-time"><i class="fas fa-sort" (click)="onSortIconClick('reviewDateTime')"></i></span>
                                </div>                                
                            </th>                            
                            <th style="width: 148px !important; padding-left: 10px;">                                
                                <div class="d-flex">
                                    <span>Applicant<br />Name</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('applicantName')"></i></span>
                                </div>
                            </th>
                            <th style="width: 135px !important; padding-left: 8px;">                                
                                <div class="d-flex">
                                    <span>Review<br />Type</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('reviewTypeName')"></i></span>
                                </div>
                            </th>
                            <th style="width: 83px !important; padding-left: 6px !important; padding-right: 20px !important;">                                
                                <div class="d-flex">
                                    <span>Review<br />Method </span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('reviewMethodTypeName')"></i></span>
                                </div>
                            </th>
                            <th style="width: 148px !important; padding-left: 0px;">                               
                                <div class="d-flex">
                                    <span>Assigned<br />Adjudicator </span>
                                    <span class="sort-icon assigned-adjudicator"><i class="fas fa-sort" (click)="onSortIconClick('adjudicatorName')"></i></span>
                                </div>
                            </th>
                            <th style="width: 170px; padding-left: 0px; position: relative;">                                
                                <div class="d-flex">
                                    <span>Status</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('reviewStatusTypeName')"></i></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredReviews.length > 0">
                        <tr *ngFor="let review of filteredReviews">
                            <td><a class="review-number" [routerLink]="['/review', review.reviewNumber]">{{review.reviewNumber}}</a></td>
                            <td style="font-weight: bold !important;">{{review.decisionDueDate | date}}</td>
                            
                            <td style="padding-left: 10px;">
                                <div style="font-weight: bold !important;">{{review.reviewDateTime | date:'mediumDate'}}</div>
                                <div style="margin-top: 8px;">{{review.reviewDateTime | date:'HH:mm'}}</div>
                            </td>
                            <td style="padding-left: 10px;">{{review.applicantName}}</td>
                            <td style="padding-left: 8px;">{{review.reviewTypeName}}</td>
                            <td style="padding-left: 6px !important;">{{review.reviewMethodTypeName}}</td>
                            <td style="padding-left: 0px !important;">
                                <ng-container *ngIf="review?.adjudicatorName">
                                    {{review?.adjudicatorName}}
                                </ng-container>
                                <ng-container *ngIf="!review?.adjudicatorName">
                                    Unassigned
                                </ng-container>
                            </td>
                            <td class="ps-0">
                                <div class="d-flex flex-wrap" style="width: 170px;">
                                    <span class="review-status-pill mb-2" 
                                        [ngClass]="getReviewStatusTypeCssClass(review.reviewStatusTypeId)">
                                        {{review.reviewStatusTypeName}}</span>

                                    <div class="d-flex">                                        
                                        <div *ngIf="review.hasDocumentsRequiringRedaction" class="review-action-alert-icon">                
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>
                                        <div *ngIf="review.hasDocumentsWithPendingSubmissions" class="review-action-warning-icon">                
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>
                                        <div *ngIf="review.isPhoneContactRequired" class="review-action-contact-icon">
                                            <i class="fas fa-phone fa-xs"></i>
                                        </div>
                                        <div *ngIf="review.isActionRequired" class="review-action-closure-icon">
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>   
                                    </div>
                                </div>                                                                               
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="filteredReviews.length === 0">
                        <tr>
                            <td colspan="8">No reviews found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end">            
                <div class="paging">                    
                    <label for="pageSizeBottom" class="page-size-label">Page Size</label>
                    <select class="form-select page-size-select" id="pageSizeBottom" (change)="onPageSizeChange($event)">
                        <option value="10" [selected]="+pageSize == 10">10</option>
                        <option value="25" [selected]="+pageSize == 25">25</option>
                        <option value="50" [selected]="+pageSize == 50">50</option>
                        <option value="100" [selected]="+pageSize == 100">100</option>
                    </select>     

                    <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>
                    <div class="previous-link" 
                        [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                        (click)="onPreviousLinkClick($event)">Previous</div>

                    <ng-container *ngFor="let page of pageNumbers; let i=index;">                            
                        <div id="pageNumberBottom_{{page}}" class="page-number" 
                            [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                            (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                    </ng-container>
                    
                    <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                        <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                        <div id="pageNumberBottom_{{totalPages}}" class="page-number ms-0" 
                        [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                        (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                    </ng-container>

                    <div class="next-link" 
                        [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                        (click)="onNextLinkClick($event)">Next</div>
                    <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>
                </div>
            </div>  
              
        </div>                
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>
</div>

