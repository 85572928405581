<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Change Decision Due Date</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="mb-3 row">
                <div class="col-12 col-lg-6" style="position: relative;">
                    <label class="modal-body-label" for="decisionDueDate">New Decision Due Date</label>
                    <input type="text" 
                        required
                        class="form-control"
                        id="decisionDueDate"
                        name="decisionDueDate"                                                     
                        [(ngModel)]="review.decisionDueDate"
                        #dp="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="datePickerConfig" 
                        [dateCustomClasses]="dateCustomClasses"                                       
                        placeholder="MMM DD, YYYY"
                    />
                    <span class="calendar-icon" (click)="dp.toggle()"><i class="far fa-calendar"></i></span>                             
                </div>          
            </div>
            <div class="mb-3">
                <label class="modal-body-label" for="eventDetails">Note</label>
                <textarea 
                    id="eventDetails" 
                    name="eventDetails"
                    [(ngModel)]="eventDetails"
                    class="modal-textarea"                                   
                    (input)="onTextareaInput($event)"></textarea>
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            </div>  
            <span class="error">{{errorMessage}}</span> 
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()" >Cancel</div>
            <div id="changeButton" class="change-button" [ngClass]="{'disabled': isChangeButtonDisabled() }" (click)="onChangeModalClick($event)">
                Change
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>