<div class="row">
    <div class="col-4" *ngIf="reviewItemCancellationReason.contraventionDetailTypeId">
        <label class="modal-body-label">Contravention Details</label>
        <div>
            {{ getContraventionDetailsName(reviewItemCancellationReason.contraventionDetailTypeId) }}
        </div>
    </div>
    <div class="col-4">
        <label class="modal-body-label">Grounds <span style="font-weight: normal;">(Required)</span></label>
        <select id="reviewCancellationReasonTypeId" 
            name="reviewCancellationReasonTypeId"                                     
            [(ngModel)]="reviewItemCancellationReason.reviewCancellationReasonTypeId"                                    
            class="form-select modal-select"
            (change)="onCancellationReasonChanged()">
            <option class="modal-select-option" [ngValue]="0">--</option>
            <option class="modal-select-option"                        
                *ngFor="let reviewCancellationReasonType of reviewCancellationReasonTypes"                                                 
                [value]="reviewCancellationReasonType.id">{{reviewCancellationReasonType.name}}</option>
        </select>
    </div>
    <div class="col">
        <label class="modal-body-label">Description</label>
        <textarea
        [ngModel]="getCancellationReasonDescription(reviewItemCancellationReason.reviewCancellationReasonTypeId)"
        readonly rows="2"
        class="form-control"></textarea>
    </div>
</div>
<div class="row mt-4" *ngIf="isSecondaryReasonApplicable(reviewItemCancellationReason.reviewCancellationReasonTypeId)">
    <div class="col-4" *ngIf="reviewItemCancellationReason.contraventionDetailTypeId"></div>
    <div class="col-4">
        <label class="modal-body-label">Reason</label>
        <select id="reviewCancellationSecondaryReasonTypeId" 
            name="reviewCancellationSecondaryReasonTypeId"                                     
            [(ngModel)]="reviewItemCancellationReason.reviewCancellationSecondaryReasonTypeId"                                    
            class="form-select modal-select">
            <option class="modal-select-option" [ngValue]="0">--</option>
            <option class="modal-select-option"                        
                *ngFor="let reviewCancellationSecondaryReasonType of reviewCancellationSecondaryReasonTypes"                                                 
                [value]="reviewCancellationSecondaryReasonType.id">{{reviewCancellationSecondaryReasonType.name}}</option>
        </select>
    </div>
</div>