<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Cancel Review</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">            
            <div class="mb-3">
                <label class="modal-body-label" for="cancellationTypeRadio">Type of Cancellation</label>
                <div class="d-flex mt-1">                   
                    <div class="form-check custom-control-inline">
                        <input type="radio" class="form-check-input" 
                            id="cancellationTypeRecipient" name="cancellationTypeRadio"
                            [value]="1" [(ngModel)]="cancellationType" #cancellationTypeModel="ngModel"
                            required/>
                        <label class="form-check-label" for="cancellationTypeRecipient">Requested by recipient</label>
                    </div>
                    <div class="form-check custom-control-inline">
                        <input type="radio" class="form-check-input" 
                            id="cancellationTypeAdmin" name="cancellationTypeRadio"
                            [value]="2" [(ngModel)]="cancellationType" #cancellationTypeModel="ngModel"
                            required/>
                        <label class="form-check-label" for="cancellationTypeAdmin">Administrative</label>
                    </div>                    
                </div> 
                <span class="alert-text"
                    [ngClass]="{'show': cancellationTypeModel.invalid && formSubmitted }">Type of Cancellation is required</span>                       
            </div>
            <div class="mb-3">
                <label class="modal-body-label required" for="eventDetails">Reason for cancelling</label>
                <textarea 
                    id="eventDetails" 
                    name="eventDetails"                    
                    #eventDetailsModel="ngModel"
                    [(ngModel)]="eventDetails"
                    [ngClass]="{'alert-border': eventDetailsModel.invalid && formSubmitted }"
                    class="modal-textarea"                                   
                    (input)="onTextareaInput($event)"></textarea>                
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
                <span class="alert-text"
                    [ngClass]="{'show': eventDetailsModel.invalid && formSubmitted }">Reason for cancelling is required</span>
            </div>
            <div class="mb-3 pt-2">
                <div class="info-box">
                    <div class="info-icon">
                        <i class="fas fa-info-circle fa-lg"></i>
                    </div>
                    <div class="info-box-main">A notification of this cancellation will be sent to the applicant</div>        
                </div>                        
            </div>            
        </div>                        
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()" >Cancel</div>
            <div id="confirmCancellationButton" class="confirm-cancellation-button" (click)="onConfirmCancellationClick($event)">
                Confirm Cancellation
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>