<div class="d-flex flex-column">
    <div class="heading">Decision Information</div>
    <div class="mb-3 row">

        <div class="col-12 col-lg-3">
            <div class="info-container-with-icon">
                <label class="info-label">Decision Due Date</label>
                <div class="info-text">{{decisionDueDate}}</div>            
            </div>
        </div>
        
        <div class="col-12 col-lg-3">
            <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_OUTCOME">
                <div class="info-container">
                    <label class="info-label">Review Outcome</label>
                    <div class="info-text">{{reviewOutcome}}</div>            
                </div>
            </app-access-control>
        </div>        

        <div class="col-12 col-lg-3">
            <div class="info-container-with-icon" *ngIf="review?.decisionDate != null || isDecisionDateEdited">
                <div class="d-flex justify-content-start">
                    <label class="info-label">Decision Made</label>
                    <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.DATA_EDITS">
                        <div class="data-edit-button" (click)="editDecisionDateClick()"></div>
                    </app-access-control>
                </div>
                <div *ngIf="!isDecisionDateEdited" class="info-text">{{review?.decisionDate | date: "mediumDate"}}</div>
                <div *ngIf="isDecisionDateEdited">
                    <div class="d-flex justify-content-start editableField">
                        <input required #decisionDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control form-control-sm custom-text-box" id="decisionDate" name="decisionDate" placeholder="yyyy/mm/dd" [(ngModel)]="review.decisionDate" [ngClass]="{'is-invalid':decisionDateControl.dirty && decisionDateControl.invalid}">
                        <button [disabled]="isSaving" class="button-primary custom-button" (click)="editDecisionDateSaveClick(!decisionDateControl.invalid)">Ok</button>
                        <div class="invalid-feedback">
                            <div *ngIf="decisionDateControl.hasError('required')">Decision Date is required</div>
                        </div>
                    </div>
                    <span class="error">{{decisionDateErrorMessage}}</span>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3">        
            <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.DECISION_AND_UPLOAD">
                <button class="submit-decision-button" [disabled]="decisionLetter != null"
                    [ngClass]="{'disabled': decisionLetter != null}" 
                    (click)="onSubmitDecisionClick($event)">                    
                    <span>Submit Decision</span>
                </button>
            </app-access-control>
        </div>   
    </div>
    
    <div class="row">
        <div class="col-12 col-sm-12">
            <span class="error">{{errorMessage}}</span>
        </div>
    </div>

    <div class="heading">Decision Send Method</div>
    <div class="mb-3 row">
        <div class="col-12 col-lg-4">
            <div class="info-container">
                <label class="info-label">Send Decision Via</label>
                <div class="info-text">{{preferredContactMethodName}}</div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="info-container">
                <label class="info-label">Address</label>
                <div class="info-text">{{address}}</div>
            </div>             
        </div>
    </div>
    
       
    <ng-container *ngIf="decisionLetter != null">
        <div class="heading-table">Decision Details</div>
        <div class="d-flex flex-column">
            <div class="row mt-3">
                <div class="col-12 bold-text mb-2">
                    Decision Letter
                </div>
            </div>   
            <div class="mb-3 row">
                <div class="col-12 col-lg-4">
                    <div class="info-container">
                        <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.DOWNLOAD_DECISION">
                            <div class="d-flex align-items-center">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onDecisionLetterClick()" class="download-file-link">{{decisionLetter?.documentName}}</a>
                            </div> 
                        </app-access-control>
                    </div>             
                </div>
                <div class="col-12 col-lg-4">
                    <div class="info-container">
                        <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.DELETE_DECISION">
                            <a class="remove-file-link" (click)="onDeleteDecisionLetterClick()">Delete</a>
                        </app-access-control>
                    </div>
                </div>
            </div>

            <table class="table mt-3">
                <thead>
                    <tr>
                        <th *ngIf="review?.reviewItems && review?.reviewItems[0]?.vehicleSeizureId != null">Seizure Number</th>
                        <th *ngIf="review?.reviewItems && review?.reviewItems[0]?.contraventionId != null">Contravention Number</th>
                        <th>Section</th>
                        <th>Decision</th>
                    </tr>
                </thead>                            
                <tbody *ngIf="decisionLetter != null">                    
                    <tr *ngFor="let reviewItem of review.reviewItems">
                        <td>
                            <a *ngIf="reviewItem?.vehicleSeizureId != null" [routerLink]="['/seizure', reviewItem?.recordNumber]">{{reviewItem?.recordNumber}}</a>
                            <a *ngIf="reviewItem?.contraventionId != null" [routerLink]="['/contravention', reviewItem?.recordNumber]">{{reviewItem?.recordNumber}}</a>
                        </td>
                        <td>{{getContraventionTypeName(reviewItem.contraventionTypeId)}}</td>
                        <td>{{getDecisionTypeName(reviewItem.decisionTypeId)}}</td>
                    </tr>                      
                </tbody>   
            </table>        
        </div>

        <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.DECISION_AND_UPLOAD">
            <div *ngFor="let reviewItem of review.reviewItems">
                <div *ngIf="reviewItem.decisionTypeId == 2" class="mb-4 mt-3">
                    <div class="bold-text">Reason(s) for Cancellation</div>
                    <div class="row mt-2" *ngFor="let cancellationReason of reviewItem.reviewItemCancellationReasons">
                        <div class="col-12">
                            {{ getCancellationReasonName(cancellationReason.reviewCancellationReasonTypeId) }} 
                            {{ getCancellationReasonDescription(cancellationReason.reviewCancellationReasonTypeId) }}
                            <span *ngIf="cancellationReason.reviewCancellationSecondaryReasonTypeId">
                                , {{ getCancellationSecondaryReasonName(cancellationReason.reviewCancellationSecondaryReasonTypeId) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </app-access-control>
    </ng-container>

    <!-- Commented Additional Decision Documents section -->
    <!--<ng-container *ngIf="additionalDecisionDocuments != null">
        <div class="heading-table mt-3">Additional Decision Documents</div>
        <div class="d-flex">        
            <table class="table">
                <thead>
                    <tr>
                        <th>Filename</th>
                        <th>Date Added</th>
                        <th>Format</th>
                        <th>File Size</th>
                        <th></th>
                    </tr>
                </thead>                            
                <tbody *ngIf="additionalDecisionDocuments?.length > 0">                    
                    <tr *ngFor="let document of additionalDecisionDocuments">
                        <td>   
                            <div class="d-flex align-items-center">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                            </div>                                                       
                        </td>
                        <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                        <td class="document-format">{{document.documentExtension}}</td>
                        <td>{{document.documentSize}}</td>
                        <td><a class="remove-file-link" (click)="onDeleteAdditionalDecisionDocumentClick(document)">Delete</a></td>
                    </tr>                      
                </tbody>
                <tbody *ngIf="additionalDecisionDocuments?.length == 0">
                    <tr>
                        <td colspan="5">No additional decision documents found.</td>
                    </tr>
                </tbody> 
            </table>        
        </div>
    </ng-container>
    <document-upload         
        documentRefTypeName="Reviews"
        [documentRefTypeNumber]="review?.reviewNumber"
        [documentTypeId]="additionalDecisionDocumentTypeId"
        (documentAdded)="onAdditionalDecisionDocumentAdded($event)"
        [uploadAndFinalize]="true"
        [showDropZoneAfterUpload]="true"
    ></document-upload> -->
</div>

<!-- Modal -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmModalLabel">
                    Delete Decision Letter
                </span>
                <div class="close-icon" data-bs-dismiss="modal">
                    <i class="far fa-times-circle fa-lg"></i>
                </div>
            </div>
            <div class="modal-body py-0">
                <div class="delete-alert">
                    <div class="alert-icon">                
                        <i class="fas fa-exclamation-triangle fa-lg"></i>
                    </div>
                    <div class="delete-alert-text">
                        <span>Deleting this file will remove it from the system. This action cannot be undone.</span>
                        <div class="bold-text">Are you sure?</div>
                    </div>                       
                </div>
                <div class="button-container float-end">
                    <button id="btnGoBack" type="button" class="goa-button goa--secondary" data-bs-dismiss="modal">Cancel</button>
                    <button id="btnConfirm" type="button" class="goa-button goa--primary me-0" data-bs-dismiss="modal" (click)="deleteDecisionLetter()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>