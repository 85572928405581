<form #financeForm="ngForm">
  <div class="d-flex flex-column h-100">
    <div class="header">
        Financial Reports
    </div>
    <div class="secondary-header">Accounts Receivable Finance Extract</div>
  </div>
	<div class="mb-3 row">
    <div class="col-sm-3 col-left-margin">
      <label for="startDate">Start Date <span class="label-hint">(Required)</span></label>
			<input
        readonly
        required
        isLessThanStartDate="2020-12-01"
        #startDateControl="ngModel"
        type="text"
        bsDatepicker
        [bsConfig]="startDatePickerConfig"
        maxlength="10"
        class="form-control"
        id="startDate"
        name="startDate"
        placeholder="yyyy/mm/dd"
        ngModel
        [ngClass]="{'is-invalid':(startDateControl.touched) && startDateControl.invalid}">
      <div class="invalid-feedback">
        <div *ngIf="startDateControl.hasError('required')">Start Date is required</div>
          <div *ngIf="!startDateControl.hasError('required') && startDateControl.hasError('EarlierDate')">Start Date can't be earlier than 01 Dec, 2020</div>
      </div>
      <label class="note">note: start time is 10:00 pm MT of previous day from the date above</label>
    </div>
    <div class="col-sm-3 col-left-margin">
      <label for="endDate">End Date <span class="label-hint">(Required)</span></label>
			<input
        readonly
        required
        isLessThanEndDate="2020-12-01"
        #endDateControl="ngModel"
        type="text"
        bsDatepicker
        [bsConfig]="endDatePickerConfig"
        maxlength="10"
        class="form-control"
        id="endDate"
        name="endDate"
        placeholder="yyyy/mm/dd"
        ngModel
        [ngClass]="{'is-invalid':(endDateControl.touched) && endDateControl.invalid}">
      <div class="invalid-feedback">
        <div *ngIf="endDateControl.hasError('required')">End Date is required</div>
          <div *ngIf="!endDateControl.hasError('required') && endDateControl.hasError('EarlierDate')">End Date can't be earlier than 01 Dec, 2020</div>
      </div>
      <label class="note">note: end time is 9:59 pm MT of the date above</label>
    </div>
    <div class="col-sm-2 col-left-margin" >
      <label></label>
      <button *ngIf="!startDateControl.hasError('required') && !endDateControl.hasError('required')" class="btn button-primary btn-block" type="button" [disabled]="false" (click)="onDownloadClick(financeForm)">Download A/R Extract</button>
      <button *ngIf="startDateControl.hasError('required') || endDateControl.hasError('required')" class="btn button-primary btn-block" type="button" [disabled]="true" (click)="onDownloadClick(financeForm)">Download  A/R Extract</button>
    </div>
  </div>
  <div>
    <div class="secondary-header-top-border">Accounts Payable Finance Extract</div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-3 col-left-margin">
      <label for="startPayableDate">Start Date <span class="label-hint">(Required)</span></label>
			<input
        readonly
        required
        isLessThanStartDate="2020-12-01"
        #startPayableDateControl="ngModel"
        type="text"
        bsDatepicker
        [bsConfig]="startPayableDatePickerConfig"
        maxlength="10"
        class="form-control"
        id="startPayableDate"
        name="startPayableDate"
        placeholder="yyyy/mm/dd"
        ngModel
        [ngClass]="{'is-invalid':(startPayableDateControl.touched) && startPayableDateControl.invalid}">
      <div class="invalid-feedback">
        <div *ngIf="startPayableDateControl.hasError('required')">Start Date is required</div>
          <div *ngIf="!startPayableDateControl.hasError('required') && startPayableDateControl.hasError('EarlierDate')">Start Date can't be earlier than 01 Dec, 2020</div>
      </div>
      <label class="note">note: start time is 10:00 pm MT of the previous day from the date above</label>
    </div>
    <div class="col-sm-3 col-left-margin">
      <label for="endDate">End Date <span class="label-hint">(Required)</span></label>
			<input
        readonly
        required
        isLessThanEndDate="2020-12-01"
        #endPayableDateControl="ngModel"
        type="text"
        bsDatepicker
        [bsConfig]="endPayableDatePickerConfig"
        maxlength="10"
        class="form-control"
        id="endPayableDate"
        name="endPayableDate"
        placeholder="yyyy/mm/dd"
        ngModel
        [ngClass]="{'is-invalid':(endPayableDateControl.touched) && endPayableDateControl.invalid}">
      <div class="invalid-feedback">
        <div *ngIf="endPayableDateControl.hasError('required')">End Date is required</div>
          <div *ngIf="!endPayableDateControl.hasError('required') && endPayableDateControl.hasError('EarlierDate')">End Date can't be earlier than 01 Dec, 2020</div>
      </div>
      <label class="note">note: end time is 9:59 pm MT of the date above</label>
    </div>
    <div class="col-sm-2 col-left-margin">
      <label></label>
      <button *ngIf="!startPayableDateControl.hasError('required') && !endPayableDateControl.hasError('required')" class="btn button-primary btn-block" type="button" [disabled]="false" (click)="onPayableDownloadClick(financeForm)">Download A/P Extract</button>
      <button *ngIf="startPayableDateControl.hasError('required') || endPayableDateControl.hasError('required')" class="btn button-primary btn-block" type="button" [disabled]="true" (click)="onPayableDownloadClick(financeForm)">Download A/P Extract</button>
    </div>
  </div>
</form>
