<div class="d-flex flex-column h-100">
    <div class="header">
        <div class="top-header">
            <div class="d-flex">Reviews</div>
        </div>
    </div>
    <div class="main" *ngIf="judicialReview != null">
        <form #reReviewForm="ngForm">
        <div class="tabs-container">
            <div class="tabs">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="heading-table">Re-Review</div>
                    </div>                
                </div>
                <div class="mb-3">
                    <div class="row mt-4">
                        <div class="col-4" style="position: relative;">
                            <label class="label label-strong" for="judicialReviewDecisionSubmittedDate">Decision Submitted to APIS</label>
                            <input type="text" readonly class="form-control" id="judicialReviewDecisionSubmittedDate"
                                name="judicialReviewDecisionSubmittedDate" 
                                value="{{ judicialReview.judicialReviewDecisionSubmittedDate | date: 'mediumDate'}}"
                                />
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12" style="position: relative;">
                            <label class="label">Court Document</label>
                            <div class="d-flex align-items-center">
                                <img class="download-file-icon me-1" src="assets/images/download-file.png" />
                                <a (click)="onDocumentNameClick()"
                                    class="download-file-link">{{ courtDocument?.documentName }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <label class="label">Review Details</label>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <label class="label label-strong" for="reviewHeldByDate">Review Held By</label>
                            <input type="text" required class="form-control"
                                id="reviewHeldByDate" name="reviewHeldByDate" bsDatepicker
                                [disabled]="hasNoReviewHeldByDate"
                                [bsConfig]="datePickerConfig" [dateCustomClasses]="dateCustomClasses" placeholder="MMM DD, YYYY"
                                [(ngModel)]="review.reviewHeldByDate" #reviewHeldByDateModel="ngModel"
                                [ngClass]="{ 'alert-border': reviewHeldByDateModel.invalid && formSubmitted}" />
                            <span class="alert-text" 
                                [ngClass]="{'show': reviewHeldByDateModel.invalid && formSubmitted }">Date is required</span>
                        </div>
                        <div class="col-3">
                            <label class="label label-strong" for="reviewDecisionDueDate">Decision Due Date</label>
                            <input type="text" class="form-control"
                                id="reviewDecisionDueDate" name="reviewDecisionDueDate" bsDatepicker
                                [bsConfig]="datePickerConfig" [dateCustomClasses]="dateCustomClasses" placeholder="MMM DD, YYYY"
                                [(ngModel)]="review.decisionDueDate" #reviewDecisionDueDateModel="ngModel" />
                        </div>
                        <div class="col-12 mt-3">
                            <div class="custom-checkbox">
                                <input type="checkbox" id="chkNoReviewHeldByDate" name="chkNoReviewHeldByDate" 
                                [(ngModel)]="hasNoReviewHeldByDate" (change)="onReviewHeldByDateChkChanged()">
                                <label for="chkNoReviewHeldByDate">Order did not contain a "Review Held By date"</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div *ngIf="judicialReview.review.reviewTypeId == ReviewTypes.IrsReview || judicialReview.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview" class="col-12 mt-2">
                            <div class="custom-checkbox">
                                <input type="checkbox" id="chkLawEnforcementAddSupportingDocs" name="chkLawEnforcementAddSupportingDocs" [(ngModel)]="review.isLEAAllowedToAddSupportingDocs">
                                <label for="chkLawEnforcementAddSupportingDocs">Allow law enforcement to add supporting documents</label>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="custom-checkbox">
                                <input type="checkbox" id="chkEditReviewGrounds" name="chkEditReviewGrounds" [(ngModel)]="review.isAllowedToEditReviewGrounds">
                                <label for="chkEditReviewGrounds">Allow recipient to edit grounds for review</label>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="custom-checkbox">
                                <input type="checkbox" id="chkEditSupportingDocuments" name="chkEditSupportingDocuments" [(ngModel)]="review.isAllowedToAmendSupportingDocs">
                                <label for="chkEditSupportingDocuments">Allow recipient to add or amend supporting documents</label>
                            </div>
                        </div>
                        <div class="col-12 mt-2" *ngIf="judicialReview.contraventionId">
                            <div class="custom-checkbox">
                                <input type="checkbox" id="chkEditReviewMethod" name="chkEditReviewMethod" [(ngModel)]="review.isAllowedToEditReviewMethod">
                                <label for="chkEditReviewMethod">Allow recipient to edit review method</label>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="custom-checkbox">
                                <input type="checkbox" id="chkAssignToSameAdjudicator" name="chkAssignToSameAdjudicator" [(ngModel)]="review.isReReviewBySameAdjudicator">
                                <label for="chkAssignToSameAdjudicator">To be re-reviewed by the same adjudicator</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-4">
                            <label class="label label-strong" for="contactMethod">Preferred Contact Method</label>
                            <select class="form-select" id="contactMethod" name="contactMethod" disabled>
                                <option value="1">Email</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-lg-4">
                            <label class="label label-strong required" for="email">Email Address</label>
                            <input type="text" id="email" name="email" 
                                required class="form-control"
                                #email="ngModel"                               
                                [(ngModel)]="review.email"
                                placeholder="Email address"                                     
                                [ngClass]="{'alert-border': email.invalid && formSubmitted}"/>
                            <span class="alert-text" 
                                [ngClass]="{'show': email.invalid && formSubmitted }">Email address is required</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <span class="error">{{errorMessage}}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <button id="cancelButton" class="goa-button goa--secondary cancel-button" (click)="onCancelClick()">Cancel</button>
                    <button id="submitButton" class="goa-button submit-button" (click)="onCreateClick(reReviewForm.valid)">Create</button>
                </div>
            </div>
        </div>
    </form>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>