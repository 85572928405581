import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { RequestTypes, RequestDecisionTypes, RequestDenyReasonTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DecisionRequest } from '@apis/shared/models/decision-request.model';
import { DecisionType } from '@apis/shared/models/types/decision-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RequestService } from '../../../shared/services/request.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { Constants } from "@apis/shared/helpers/constants";
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { RequestDenyReasonType } from '@apis/shared/models/types/request-deny-reason-type.model';
import { NoticeCancellationRequestMapping } from '@apis/shared/models/notice-cancellation-request-mapping.model';

declare var $: any

@Component({
  selector: 'app-approve-or-deny-request',
  templateUrl: './approve-or-deny-request.component.html',
  styleUrls: ['./approve-or-deny-request.component.scss']
})
export class ApproveOrDenyRequestComponent implements OnInit, OnChanges {
  @Input() request: any;
  @Input() requestType: any;
  @Output() decisionSubmitted = new EventEmitter<any>();
  
  noticeCancellationRequest:NoticeCancellationRequest;

  decisionTypes: DecisionType[] = [];
  requestDenyReasonTypes: RequestDenyReasonType[] = [];
  
  RequestTypes = RequestTypes;
  RequestDecisionTypes = RequestDecisionTypes;
  RequestDenyReasonTypes = RequestDenyReasonTypes;
  TypeTable = TypeTable;

  requestNumber = '';
  decisionDate = '-';  
  
  decisionRequest = new DecisionRequest({    
    decisionTypeId: null
  });
  errorMessage = '';
  sysDetailedErrorMessage = '';
  sysErrorMessage = '';
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;  

  isContravention:boolean;

  @ViewChild("decisionForm") decisionForm: NgForm;

  constructor(
    public localStorageService: LocalStorageService,
    private requestService: RequestService,
    private readonly documentService: DocumentService,
    private readonly spinner: NgxSpinnerService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.decisionTypes = this.localStorageService.getRequestDecisionTypes();        
    this.requestDenyReasonTypes = this.localStorageService.getRequestDenyReasonTypes();       
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.request?.currentValue != null) { 
      this.loadData();
    }
  }
  private loadData(): void {    
    if (this.request.decisionDate) {
      this.decisionDate = this.datePipe.transform(this.request.decisionDate, 'mediumDate');
    }
        
    this.noticeCancellationRequest = this.request;    
    this.requestNumber = this.noticeCancellationRequest.noticeCancellationRequestNumber;     
    if(this.noticeCancellationRequest.noticeCancellationRequestMappings[0].contraventionId) 
    {
      this.isContravention = true;
    }
    else
    {
      this.isContravention = false;
    }
  }


  onCloseIconClick(): void {  
    $('#submitDecisionModal').modal('hide');
    this.decisionForm.resetForm();
  }

  onSubmitClick() {    
    if (this.decisionForm.valid) {
      this.spinner.show();            
      if (this.requestType == RequestTypes.LEACancellationRequest.toString()) {
        this.requestService.submitNoticeCancellationRequestDecision(this.decisionRequest)
          .pipe(
            finalize(() => {
              this.spinner.hide(); 
            })
          )
          .subscribe(
            (result: any) => {
              $('#submitDecisionModal').modal('hide');
              this.request = result;                            
              this.noticeCancellationRequest = result;

              this.loadData();
              this.decisionSubmitted.emit(this.request);
            },
            (error: any) => {
              this.sysErrorMessage = 'An error occurred while processing the request.';
              this.sysDetailedErrorMessage = this.getDetailedError(error);
            });
      }
    }
  }

  getDetailedError(error: any): string {
    let errMsg = '';
    if (error?.error && Array.isArray(error?.error))
      errMsg= error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      errMsg = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      errMsg = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      errMsg = error.error.errors;  
    else    
      errMsg = '';

    return errMsg;
  }

  showDecisionModal() {
    this.decisionRequest = new DecisionRequest({      
      decisionTypeId: null,
      requestNumber: this.requestNumber,
      requestDenyReasonTypeId:null,
      requestDenyReasonNotes:null   
    });
    this.errorMessage = '';
    this.sysErrorMessage = '';
    this.sysDetailedErrorMessage = '';

    $('#submitDecisionModal').modal('show');    
  }
  
}
