<div class="d-flex flex-column">
	<div class="d-flex justify-content-between align-items-center" style="margin-top: 22px;">
		<div class="heading-table">Review Information</div>
		<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.ATTEND_REVIEW">
			<a class="attend-review-button" href="{{review?.videoConferenceUrl}}" target="_blank">Attend Review</a>
		</app-access-control>
	</div>
	<div class="d-flex mt-3 mb-3 flex-column">
		<table class="table">
			<thead>
				<tr>
					<th>Review Date & Time</th>
					<th>Review Method</th>
					<th>WebEx Access Code</th>
					<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_OUTCOME">
						<th>Review Outcome</th>
					</app-access-control>
					<app-access-control [Resource]="Resource.REVIEW"
						[Permission]="Permission.REVIEW_OUTCOME_READ_ONLY">
						<th>Review Outcome</th>
					</app-access-control>
					<th></th>
				</tr>
			</thead>

			<tbody *ngIf="review && review.outcomes?.length > 0">
				<tr *ngFor="let outcome of review.outcomes; let i=index">
					<td class="review-date-time">
						<div>{{outcome.reviewDate | date:"mediumDate"}} at {{outcome.reviewTime.substring(0,5)}}</div>
					</td>
					<td class="review-method">
						<div>{{getReviewMethodTypeName(outcome.reviewMethodTypeId)}}</div>
					</td>
					<td *ngIf="outcome.reviewMethodTypeId == reviewMethodTypes.Oral && i == review.outcomes.length-1; else elseMeetingKey" class="meeting-key">
						<div>{{meetingKey}}</div>
					</td>
					<ng-template #elseMeetingKey>
						<td class="meeting-key"><div></div></td>
					</ng-template>
					<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_OUTCOME">
						<td class="outcome-type">
							<div *ngIf="i < review.outcomes.length-1">{{getReviewOutcomeTypeName(i)}}</div>
							<div *ngIf="i == review.outcomes.length-1 && !canEditReviewOutcome">
								{{getReviewOutcomeTypeName(i)}}</div>
							<div *ngIf="i == review.outcomes.length-1 && canEditReviewOutcome">
								<select id="outcomeType" name="outcomeType" #outcomeType="ngModel"
									[(ngModel)]="outcome.reviewOutcomeTypeId" (change)="onOutcomeChange(outcome)"
									class="form-select modal-select">
									<option class="modal-select-option" [ngValue]="null">Select Review Outcome</option>
									<option class="modal-select-option"
										*ngFor="let reviewOutcomeType of reviewOutcomeTypes"
										[value]="reviewOutcomeType.id">{{reviewOutcomeType.name}}</option>
								</select>
							</div>
						</td>
					</app-access-control>
					<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_OUTCOME_READ_ONLY">
						<td class="outcome-type">
							<div>{{getReviewOutcomeTypeName(i)}}</div>
						</td>
					</app-access-control>
					<td></td>
				</tr>
			</tbody>
		</table>
		<div>
			<span class="error">{{outcomeErrorMessage}}</span>
		</div>
	</div>
	<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_UPLOADS">
		<div class="d-flex justify-content-between align-items-center" style="margin-top: 42px;">
			<div class="heading-table">Review Uploads</div>
		</div>
		<div class="d-flex mt-3 mb-3 flex-column">
			<table class="table">
				<thead>
					<tr>
						<th>Published</th>
						<th>Filename</th>
						<th>Date Added</th>
						<th>Format</th>
						<th>File Size</th>
						<th></th>
					</tr>
				</thead>

				<tbody *ngIf="reviewUploads?.length > 0">
					<tr *ngFor="let document of reviewUploads">
						<td>
							<div *ngIf="!canEdit" class="publish-checkbox-container" [ngClass]="{'active': document.isPublished }">
								<div class="publish-checkbox disabled"></div>
							</div>
							<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_UPLOADS_EDIT">
								<div class="publish-checkbox-container" [ngClass]="{'active': document.isPublished }"
								(click)="onPublishCheckboxClick(document)">
									<div class="publish-checkbox"></div>
								</div>
							</app-access-control>
						</td>
						<td>
							<div class="d-flex align-items-center">
								<img class="download-file-icon" src="assets/images/download-file.png" />
								<a (click)="onDocumentNameClick(document)"
									class="download-file-link">{{document.documentName}}</a>
							</div>
						</td>
						<td>{{document.uploadedDate | date: "mediumDate"}}</td>
						<td class="document-format">{{document.documentExtension}}</td>
						<td>{{document.documentSize}}</td>
						<td><app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_UPLOADS_EDIT">
							<a class="remove-file-link" (click)="onDeleteReviewUploadClick(document)">Delete</a>
						</app-access-control>
						</td>
					</tr>
				</tbody>
				<tbody *ngIf="reviewUploads?.length == 0">
					<tr>
						<td colspan="5">No review uploads found.</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_UPLOADS_EDIT">
			<document-upload documentRefTypeName="Reviews" [documentRefTypeNumber]="review?.reviewNumber"
			[documentTypeId]="reviewUploadsDocumentTypeId" [showDropZoneAfterUpload]="true" [uploadAndFinalize]="true"
			(documentAdded)="onReviewUploadAdded($event)"></document-upload>
		</app-access-control>
	</app-access-control>

	<app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_FILE_CLOSURE">
		<ng-container *ngIf="review?.isActionRequired">
			<div class="heading-table mb-4">File Closure</div>
			<div class="d-flex justify-content-start align-items-center file-closure-section">
				<label class="checkbox-label">File closure complete</label>
				<div class="closure-icon">
					<i class="fas fa-exclamation-triangle fa-xs"></i>
				</div>
				<input class="big-checkbox" type="checkbox" id="isFileClosureComplete" name="isFileClosureComplete"
					(change)="onFileClosureCompleteClick()">
			</div>
		</ng-container>
	</app-access-control>
</div>

<!-- Modal -->
<div class="modal" id="publishReviewUploadModal" role="dialog" aria-labelledby="publishReviewUploadModalLabel"
	tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg">
		<div class="modal-content p-4">
			<div>
				<h3 class="mt-0"><span class="fw-bold">Publish {{document?.documentName}}</span></h3>
				<div class="goa-callout goa--emergency">
					Publishing this file will make it visible to the recipient. <span class="fw-bold">Are you
						sure?</span>
				</div>
				<div class="modal-footer border-0 d-flex justify-content-right">
					<button type="button" class="goa-button goa--secondary mt-2" data-bs-dismiss="modal">Cancel</button>
					<button type="button" class="goa-button mt-2" data-bs-dismiss="modal"
						(click)="onSubmitClick()">Publish</button>
				</div>
			</div>
		</div>
	</div>
</div>