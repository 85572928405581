import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { PoliceServiceInformation } from './../../../../../../../libs/shared/models/police-service-information.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { Recipient } from './../../../../../../../libs/shared/models/recipient.model';
import { RecipientIdentification } from './../../../../../../../libs/shared/models/recipient-identification.model';
import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, ViewContainerRef, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { ContactMethodTypes, DocumentTypes, DriverDeclinedToTestTypes, HighwayCharacteristicsTypes, IdentificationTypes, JudicialReviewDecisionTypes, OfficerTransportationTypes, OfficerUniformTypes, RecipientTypes, RefusalOrFailedClassification, RepresentationTypes, RequestTypes, ReviewMethodTypes, ReviewTypes, RoadConditionTypes, RoadSurfaceTypes, SampleClassification, SeizureTypes, StayOrderStatusTypes, TestTypes, VisibilityConditionTypes, WeatherConditionTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Document } from '@apis/shared/models/document.model';
import { JudicialReview } from '@apis/shared/models/judicial-review.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from "file-saver";
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { Constants } from "@apis/shared/helpers/constants";
import { StayOrderComponent } from '../../modals/stay-order/stay-order.component';
import { StayOrder } from '@apis/shared/models/stay-order.model';
import { JudicialReviewDecisionComponent } from '../../modals/judicial-review-decision/judicial-review-decision.component';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import * as html2pdf from 'html2pdf.js';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import * as moment from 'moment';
import { Review } from '@apis/shared/models/review.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { _ } from 'ag-grid-community';

@Component({
  selector: 'judicial-review',
  templateUrl: './judicial-review.component.html',
  styleUrls: ['./judicial-review.component.scss']
})
export class JudicialReviewComponent implements OnChanges {

  @Input() judicialReview: JudicialReview;
  @Output() judicialReviewUpdated = new EventEmitter<JudicialReview>();
  @ViewChild("contraventionNotice") contraventionNotice: ElementRef;
  @ViewChild("seizureNotice") seizureNotice: ElementRef;
  @ViewChild("reviewRequestNotice") reviewRequestNotice: ElementRef;
  @ViewChild("lateReviewRequestNotice") lateReviewRequestNotice: ElementRef;
  contraventionNoticeUrl: string;
  seizureNoticeUrl: string;
  reviewRequestNoticeUrl: string;
  lateReviewRequestNoticeUrl: string;
  judicialReviewUploadsDocumentTypeId: number = DocumentTypes.JudicialReviewUploads;
  judicialReviewUploads: Document[];
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  driversLicenceStatusTypeName: string;
  reviewRepresentationTypeName: string;
  lateReviewRepresentationTypeName: string;
  reviewMethodTypeName: string;
  reviewContactMethodTypeName: string;
  lateReviewContactMethodTypeName: string;
  reviewTermsConditionsStatement: string;
  lateReviewTermsConditionsStatement: string;
  relativeLocation: string;
  weatherConditions: string;
  roadSurface: string;
  RoadConditions: string;
  visibility: string;
  observedBy: string;
  witnessFirstName: string;
  witnessLastName: string;
  witnessDateOfBirth: string;
  circumstancesForStop: string;
  suspectedImpairmentType: string;
  wasScreeningMethodUsed: string;
  reasonableSuspicionOrMas: string;
  reasonableSuspicionBasedOn: string;
  impairmentScreeningMethod: string;
  reasonableGroundsDeclaration: string;
  reviewApplicantName: string;
  reviewDateTime: Date;
  lateReviewApplicantName: string;
  reviewRepTitle: string;
  lateReviewRepTitle: string;
  policeServiceInfo: PoliceServiceInformation;
  vehicle: Vehicle;
  SDPVehicle: Vehicle;
  policeFileNumber: string;
  showNoticesInformation: boolean;
  isPhase2Submission: boolean;
  isVehicleSeizureSectionVisible: boolean;
  isVehicleSectionVisible: boolean;
  isSDPVehicleSectionVisible: boolean;
  showSupportingDocumentDeclarations: boolean;
  showPassengers: boolean;
  showOralReviewElection: boolean;
  showPublishedDate: boolean;
  isBodycamVideoActive: boolean = false;
  progress = 0;
  zipTotalSize = 0;
  isJrdownloadStarted: boolean = false;
  isStaydownloadStarted: boolean = false;

  
  document: Document;
  recipientInformation: SectionDetailsModel[] = [];
  SDPRecipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  SDPRecipientIdentificationInformation: SectionDetailsModel[] = [];
  recipientContactInformation: SectionDetailsModel[] = [];
  SDPRecipientContactInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[] = [];
  contraventionType: SectionDetailsModel[] = [];
  occurrenceTimeAndLocationInformation: SectionDetailsModel[] = [];
  locationInformation: SectionDetailsModel[] = [];
  occurrenceInformation: SectionDetailsModel[] = [];
  witnessInformation: SectionDetailsModel[] = [];
  contraventionGrounds: SectionDetailsModel[] = [];
  impairmentScreeningInformation: SectionDetailsModel[] = [];
  impairmentScreeningDetails: SectionDetailsModel[] = [];
  additionalScreeningInformation: SectionDetailsModel[][] = [];
  policeNarrativeDetails: SectionDetailsModel[] = [];
  SDPPoliceNarrativeDetails: SectionDetailsModel[] = [];
  roadsideAppealInformation: SectionDetailsModel[] = [];
  roadsideAppealDetails: SectionDetailsModel[] = [];
  officerInformation: SectionDetailsModel[] = [];
  reasonableGrounds: SectionDetailsModel[] = [];
  seizureInformation: SectionDetailsModel[] = [];
  SDPSeizureInformation: SectionDetailsModel[] = [];
  towLotInformation: SectionDetailsModel[] = [];
  SDPTowLotInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  SDPVehicleInformation: SectionDetailsModel[] = [];
  trailerInformation: SectionDetailsModel[] = [];
  SDPTrailerInformation: SectionDetailsModel[] = [];
  registeredOwnerInformation: SectionDetailsModel[] = [];
  SDPRegisteredOwnerInformation: SectionDetailsModel[] = [];
  vehicleDriverInformation: SectionDetailsModel[] = [];
  SDPVehicleDriverInformation: SectionDetailsModel[] = [];
  policeServiceInformation: SectionDetailsModel[] = [];
  SDPPoliceServiceInformation: SectionDetailsModel[] = [];
  declarationInformation: SectionDetailsModel[] = [];
  SDPDeclarationInformation: SectionDetailsModel[] = [];
  supportingDocumentDeclarationInformation: SectionDetailsModel[] = [];
  SDPSupportingDocumentDeclarationInformation: SectionDetailsModel[] = [];
  reServiceInformation: SectionDetailsModel[] = [];
  SDPReServiceInformation: SectionDetailsModel[] = [];
  TypeTable = TypeTable;
  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;
  Adjudication: any = Constants.Adjudication;
  JudicialReviewDecisionTypes = JudicialReviewDecisionTypes;
  StayOrderStatusTypes = StayOrderStatusTypes;
  RecipientTypes = RecipientTypes;
  RepresentationTypes = RepresentationTypes;
  ContactMethodTypes = ContactMethodTypes;
  recipient: Recipient;
  recipientIdentification: RecipientIdentification;
  SDPRecipient: Recipient;
  SDPRecipientIdentification: RecipientIdentification;
  contravention: Contravention;
  stopInformation: StopInformation;
  vehicleSeizure: VehicleSeizure;
  SDPVehicleSeizure: VehicleSeizure;
  review: Review;
  reviewMethodTypes = ReviewMethodTypes;
  reviewItems: ReviewItem[];
  lateReviewRequest: any;
  judicialReviewDocuments: Document[] = [];
  reviewDocuments: Document[] = [];
  reviewSupportingDocuments: Document[] = [];
  lateReviewDocuments: Document[] = [];
  supportingDocuments: Document[] = [];
  SDPSupportingDocuments: Document[] = [];
  notices: any[] = [];

  constructor(private readonly documentService: DocumentService,
    private readonly adjudicationService: AdjudicationService,
    readonly viewContainerRef: ViewContainerRef,
    readonly localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private http: HttpClient) { }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.judicialReview?.currentValue != null) {

      this.documentRefTypeName = "JudicialReviews";
      this.documentRefTypeNumber = this.judicialReview.judicialReviewNumber;

      this.populateJudicialReviewUploads();
    }
  }

  private populateJudicialReviewUploads(): void {
    this.judicialReviewUploads = this.judicialReview.documents;
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService.downloadDocument("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, document.documentName);
      },
        (error: any) => { });
  }

  onDeleteUploadClick(document: Document): void {
    if (document.version == Constants.DocumentVersion.VERSION3) {
      this.documentService.deletePermanentBlobDocument(
        document.documentId,
        document.contentGuid,
        `${document.contentGuid}.${document.documentExtension}`,
        this.documentRefTypeName,
        this.documentRefTypeNumber
      )
      .subscribe((result: any) => {
        this.adjudicationService.getJudicialReviewByNumber(this.judicialReview.judicialReviewNumber)
        .subscribe((result: JudicialReview) => {
          if (result != null) {
            this.judicialReview = result;
            this.populateJudicialReviewUploads();
          }
        });
      });
    }
    else {
      this.documentService.deletePermanentDocument(document.documentId, document.contentGuid)
      .subscribe((result: any) => {
        this.adjudicationService.getJudicialReviewByNumber(this.judicialReview.judicialReviewNumber)
        .subscribe((result: JudicialReview) => {
          if (result != null) {
            this.judicialReview = result;
            this.populateJudicialReviewUploads();
          }
        });
      });
    }
  }

  onJudicialReviewUploadAdded(document: Document): void {
    document.isPublished = false;
    this.adjudicationService.postDocument(this.judicialReview.judicialReviewId, document)
      .subscribe((result: JudicialReview) => {
        this.judicialReview = result;
        this.populateJudicialReviewUploads();
      });
  }

  onDownloadPackageClick(): void {
    // Get recipient submissions and review uploads from review

    this.isJrdownloadStarted = true;

    this.reviewDocuments = this.judicialReview.review.documents;

    this.reviewSupportingDocuments = this.judicialReview.review.documents.filter(d => +d.documentTypeId != DocumentTypes.ReviewDecisionLetter &&
      (+d.documentTypeId != DocumentTypes.ReviewUploads || (+d.documentTypeId == DocumentTypes.ReviewUploads && d.isPublished == true)));

    let documentTypes = [DocumentTypes.RequestDecisionLetter, DocumentTypes.AdditionalDecisionDocument, DocumentTypes.Note];
    this.lateReviewDocuments = this.judicialReview.lateReviewRequest?.documents.filter((d: Document) => !documentTypes.includes(+d.documentTypeId));

    if (this.reviewItems == null) {
      this.reviewItems = this.judicialReview.review.reviewItems;
    }

    this.spinner.show();

    // Get all LEA submissions
    var invalidDocumentTypes: DocumentTypes[] = [
      DocumentTypes.Note,
      DocumentTypes.ContraventionCancellationAuthorization,
      DocumentTypes.ContraventionCancellationAdditional,
      DocumentTypes.VehicleSeizureCancellationAuthorization,
      DocumentTypes.VehicleSeizureCancellationAdditional
    ];

    if (this.judicialReview.contraventionId != null) {
      this.adjudicationService.getContraventionById(this.judicialReview.contraventionId)
        .subscribe((contravention: Contravention) => {
          this.contravention = contravention;
          this.judicialReviewDocuments = this.contravention.documents.filter(d => !invalidDocumentTypes.includes(+d.documentTypeId));

          this.judicialReviewDocuments = this.judicialReviewDocuments.filter(d => d.isDeleted == null || d.isDeleted != true);

          this.documentRefTypeName = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
          this.documentRefTypeNumber = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1
            ? this.contravention.contraventionNumber
            : this.contravention.stopInformationId.toString();

          this.adjudicationService.getStopInformation(this.contravention?.contraventionNumber)
            .subscribe((stopInformation: StopInformation) => {
              this.contravention.stopInformation = stopInformation;
              this.getNoticesInformation();
              this.getDownloadPackage(this.judicialReviewDocuments, false);
              this.spinner.hide();
            });
        });
    }

    if (this.judicialReview.vehicleSeizureId != null) {
      this.adjudicationService.getSeizureById(this.judicialReview.vehicleSeizureId)
        .subscribe((vehicleSeizure: VehicleSeizure) => {
          this.vehicleSeizure = vehicleSeizure;
          if (this.vehicleSeizure.contraventionId != null) {
            this.adjudicationService.getContraventionById(this.vehicleSeizure.contraventionId)
              .subscribe((result: Contravention) => {
                this.contravention = result;
                this.judicialReviewDocuments = result.documents.filter(d => !invalidDocumentTypes.includes(+d.documentTypeId));

                this.judicialReviewDocuments = this.judicialReviewDocuments.filter(d => d.isDeleted == null || d.isDeleted != true);

                this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
                this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? result.contraventionNumber : result.stopInformationId.toString();
              });
          } else {
            this.documentRefTypeName = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "VehicleSeizures" : "Stop";
            this.documentRefTypeNumber = this.vehicleSeizure.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? this.vehicleSeizure.seizureNumber : this.vehicleSeizure.stopInformationId.toString();
            this.judicialReviewDocuments = this.vehicleSeizure.documents.filter(d => !invalidDocumentTypes.includes(+d.documentTypeId));
            this.judicialReviewDocuments = this.judicialReviewDocuments.filter(d => d.isDeleted == null || d.isDeleted != true);
          }

          this.adjudicationService.getStopInformationById(this.vehicleSeizure.stopInformationId)
            .subscribe((stopInformation: StopInformation) => {
              this.stopInformation = stopInformation;
              if (this.contravention != null) {
                this.contravention.stopInformation = stopInformation;
              }
              this.getNoticesInformation();
              this.getDownloadPackage(this.judicialReviewDocuments, false);
              this.spinner.hide();
            });
        });
    }
  }

  private sortDocuments(): void {
    this.judicialReviewDocuments.sort((a: Document, b: Document) => {
      if (+a.documentTypeId > +b.documentTypeId) {
        return 1;
      }

      if (+a.documentTypeId < +b.documentTypeId) {
        return -1
      }

      return 0;
    });
  }

  private getDownloadPackage(documents: Document[], isStayOrder: boolean) {
    var zipFolder = "";

    this.changeDetectorRef.detectChanges();

    documents.forEach(doc => {
      doc.documentRefTypeName = doc.documentTypeId == DocumentTypes.OverdueFineNotice? "Contraventions" : this.documentRefTypeName;
      doc.documentRefTypeNumber = doc.documentTypeId == DocumentTypes.OverdueFineNotice? this.contravention?.contraventionNumber : this.documentRefTypeNumber;
    });

    this.reviewDocuments.forEach(doc => {
      doc.documentRefTypeNumber = this.judicialReview.review.reviewNumber;
      doc.documentRefTypeName = "Reviews";
      this.judicialReviewDocuments.push(doc);
    });

    this.sortDocuments();

    if (isStayOrder) {
      zipFolder = "stayOrderDocuments.zip";
    }
    else {
      zipFolder = "judicialReviewDocuments.zip";
    }

    var counter = 0;

    var that = this;

    if (this.contraventionNotice != null) {
      counter++;
      this.contraventionNotice.nativeElement.style.display = "block";

      html2pdf().from(this.contraventionNotice.nativeElement).set({
        margin: 0.1,
        image: { type: 'jpeg', quality: 1.00 },
        html2canvas: { scale: 3 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }).outputPdf().then(doc => {
        that.contraventionNoticeUrl = btoa(doc);

        var name = `APIS Export - ${that.contravention.contraventionNumber} Contravention Details.pdf`;

        that.notices.push({
          Name: name,
          Url: that.contraventionNoticeUrl
        });

        that.finalizeDocumentsForPackage(documents, isStayOrder, counter);
      });
    }

    if (this.seizureNotice != null) {
      counter++;
      this.seizureNotice.nativeElement.style.display = "block";

      html2pdf().from(this.seizureNotice.nativeElement).set({
        margin: 0.1,
        image: { type: 'jpeg', quality: 1.00 },
        html2canvas: { scale: 3 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }).outputPdf().then(doc => {
        that.seizureNoticeUrl = btoa(doc);

        var name = `APIS Export - ${that.SDPVehicleSeizure.seizureNumber != null ? that.SDPVehicleSeizure.seizureNumber : that.vehicleSeizure.seizureNumber} Seizure Details.pdf`;

        that.notices.push({
          Name: name,
          Url: that.seizureNoticeUrl
        });

        that.finalizeDocumentsForPackage(documents, isStayOrder, counter);
      });
    }

    if (this.reviewRequestNotice != null) {
      counter++;
      this.reviewRequestNotice.nativeElement.style.display = "block";

      html2pdf().from(this.reviewRequestNotice.nativeElement).set({
        margin: 0.1,
        image: { type: 'jpeg', quality: 1.00 },
        html2canvas: { scale: 3 },
        pagebreak: { mode: 'legacy' },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }).outputPdf().then(doc => {
        that.reviewRequestNoticeUrl = btoa(doc);

        var name = `APIS Export - ${that.review.reviewNumber} Request For Review.pdf`;

        that.notices.push({
          Name: name,
          Url: that.reviewRequestNoticeUrl
        });

        that.finalizeDocumentsForPackage(documents, isStayOrder, counter);
      });
    }

    if (this.lateReviewRequestNotice != null) {
      counter++;
      this.lateReviewRequestNotice.nativeElement.style.display = "block";

      html2pdf().from(this.lateReviewRequestNotice.nativeElement).set({
        margin: 0.1,
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 3 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }).outputPdf().then(doc => {
        var lateReviewRequestNoticeUrl = btoa(doc);

        var name = `APIS Export - ${that.lateReviewRequest.lateReviewRequestNumber} Request For Late Review.pdf`;

        that.notices.push({
          Name: name,
          Url: lateReviewRequestNoticeUrl
        });

        that.finalizeDocumentsForPackage(documents, isStayOrder, counter);
      });
    }
  }

  finalizeDocumentsForPackage(documents, isStayOrder, counter) {

    if (this.notices.length == counter) {

      var zipFolder = "";

      if (isStayOrder) {
        zipFolder = "stayOrderDocuments.zip";
      }
      else {
        zipFolder = "judicialReviewDocuments.zip";
      }

      var termsAndConditionsDate = new Date(this.judicialReview.termsAndConditionsDate).getFullYear() + "-" + (new Date(this.judicialReview.termsAndConditionsDate).getMonth() + 1);

      this.adjudicationService.downloadDocumentsPackage(documents, this.notices, this.contravention?.submissionDate != null ? this.contravention?.submissionDate.toString() : "", termsAndConditionsDate)
       .subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.ResponseHeader) {
            this.zipTotalSize = parseInt(event.headers.get('X-File-Size'));
          }
          if (event.type === HttpEventType.DownloadProgress) {
            this.progress = Math.round(100 * event.loaded / this.zipTotalSize);
          }
          else if (event instanceof HttpResponse) {
            // Save the file when download is complete
            fileSaver.saveAs(event.body, zipFolder);
            this.notices = [];
            this.progress = 100;
            this.zipTotalSize = 0;

            if (!isStayOrder) {
              this.adjudicationService.addDownloadPackageEvent(this.judicialReview).subscribe((result: any) => {
                this.judicialReview = result;
                this.judicialReviewUpdated.emit(this.judicialReview);
              });
            }
          }
        },
        error: (error) => { }
        
      });

      if (this.contraventionNotice != null) this.contraventionNotice.nativeElement.style.display = "none";
      if (this.seizureNotice != null) this.seizureNotice.nativeElement.style.display = "none";
      if (this.reviewRequestNotice != null) this.reviewRequestNotice.nativeElement.style.display = "none";
      if (this.lateReviewRequestNotice != null) this.lateReviewRequestNotice.nativeElement.style.display = "none";
    }
  }

  getNoticesInformation() {
    this.ClearSectionDetails();

    // Check if additional condition message and "Immediate" word is required for Licence Suspension and Seizure Duration
    var showAdditionalConditionMessage = new Date(this.judicialReview.review.reviewDate) > new Date(this.Adjudication.ADDITIONAL_CONDITION_MESSAGE_EFFECTIVE_DATE);

    if (this.contravention != null && this.contravention.stopInformation != null) {
      this.isPhase2Submission = this.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE2;

      this.driversLicenceStatusTypeName = this.localStorageService.getDriversLicenceStatusTypes().find(l => l.id == this.contravention.recipient.recipientIdentification.driversLicenceStatusTypeId)?.name;
      this.relativeLocation = `${this.contravention.stopInformation?.occurrenceLocation?.directionTypeId ? this.localStorageService.getDirectionTypes().find(x => x.id == this.contravention.stopInformation?.occurrenceLocation?.directionTypeId)?.name + " of " : ""} ${this.contravention.stopInformation?.occurrenceLocation?.relativeLocation ?? ""}`;
      this.weatherConditions = Array.prototype.map.call(this.localStorageService.getWeatherConditionTypes().filter(x => this.contravention.stopInformation?.weatherConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.weatherConditionTypes?.split(',').includes(WeatherConditionTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.weatherConditionOther : "");
      this.roadSurface = Array.prototype.map.call(this.localStorageService.getRoadSurfaceTypes().filter(x => this.contravention.stopInformation?.roadSurfaceTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.roadSurfaceTypes?.split(',').includes(RoadSurfaceTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.roadSurfaceOther : "");
      this.RoadConditions = Array.prototype.map.call(this.localStorageService.getRoadConditionTypes().filter(x => this.contravention.stopInformation?.roadConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.roadConditionTypes?.split(',').includes(RoadConditionTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.roadConditionOther : "");
      this.visibility = Array.prototype.map.call(this.localStorageService.getVisibilityConditionTypes().filter(x => this.contravention.stopInformation?.visibilityConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.visibilityConditionTypes?.split(',').includes(VisibilityConditionTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.visibilityConditionOther : "");

      this.observedBy = this.localStorageService.getObservedByTypes().find(x => x.id == this.contravention.stopInformation?.observedByTypeId)?.name;
      this.circumstancesForStop = this.contravention.stopInformation?.circumstanceTypeId == 4 ? this.contravention.stopInformation?.circumstanceOther : this.localStorageService.getCircumstanceTypes().find(x => x.id == this.contravention.stopInformation?.circumstanceTypeId)?.name;

      this.contravention.stopInformation?.witnesses?.forEach(witness => {
        this.witnessFirstName = 'Witness ' + witness.witnessSequence + ' First Name', witness.firstName;
        this.witnessLastName = 'Witness ' + witness.witnessSequence + ' Last Name', witness.lastName;
        this.witnessDateOfBirth = 'Witness ' + witness.witnessSequence + ' DOB', witness.dateOfBirth == null ? "-" : this.datePipe.transform(witness.dateOfBirth, 'dd MMM, yyyy');
      });

      this.recipient = this.contravention.recipient;
      this.recipientIdentification = this.contravention.recipient.recipientIdentification;

      // Recipient Section
      this.recipientInformation.push(new SectionDetailsModel('Surname', this.recipient.lastName));
      this.recipientInformation.push(new SectionDetailsModel('First Name', this.recipient.firstName));
      this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.recipient.otherName));

      if (this.recipientIdentification.identificationTypeId != IdentificationTypes.DriversLicence) {
        this.recipientIdentificationInformation.push(new SectionDetailsModel("Identity verified by", this.localStorageService.getIdentificationTypes().find(x => x.id == this.recipientIdentification.identificationTypeId)?.name));
        if (this.recipientIdentification.identificationTypeId == IdentificationTypes.Other) {
          this.recipientIdentificationInformation.push(new SectionDetailsModel("Identification Type", this.recipientIdentification?.identificationOther));
        }
      }

      if (this.recipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Driver Licence Number', this.recipient.recipientIdentification?.identificationNumber));
        if (this.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
          this.recipientIdentificationInformation.push(new SectionDetailsModel('MVID', this.recipient.recipientIdentification?.motorVehicleIdentificationNumber));
        }
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing Country', this.recipient.recipientIdentification?.issuingCountryId == null ? "" : this.localStorageService.getCountryTypes().find(x => x.id == this.recipient.recipientIdentification?.issuingCountryId)?.name));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing State/Province', (this.recipient.recipientIdentification?.issuingCountryId == 40 || this.recipient.recipientIdentification?.issuingCountryId == 236) ? (this.recipient.recipientIdentification?.issuingProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient.recipientIdentification?.issuingProvinceId)?.name) : this.recipient.recipientIdentification?.issuingProvinceOther));
      }

      if (this.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Class', this.recipient.recipientIdentification?.driversLicenceClassTypeId == null ? this.recipient.recipientIdentification?.driversLicenceClass : this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.recipient.recipientIdentification?.driversLicenceClassTypeId)?.name));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Status', this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.recipient.recipientIdentification?.driversLicenceStatusTypeId)?.name));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Expiry Date', this.datePipe.transform(this.recipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification.dateOfBirth, 'dd MMM, yyyy')));
        if (this.recipient.recipientIdentification?.isGDLDriver != null) {
          this.recipientIdentificationInformation.push(new SectionDetailsModel('Is GDL/Novice Driver', this.recipient.recipientIdentification?.isGDLDriver ? "Yes" : "No"));
        }
      }

      if (this.recipientIdentification.identificationTypeId != IdentificationTypes.DriversLicence && this.recipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Expiry Date', this.datePipe.transform(this.recipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
      }

      this.recipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.recipient.recipientIdentification?.genderId)?.name));

      // May 17, 2022

      if (new Date(this.contravention.submissionDate) > new Date(Constants.Intake.ADDITIONAL_RECIPIENT_AND_VEHICLE_FIELDS_RELEASE_DATE)) {
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Condition Codes', Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.recipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join("")));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'dd MMM, yyyy')));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Eye Color', this.recipient.eyeColor));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Hair Color', this.recipient.hairColor));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Weight (kg)', this.recipient.weight));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Height (cm)', this.recipient.height));
        this.recipientIdentificationInformation.push(new SectionDetailsModel('Restrictions', this.recipient.recipientIdentification?.restrictions, true));
      }

      this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient.recipientContact?.addressLine1));
      this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient.recipientContact?.addressLine2));
      this.recipientContactInformation.push(new SectionDetailsModel('City', this.recipient.recipientContact?.city));
      this.recipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient.recipientContact?.countryId == 40 || this.recipient.recipientContact?.countryId == 236) ? (this.recipient.recipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.recipient.recipientContact?.provinceId)?.name) : this.recipient.recipientContact?.province));
      this.recipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.recipient.recipientContact?.countryId)?.name));
      this.recipientContactInformation.push(new SectionDetailsModel('Postal Code', this.recipient.recipientContact?.postalCode));
      this.recipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient.recipientContact?.phoneNumber1));
      this.recipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.recipient.recipientContact?.phoneNumber3));
      this.recipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient.recipientContact?.phoneNumber2));
      this.recipientContactInformation.push(new SectionDetailsModel('Email Address', this.recipient.recipientContact?.emailAddress));
      this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.recipient.recipientContact?.isAddressDifferentFromMOVES ? "Yes" : "No"));
      this.recipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.recipient.recipientContact?.isNoFixedAddress ? "Yes" : "No"));

      // Occurrence Information            
      this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Occurrence Date', this.datePipe.transform(this.contravention?.occurrenceDate, 'mediumDate')));
      this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Occurrence Time', this.contravention?.occurrenceTime?.substring(0, 5)));
      this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Issuance Date', this.datePipe.transform(this.contravention?.issueDate, 'mediumDate')));
      this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Municipality At or Near', this.localStorageService.getLocationTypes().find(x => x.id == this.contravention.stopInformation?.occurrenceLocation?.locationTypeId)?.name));

      if (this.isPhase2Submission) {

        // March 15, 2022

        this.showPassengers = new Date(this.contravention.submissionDate) > new Date(Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE);

        if (new Date(this.contravention.submissionDate) > new Date(Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE)) {
          this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Address or Highway Number', this.contravention.stopInformation?.occurrenceLocation?.addressOrHighwayNumber));
          this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('At Intersection of', this.contravention.stopInformation?.occurrenceLocation?.atStreet));
          this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Relative location', `${this.contravention.stopInformation?.occurrenceLocation?.directionTypeId ? this.localStorageService.getDirectionTypes().find(x => x.id == this.contravention.stopInformation?.occurrenceLocation?.directionTypeId)?.name + " of " : ""} ${this.contravention.stopInformation?.occurrenceLocation?.relativeLocation ?? ""}`));
        }
        this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('GPS Coordinates', `${this.contravention.stopInformation?.occurrenceLocation?.latitude ?? ""} ${this.contravention.stopInformation?.occurrenceLocation?.longitude ?? ""}`));
      }
      this.occurrenceTimeAndLocationInformation.push(new SectionDetailsModel('Additional Location Information', this.contravention.stopInformation?.occurrenceLocation?.additionalLocationDetails, true));

      //Location Conditions

      // March 15, 2022

      if (new Date(this.contravention.submissionDate) > new Date(Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE)) {
        if (this.isPhase2Submission) {
          this.locationInformation.push(new SectionDetailsModel('Traffic Conditions', this.contravention.stopInformation?.trafficCondition));
          this.locationInformation.push(new SectionDetailsModel('Weather Conditions', Array.prototype.map.call(this.localStorageService.getWeatherConditionTypes().filter(x => this.contravention.stopInformation?.weatherConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.weatherConditionTypes?.split(',').includes(WeatherConditionTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.weatherConditionOther : "")));
          this.locationInformation.push(new SectionDetailsModel('Road Surface', Array.prototype.map.call(this.localStorageService.getRoadSurfaceTypes().filter(x => this.contravention.stopInformation?.roadSurfaceTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.roadSurfaceTypes?.split(',').includes(RoadSurfaceTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.roadSurfaceOther : "")));
          this.locationInformation.push(new SectionDetailsModel('Road Conditions', Array.prototype.map.call(this.localStorageService.getRoadConditionTypes().filter(x => this.contravention.stopInformation?.roadConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.roadConditionTypes?.split(',').includes(RoadConditionTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.roadConditionOther : "")));
          this.locationInformation.push(new SectionDetailsModel('Visibility', Array.prototype.map.call(this.localStorageService.getVisibilityConditionTypes().filter(x => this.contravention.stopInformation?.visibilityConditionTypes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.visibilityConditionTypes?.split(',').includes(VisibilityConditionTypes.Other.toString()) ? "-" + this.contravention.stopInformation?.visibilityConditionOther : "")));
          this.locationInformation.push(new SectionDetailsModel('Highway Characteristics', Array.prototype.map.call(this.localStorageService.getHighwayCharacteristicsTypes().filter(x => this.contravention.stopInformation?.highwayCharacteristicsTypes?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.contravention.stopInformation?.highwayCharacteristicsTypes?.split(',').includes(HighwayCharacteristicsTypes.Other.toString())? "-" + this.contravention.stopInformation?.highwayCharacteristicsOther:"")));
        }
      }

      // Stop Information
      this.occurrenceInformation.push(new SectionDetailsModel('Observed by', this.localStorageService.getObservedByTypes().find(x => x.id == this.contravention.stopInformation?.observedByTypeId)?.name));
      //Witness Name, Witness DOB
      this.occurrenceInformation.push(new SectionDetailsModel('Time 1st Observed', this.contravention.stopInformation?.firstObservedTime?.substring(0, 5)));
      this.occurrenceInformation.push(new SectionDetailsModel('1st Observed Location', this.contravention.stopInformation?.firstObservedLocation));

      //Does the contravention relate to a vehicle collision?

      if (this.contravention.stopInformation.isNoTrafficStop != true) {
        this.occurrenceInformation.push(new SectionDetailsModel('Time of Stop', this.contravention.stopInformation?.stopTime?.substring(0, 5)));
        this.occurrenceInformation.push(new SectionDetailsModel('Circumstances for Stop', this.contravention.stopInformation?.circumstanceTypeId == 4 ? this.contravention.stopInformation?.circumstanceOther : this.localStorageService.getCircumstanceTypes().find(x => x.id == this.contravention.stopInformation?.circumstanceTypeId)?.name));
      }

      //Witness Information
      this.contravention.stopInformation?.witnesses?.forEach(witness => {
        this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' First Name', witness.firstName));
        this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' Last Name', witness.lastName));
        this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' DOB', witness.dateOfBirth == null ? "-" : this.datePipe.transform(witness.dateOfBirth, 'dd MMM, yyyy')));
      });

      //Bodycam video question is only active when contravention was issued between Aug 1 and Aug 4 (inclusive), or details were added while the feature was active (meaning hasBodycamVideo is not null)
      const issuedAfterBodycamVideoEffective = new Date(this.contravention.issueDate) > new Date(+Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(8, 10));
      const issuedBeforeBodycamVideoRemoved = new Date(this.contravention.issueDate) < new Date(+Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(8, 10));
      if ((issuedAfterBodycamVideoEffective && issuedBeforeBodycamVideoRemoved) || this.contravention.stopInformation?.hasBodycamVideo != null) {
        this.isBodycamVideoActive = true;
      }

      // Officer Information

      // March 15, 2022

      if (new Date(this.contravention.submissionDate) > new Date(Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE)) {
        if (this.contravention.stopInformation?.officerTransportationTypeId) {
          this.officerInformation.push(new SectionDetailsModel('Peace Officer Transportation', this.localStorageService.getOfficerTransportationTypes().find(x => x.id == this.contravention.stopInformation?.officerTransportationTypeId)?.name));
          if (this.contravention.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.InMarkedPatrolVehicle) {
            this.officerInformation.push(new SectionDetailsModel('Type of Vehicle', this.localStorageService.getOfficerVehicleTypes().find(x => x.id == this.contravention.stopInformation?.officerVehicleTypeId)?.name));
            this.officerInformation.push(new SectionDetailsModel('Vehicle Number', this.contravention.stopInformation?.officerVehicleNumber));
          }
          else if (this.contravention.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.Other || this.contravention.stopInformation?.officerTransportationTypeId == OfficerTransportationTypes.InUnMarkedPatrolVehicle)
            this.officerInformation.push(new SectionDetailsModel('Other Transportation', this.contravention.stopInformation?.officerTransportationOther));

          this.officerInformation.push(new SectionDetailsModel('Officer Uniform', this.localStorageService.getOfficerUniformTypes().find(x => x.id == this.contravention.stopInformation?.officerUniformTypeId)?.name));
          if (this.contravention.stopInformation?.officerUniformTypeId == OfficerUniformTypes.Other)
            this.officerInformation.push(new SectionDetailsModel('Other Uniform', this.contravention.stopInformation?.officerUniformOther));
        }
      }
      ``
      var contraventionType = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId);

      if (contraventionType?.isIRS && showAdditionalConditionMessage)
      {
        this.contraventionType.push(new SectionDetailsModel('There may be additional conditions for reinstatement of the recipient\'s operator\'s licence.', '', true));          
      }

      this.contraventionType.push(new SectionDetailsModel('Contravention Type', contraventionType?.formattedChargeCode));
      this.contraventionType.push(new SectionDetailsModel('Contravention Penalty Amount', `$${this.contravention?.fine?.fineAmount.toString()}`));
      if (this.contravention.contraventionTypeId == 1 || this.contravention.contraventionTypeId == 9 || this.contravention.contraventionTypeId == 10 || this.contravention.contraventionTypeId == 11)
        this.contraventionType.push(new SectionDetailsModel('Contravention Details', Array.prototype.map.call(this.localStorageService.getContraventionDetailTypes().filter(x => this.contravention.contraventionDetails.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ")));
      else
        this.contraventionType.push(new SectionDetailsModel('Contravention Details', this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.description, true));

      if (contraventionType?.isIRS) {
        this.contraventionType.push(new SectionDetailsModel(showAdditionalConditionMessage?'Immediate Licence Suspension':'Licence Suspension', `${this.contravention.suspensionDuration} ${this.contravention.suspensionDurationType}` + (this.contravention.additionalSuspension ? " + " + this.contravention.additionalSuspension : "")));
        this.contraventionType.push(new SectionDetailsModel('Licence Seized', this.contravention.isLicenceSeized ? 'Yes' : 'No'));
        this.contraventionType.push(new SectionDetailsModel('Licence Destroyed', this.contravention.isLicenceDestroyed ? 'Yes' : 'No'));

        if (this.contravention.returnLicenceByMail) {
          this.contraventionType.push(new SectionDetailsModel('Licence Pick-Up', Constants.Resource.RETURN_LICENCE_BY_MAIL_TEXT, true));
        }
        else if (this.contravention.licencePickupAddress) {
          const licencePickupLocation = this.contravention.licencePickupDetachment + ': ' + this.contravention.licencePickupAddress;
          this.contraventionType.push(new SectionDetailsModel('Licence Pick-Up', licencePickupLocation, true));
        }

        if (this.contravention.remedialEducation)
          this.contraventionType.push(new SectionDetailsModel('Remedial Education', this.contravention.remedialEducation));
      }

      //Contravention Grounds
      this.contraventionGrounds.push(new SectionDetailsModel('Suspected Impairment Type', this.localStorageService.getSuspectedImpairmentTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.suspectedImpairmentTypeId)?.name));
      this.contraventionGrounds.push(new SectionDetailsModel('Was a Screening Method Used?', this.localStorageService.getContraventionGroundsTypes().find(x => x.id == this.contravention.contraventionGroundsTypeId)?.name, true));

      if (this.contravention.contraventionGroundsTypeId == 1) //Impairment Screening Information
      {
        this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion or Mas?', this.localStorageService.getImpairmentScreeningTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId)?.name));

        if (this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 1) //Reasonable Suspicion
        {
          this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion Based On', Array.prototype.map.call(this.localStorageService.getSuspicionTypes().filter(x => this.contravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (this.contravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes("5") ? "-" + this.contravention.impairmentScreeningInformation?.suspicionOther : "")));
        }

        this.impairmentScreeningInformation.push(new SectionDetailsModel('Impairment Screening Method', this.localStorageService.getTestTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.impairmentTests[0]?.testTypeId)?.name));

        this.DisplayTestResults(this.contravention.impairmentScreeningInformation?.impairmentTests[0], this.impairmentScreeningDetails, false);
      }
      else if (this.contravention.contraventionGroundsTypeId == 2) // Reasonable Grounds
      {
        this.reasonableGrounds.push(new SectionDetailsModel('Describe in Detail What Led to Your Grounds', this.contravention.reasonableGroundsDetails, true));
        this.reasonableGrounds.push(new SectionDetailsModel('Evidence of Consumption', this.contravention.consumptionEvidence, true));
      }

      if (this.contravention.isReasonableGroundsToBelieve) {
        this.reasonableGrounds.push(new SectionDetailsModel('By checking this box, the undersigned officer has reasonable grounds to believe that the recipient named in this Notice of Administrative Penalty did contravene the section of the Traffic Safety Act specified herein', "Yes", true));
      }

      this.contravention.impairmentScreeningInformation?.impairmentTests.forEach((test, index) => {
        if (test.testSequence != 0) //Avoid first test as that is impairment screening test
        {
          var sectionDtails: SectionDetailsModel[] = [];
          this.DisplayTestResults(test, sectionDtails, true);
          this.additionalScreeningInformation.push(sectionDtails);
        }
      });

      this.vehicle = this.contravention.stopInformation.contraventions[0].vehicle;
      this.isVehicleSeizureSectionVisible = this.contravention.stopInformation.contraventions.filter(x => x.vehicleSeizure).length > 0;
      this.isVehicleSectionVisible = this.contravention.recipient.recipientTypeId != RecipientTypes.NotInVehicle && !this.isVehicleSeizureSectionVisible;

      this.policeNarrativeDetails.push(new SectionDetailsModel('', this.contravention.additionalNotes, true));

      //Supporting Documents
      if (this.contravention.stopInformation.documents.length > 0)
        this.supportingDocuments = this.contravention.stopInformation.documents.filter(d => d.isDeleted == null || d.isDeleted != true);
      else if (this.contravention.stopInformation.contraventions.length > 0)
        this.supportingDocuments = this.contravention.stopInformation.contraventions[0].documents.filter(d => d.isDeleted == null || d.isDeleted != true);

      //Vehicle Seizure Information
      if (this.isVehicleSeizureSectionVisible) {
        this.seizureInformation.push(new SectionDetailsModel('Vehicle Seizure Made?', this.contravention.isNoVehicleSeizureMade ? "No" : "Yes"));
        if (this.contravention.isNoVehicleSeizureMade)
          this.seizureInformation.push(new SectionDetailsModel('No Vehicle Seizure Details', this.contravention.noVehicleSeizureDetails, true));
        else {
          this.seizureInformation.push(new SectionDetailsModel('IRS Seizure Number', this.contravention.vehicleSeizure.seizureNumber));
          this.seizureInformation.push(new SectionDetailsModel(showAdditionalConditionMessage?'Immediate Seizure Duration':'Seizure Duration', this.contravention.vehicleSeizure.seizureDuration.toString() + ' ' + this.contravention.vehicleSeizure.seizureDurationType));
          this.vehicleSeizure = this.contravention.vehicleSeizure;
        }

        if (this.vehicleSeizure) {
          // Vehicle Seizure Section
          this.seizureInformation.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.vehicleSeizure?.seizureDate, 'dd MMM, yyyy')));
          this.seizureInformation.push(new SectionDetailsModel('Seizure Time', this.vehicleSeizure?.seizureTime?.substring(0, 5)));
          this.seizureInformation.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.contravention.vehicleSeizure?.vehicleReleaseDate, 'dd MMM, yyyy')));
          this.seizureInformation.push(new SectionDetailsModel('Location At or Near', this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure?.locationTypeId)?.name));

          this.vehicle = this.vehicle ?? this.vehicleSeizure.vehicle;

          this.towLotInformation.push(new SectionDetailsModel('Towing Company', this.vehicleSeizure?.towCompanyName));
          this.towLotInformation.push(new SectionDetailsModel('Name of Towing Company', this.vehicleSeizure?.towCompanyName));
          this.towLotInformation.push(new SectionDetailsModel('Tow Lot Address', this.vehicleSeizure?.towLotAddress));
          this.towLotInformation.push(new SectionDetailsModel('City/Town/Village', this.vehicleSeizure?.towLotCity));
          this.towLotInformation.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.towLotPostalCode));
          this.towLotInformation.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.towLotPhoneNumber));
          this.towLotInformation.push(new SectionDetailsModel('Fax Number', this.vehicleSeizure?.towLotFaxNumber));
          this.towLotInformation.push(new SectionDetailsModel('Received By (Towing Company Representative)', this.vehicleSeizure?.towLotRepresentative));

          this.policeServiceInfo = this.policeServiceInfo ?? this.vehicleSeizure.policeServiceInformation[0];
          this.policeFileNumber = this.policeFileNumber ?? this.vehicleSeizure.policeFileNumber;
        }
      }

      //Vehicle Information
      if (this.isVehicleSectionVisible || this.isVehicleSeizureSectionVisible) {

        this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicle?.licencePlateNumber));
        this.vehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.vehicle?.isStolenPlate ? "Yes" : "No"));
        this.vehicleInformation.push(new SectionDetailsModel("No Plate", this.vehicle?.isNoPlate ? "Yes" : "No"));
        this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicle?.vehicleIdentificationNumber));
        this.vehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.vehicle?.vehicleRegistrationStatusTypeId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.vehicle?.clientTypeId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicle?.licensedCountryId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicle?.licensedCountryId == 40 || this.vehicle?.licensedCountryId == 236) ? (this.vehicle?.licensedProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicle?.licensedProvinceId)?.name) : this.vehicle?.licensedProvince));
        this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicle?.make));
        this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicle?.model));
        this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicle?.year?.toString()));
        this.vehicleInformation.push(new SectionDetailsModel('Colour', this.vehicle?.colour));

        // May 17, 2022

        if (new Date(this.contravention.submissionDate) > new Date(Constants.Intake.ADDITIONAL_RECIPIENT_AND_VEHICLE_FIELDS_RELEASE_DATE)) {
          this.vehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.vehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
          if (!(this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate)) {
            this.vehicleInformation.push(new SectionDetailsModel('Class of Plate', this.vehicle?.licencePlateClass));
            this.vehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.vehicle?.vehicleStyle));
            this.vehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.vehicle?.vehicleWeight));
            this.vehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.vehicle?.vehicleSeatingCapacity));
            this.vehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.vehicle?.vehicleStatusTypeId)?.name));
            this.vehicleInformation.push(new SectionDetailsModel('Last Service Type', this.vehicle?.lastServiceType));
            this.vehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.vehicle?.lastServiceDate, 'dd MMM, yyyy')));
          }
        }

        // Trailer Information

        // March 15, 2022

        if (new Date(this.contravention.submissionDate) > new Date(Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE)) {

          this.trailerInformation.push(new SectionDetailsModel("Trailer Involved?", this.vehicle?.isTrailerInvolved ? "Yes" : "No"));
          if (this.vehicle?.isTrailerInvolved) {
            this.trailerInformation.push(new SectionDetailsModel('Trailer Licence Plate Number', this.vehicle?.trailerLicencePlateNumber));
            this.trailerInformation.push(new SectionDetailsModel('Additional Trailer Information', this.vehicle?.additionalTrailerInformation));
            if (this.vehicle?.isSecondTrailerInvolved) {
              this.trailerInformation.push(new SectionDetailsModel('Second Trailer Licence Plate Number', this.vehicle?.secondTrailerLicencePlateNumber));
              this.trailerInformation.push(new SectionDetailsModel('Additional Second Trailer Information', this.vehicle?.additionalSecondTrailerInformation));
            }
          }
        }

        if (this.vehicle?.isNoRegisteredOwner)
          this.registeredOwnerInformation.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
        else {
          this.registeredOwnerInformation.push(new SectionDetailsModel('Same as Driver?', this.vehicle?.registeredOwner?.isSameAsDriver ? "Yes" : "No"));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Driver Licence Number', this.vehicle?.registeredOwner?.driverLicenceNumber));
          this.registeredOwnerInformation.push(new SectionDetailsModel('MVID', this.vehicle?.registeredOwner?.motorVehicleIdentificationNumber));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Surname', this.vehicle?.registeredOwner?.lastName));
          this.registeredOwnerInformation.push(new SectionDetailsModel('First Name', this.vehicle?.registeredOwner?.firstName));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Middle Name', this.vehicle?.registeredOwner?.otherName));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.vehicle?.registeredOwner?.genderId)?.name));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.vehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Telephone Number', this.vehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Address 1', this.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Address 2', this.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
          this.registeredOwnerInformation.push(new SectionDetailsModel('City', this.vehicle?.registeredOwner?.registeredOwnerContact?.city));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Province/State', (this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236) ? (this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name) : this.vehicle?.registeredOwner?.registeredOwnerContact?.province));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicle?.registeredOwner?.registeredOwnerContact?.countryId)?.name));
          this.registeredOwnerInformation.push(new SectionDetailsModel('Postal Code', this.vehicle?.registeredOwner?.registeredOwnerContact?.postalCode));
        }

        this.vehicleDriverInformation.push(new SectionDetailsModel('Driver Licence Number', this.vehicle?.vehicleDriver?.driversLicenceNumber));
        this.vehicleDriverInformation.push(new SectionDetailsModel('MVID', this.vehicle?.vehicleDriver?.motorVehicleIdentificationNumber));
        this.vehicleDriverInformation.push(new SectionDetailsModel('Surname', this.vehicle?.vehicleDriver?.lastName));
        this.vehicleDriverInformation.push(new SectionDetailsModel('First Name', this.vehicle?.vehicleDriver?.firstName));
        this.vehicleDriverInformation.push(new SectionDetailsModel('Middle Name', this.vehicle?.vehicleDriver?.otherName));
      }

      // Police Service Section

      this.policeServiceInfo = this.policeServiceInfo ?? this.contravention.policeServiceInformation[0];

      var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.policeServiceInfo?.detachmentId);

      this.policeServiceInformation.push(new SectionDetailsModel('Name of Police Officer', this.policeServiceInfo?.policeOfficerName));
      this.policeServiceInformation.push(new SectionDetailsModel('Police File Number', this.policeFileNumber));
      this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', this.policeServiceInfo?.regimentalNumber));
      this.policeServiceInformation.push(new SectionDetailsModel('Police Service', detachment?.agency?.agencyName));
      this.policeServiceInformation.push(new SectionDetailsModel('Detachment', detachment?.detachmentName));

      if (this.contravention?.roadsideAppeal) {
        this.roadsideAppealInformation.push(new SectionDetailsModel('Was Recipient Presented with Roadside Appeal Information?', this.contravention.roadsideAppeal?.isRecipientPresentedWithInformation ? "Yes" : "No"));
        if (!this.contravention.roadsideAppeal?.isRecipientPresentedWithInformation)
          this.roadsideAppealInformation.push(new SectionDetailsModel('If No, Explain', this.contravention.roadsideAppeal?.appealOptionsNotPresentedReason, true));

        this.roadsideAppealInformation.push(new SectionDetailsModel('Did Driver Request a Roadside Appeal?', this.contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal ? "Yes" : "No"));

        if (this.contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal && this.contravention.roadsideAppeal?.roadsideTests?.length > 0) {
          this.roadsideAppealInformation.push(new SectionDetailsModel('Roadside Appeal Method', this.localStorageService.getTestTypes().find(x => x.id == this.contravention.roadsideAppeal?.roadsideTests[0].testTypeId)?.name));

          if (this.contravention.roadsideAppeal?.roadsideTests[0].testTypeId == 8) //No Appeal Provided
            this.roadsideAppealInformation.push(new SectionDetailsModel('If Appeal Was Requested But Not Provided, Explain Why Not', this.contravention.roadsideAppeal?.roadsideTests[0].appealNotProvidedReason, true));

          this.DisplayTestResults(this.contravention.roadsideAppeal?.roadsideTests[0], this.roadsideAppealDetails, false, true);
        }
      }

      var declarationTypes = this.localStorageService.getDeclarationTypes();

      var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.contravention.officerSubmissionDeclarationTypeId).description;

      //Declarations
      this.declarationInformation.push(new SectionDetailsModel('Full Name of Officer', this.contravention.policeOfficerFullName));
      this.declarationInformation.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.contravention.submissionDate, 'dd MMM, yyyy')));
      this.declarationInformation.push(new SectionDetailsModel(officerSubmissionDeclaration, this.contravention.isTrueInformation ? "Yes" : "No", true));

      if (this.contravention.officerAdditionalDetailsDeclarationTypeId) {
        const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.contravention.officerAdditionalDetailsDeclarationTypeId).description;
        this.declarationInformation.push(new SectionDetailsModel('Full Name of Officer', this.contravention.policeOfficerFullName));
        this.declarationInformation.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.contravention.additionalDetailsDate, 'dd MMM, yyyy')));
        this.declarationInformation.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
      }

      // Supporting Document Declarations

      this.contravention.policeServiceInformation.forEach(p => {
        if (p.declarationInformation != null) {
          var declaration = declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
          this.supportingDocumentDeclarationInformation.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
          this.supportingDocumentDeclarationInformation.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
          this.supportingDocumentDeclarationInformation.push(new SectionDetailsModel(declaration.description, 'Yes', true));
          this.showSupportingDocumentDeclarations = true;
        }
      });

      this.reServiceInformation.push(new SectionDetailsModel('Re-Service', this.contravention.isReServeRequired ? "Yes" : "No"));

      if (this.contravention.isReServeRequired) {
        this.reServiceInformation.push(new SectionDetailsModel('Re-Service Date', this.datePipe.transform(this.contravention.reServeDate, 'dd MMM, yyyy')));
        this.reServiceInformation.push(new SectionDetailsModel('Re-Service Method', this.localStorageService.getReServeMethodTypes().find(x => x.id == this.contravention.reServeMethodTypeId)?.name));
      }

    }

    if (this.contravention?.stopInformation.vehicleSeizures[0] != null) {
      this.SDPVehicleSeizure = this.contravention.stopInformation.vehicleSeizures[0];
    }

    else {
      if (this.vehicleSeizure?.seizureTypeId != SeizureTypes.IRSContraventionSelected) {
        this.SDPVehicleSeizure = this.vehicleSeizure;
      }
    }

    if (this.SDPVehicleSeizure) {

      this.SDPRecipient = this.SDPVehicleSeizure.recipient;
      this.SDPRecipientIdentification = this.SDPVehicleSeizure.recipient.recipientIdentification;

      // Recipient Section
      this.SDPRecipientInformation.push(new SectionDetailsModel('Surname', this.SDPRecipient.lastName));
      this.SDPRecipientInformation.push(new SectionDetailsModel('First Name', this.SDPRecipient.firstName));
      this.SDPRecipientInformation.push(new SectionDetailsModel('Middle Name', this.SDPRecipient.otherName));

      if (this.SDPRecipientIdentification.identificationTypeId != IdentificationTypes.DriversLicence) {
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel("Identity verified by", this.localStorageService.getIdentificationTypes().find(x => x.id == this.recipientIdentification.identificationTypeId)?.name));
        if (this.SDPRecipientIdentification.identificationTypeId == IdentificationTypes.Other) {
          this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel("Identification Type", this.recipientIdentification?.identificationOther));
        }
      }

      if (this.SDPRecipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Driver Licence Number', this.SDPRecipient.recipientIdentification?.identificationNumber));
        if (this.SDPRecipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
          this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('MVID', this.SDPRecipient.recipientIdentification?.motorVehicleIdentificationNumber));
        }
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Issuing Country', this.SDPRecipient.recipientIdentification?.issuingCountryId == null ? "" : this.localStorageService.getCountryTypes().find(x => x.id == this.SDPRecipient.recipientIdentification?.issuingCountryId)?.name));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Issuing State/Province', (this.SDPRecipient.recipientIdentification?.issuingCountryId == 40 || this.SDPRecipient.recipientIdentification?.issuingCountryId == 236) ? (this.SDPRecipient.recipientIdentification?.issuingProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.SDPRecipient.recipientIdentification?.issuingProvinceId)?.name) : this.SDPRecipient.recipientIdentification?.issuingProvinceOther));
      }

      if (this.SDPRecipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence) {
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Licence Class', this.SDPRecipient.recipientIdentification?.driversLicenceClassTypeId == null ? this.SDPRecipient.recipientIdentification?.driversLicenceClass : this.localStorageService.getDriversLicenceClassTypes().find(x => x.id == this.SDPRecipient.recipientIdentification?.driversLicenceClassTypeId)?.name));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Licence Status', this.localStorageService.getDriversLicenceStatusTypes().find(x => x.id == this.SDPRecipient.recipientIdentification?.driversLicenceStatusTypeId)?.name));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Licence Expiry Date', this.datePipe.transform(this.SDPRecipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.SDPRecipient.recipientIdentification.dateOfBirth, 'dd MMM, yyyy')));
        if (this.SDPRecipient.recipientIdentification?.isGDLDriver != null) {
          this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Is GDL/Novice Driver', this.SDPRecipient.recipientIdentification?.isGDLDriver ? "Yes" : "No"));
        }
      }

      if (this.SDPRecipientIdentification.identificationTypeId != IdentificationTypes.DriversLicence && this.recipientIdentification.identificationTypeId != IdentificationTypes.Verbal) {
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Expiry Date', this.datePipe.transform(this.SDPRecipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
      }

      this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.SDPRecipient.recipientIdentification?.genderId)?.name));

      // May 17, 2022

      if (new Date(this.SDPVehicleSeizure.submissionDate) > new Date(Constants.Intake.ADDITIONAL_RECIPIENT_AND_VEHICLE_FIELDS_RELEASE_DATE)) {
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Condition Codes', Array.prototype.map.call(this.localStorageService.getConditionCodeTypes().filter(x => this.SDPRecipient?.recipientIdentification?.conditionCodes?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join("")));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.SDPRecipient.recipientIdentification?.dateOfBirth, 'dd MMM, yyyy')));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Eye Color', this.SDPRecipient.eyeColor));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Hair Color', this.SDPRecipient.hairColor));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Weight (kg)', this.SDPRecipient.weight));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Height (cm)', this.SDPRecipient.height));
        this.SDPRecipientIdentificationInformation.push(new SectionDetailsModel('Restrictions', this.SDPRecipient.recipientIdentification?.restrictions, true));
      }

      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Address 1', this.SDPRecipient.recipientContact?.addressLine1));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Address 2', this.SDPRecipient.recipientContact?.addressLine2));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('City', this.SDPRecipient.recipientContact?.city));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Province/State', (this.SDPRecipient.recipientContact?.countryId == 40 || this.SDPRecipient.recipientContact?.countryId == 236) ? (this.SDPRecipient.recipientContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.SDPRecipient.recipientContact?.provinceId)?.name) : this.SDPRecipient.recipientContact?.province));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.SDPRecipient.recipientContact?.countryId)?.name));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Postal Code', this.SDPRecipient.recipientContact?.postalCode));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.SDPRecipient.recipientContact?.phoneNumber1));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Cell Phone', this.SDPRecipient.recipientContact?.phoneNumber3));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Business Phone', this.SDPRecipient.recipientContact?.phoneNumber2));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Email Address', this.SDPRecipient.recipientContact?.emailAddress));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.SDPRecipient.recipientContact?.isAddressDifferentFromMOVES ? "Yes" : "No"));
      this.SDPRecipientContactInformation.push(new SectionDetailsModel('No Fixed Address', this.SDPRecipient.recipientContact?.isNoFixedAddress ? "Yes" : "No"));

      // Vehicle Seizure Section
      this.SDPSeizureInformation.push(new SectionDetailsModel('Seizure Number', this.SDPVehicleSeizure?.seizureNumber));
      this.SDPSeizureInformation.push(new SectionDetailsModel('Seizure Type', this.localStorageService.getTypeItemDescriptionById(this.SDPVehicleSeizure?.seizureTypeId, TypeTable.SeizureType)));
      this.SDPSeizureInformation.push(new SectionDetailsModel(showAdditionalConditionMessage?'Immediate Seizure Duration':'Seizure Duration', this.SDPVehicleSeizure?.seizureDuration.toString() + ' ' + this.SDPVehicleSeizure?.seizureDurationType));
      this.SDPSeizureInformation.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.SDPVehicleSeizure?.seizureDate, 'dd MMM, yyyy')));
      this.SDPSeizureInformation.push(new SectionDetailsModel('Seizure Time', this.SDPVehicleSeizure?.seizureTime?.substring(0, 5)));
      this.SDPSeizureInformation.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.SDPVehicleSeizure?.vehicleReleaseDate, 'dd MMM, yyyy')));
      this.SDPSeizureInformation.push(new SectionDetailsModel('Location At or Near', this.localStorageService.getLocationTypes().find(x => x.id == this.SDPVehicleSeizure?.locationTypeId)?.name));

      var additionalLocationDetails = this.vehicleSeizure.additionalLocationDetails?.trim();
      if (additionalLocationDetails == null || additionalLocationDetails == '') {
        additionalLocationDetails = this.vehicleSeizure.occurrenceLocation?.additionalLocationDetails?.trim();
      }

      this.SDPSeizureInformation.push(new SectionDetailsModel('Additional Location Information', additionalLocationDetails, true));

      this.SDPVehicle = this.SDPVehicleSeizure.vehicle;
      this.isSDPVehicleSectionVisible = this.SDPVehicleSeizure.recipient.recipientTypeId != RecipientTypes.NotInVehicle;

      this.SDPTowLotInformation.push(new SectionDetailsModel('Towing Company', this.SDPVehicleSeizure?.towCompanyName));
      this.SDPTowLotInformation.push(new SectionDetailsModel('Name of Towing Company', this.SDPVehicleSeizure?.towCompanyName));
      this.SDPTowLotInformation.push(new SectionDetailsModel('Tow Lot Address', this.SDPVehicleSeizure?.towLotAddress));
      this.SDPTowLotInformation.push(new SectionDetailsModel('City/Town/Village', this.SDPVehicleSeizure?.towLotCity));
      this.SDPTowLotInformation.push(new SectionDetailsModel('Postal Code', this.SDPVehicleSeizure?.towLotPostalCode));
      this.SDPTowLotInformation.push(new SectionDetailsModel('Telephone Number', this.SDPVehicleSeizure?.towLotPhoneNumber));
      this.SDPTowLotInformation.push(new SectionDetailsModel('Fax Number', this.SDPVehicleSeizure?.towLotFaxNumber));
      this.SDPTowLotInformation.push(new SectionDetailsModel('Received By (Towing Company Representative)', this.SDPVehicleSeizure?.towLotRepresentative));

      this.policeServiceInfo = this.policeServiceInfo ?? this.SDPVehicleSeizure.policeServiceInformation[0];
      this.policeFileNumber = this.policeFileNumber ?? this.SDPVehicleSeizure.policeFileNumber;

      this.SDPPoliceNarrativeDetails.push(new SectionDetailsModel('', this.SDPVehicleSeizure.additionalNotes, true));

      if (this.isSDPVehicleSectionVisible) {
        // Vehicle Information
        this.SDPVehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.SDPVehicle?.licencePlateNumber));
        this.SDPVehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.SDPVehicle?.isStolenPlate ? "Yes" : "No"));
        this.SDPVehicleInformation.push(new SectionDetailsModel("No Plate", this.SDPVehicle?.isNoPlate ? "Yes" : "No"));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.SDPVehicle?.vehicleIdentificationNumber));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.SDPVehicle?.vehicleRegistrationStatusTypeId)?.name));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.SDPVehicle?.clientTypeId)?.name));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.SDPVehicle?.licensedCountryId)?.name));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.SDPVehicle?.licensedCountryId == 40 || this.SDPVehicle?.licensedCountryId == 236) ? (this.SDPVehicle?.licensedProvinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.SDPVehicle?.licensedProvinceId)?.name) : this.SDPVehicle?.licensedProvince));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Make', this.SDPVehicle?.make));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Model', this.SDPVehicle?.model));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Year', this.SDPVehicle?.year?.toString()));
        this.SDPVehicleInformation.push(new SectionDetailsModel('Colour', this.SDPVehicle?.colour));

        // May 17, 2022

        if (new Date(this.SDPVehicleSeizure.submissionDate) > new Date(Constants.Intake.ADDITIONAL_RECIPIENT_AND_VEHICLE_FIELDS_RELEASE_DATE)) {
          this.SDPVehicleInformation.push(new SectionDetailsModel('Plate Expiry Date', this.datePipe.transform(this.SDPVehicle?.licencePlateExpiryDate, 'dd MMM, yyyy')));
          if (!(this.SDPVehicle?.movesLookupDate && this.SDPVehicle?.isStolenPlate)) {
            this.SDPVehicleInformation.push(new SectionDetailsModel('Class of Plate', this.SDPVehicle?.licencePlateClass));
            this.SDPVehicleInformation.push(new SectionDetailsModel('Vehicle Style', this.SDPVehicle?.vehicleStyle));
            this.SDPVehicleInformation.push(new SectionDetailsModel('Gross Vehicle Weight', this.SDPVehicle?.vehicleWeight));
            this.SDPVehicleInformation.push(new SectionDetailsModel('Seating Capacity', this.SDPVehicle?.vehicleSeatingCapacity));
            this.SDPVehicleInformation.push(new SectionDetailsModel('Vehicle Status', this.localStorageService.getVehicleStatusTypes().find(x => x.id == this.SDPVehicle?.vehicleStatusTypeId)?.name));
            this.SDPVehicleInformation.push(new SectionDetailsModel('Last Service Type', this.SDPVehicle?.lastServiceType));
            this.SDPVehicleInformation.push(new SectionDetailsModel('Last Service Date', this.datePipe.transform(this.SDPVehicle?.lastServiceDate, 'dd MMM, yyyy')));
          }
        }

        // Trailer Information

        // March 15, 2022

        if (new Date(this.SDPVehicleSeizure.submissionDate) > new Date(Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE)) {
          this.SDPTrailerInformation.push(new SectionDetailsModel("Trailer Involved?", this.SDPVehicle?.isTrailerInvolved ? "Yes" : "No"));
          if (this.SDPVehicle?.isTrailerInvolved) {
            this.SDPTrailerInformation.push(new SectionDetailsModel('Trailer Licence Plate Number', this.SDPVehicle?.trailerLicencePlateNumber));
            this.SDPTrailerInformation.push(new SectionDetailsModel('Additional Trailer Information', this.SDPVehicle?.additionalTrailerInformation));
            if (this.SDPVehicle?.isSecondTrailerInvolved) {
              this.SDPTrailerInformation.push(new SectionDetailsModel('Second Trailer Licence Plate Number', this.SDPVehicle?.secondTrailerLicencePlateNumber));
              this.SDPTrailerInformation.push(new SectionDetailsModel('Additional Second Trailer Information', this.SDPVehicle?.additionalSecondTrailerInformation));
            }
          }
        }

        if (this.SDPVehicle?.isNoRegisteredOwner)
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Registered Owner', "Unable to determine"));
        else {
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Same as Driver?', this.SDPVehicle?.registeredOwner?.isSameAsDriver ? "Yes" : "No"));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Driver Licence Number', this.SDPVehicle?.registeredOwner?.driverLicenceNumber));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('MVID', this.SDPVehicle?.registeredOwner?.motorVehicleIdentificationNumber));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Surname', this.SDPVehicle?.registeredOwner?.lastName));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('First Name', this.SDPVehicle?.registeredOwner?.firstName));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Middle Name', this.SDPVehicle?.registeredOwner?.otherName));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x => x.id == this.SDPVehicle?.registeredOwner?.genderId)?.name));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.SDPVehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Telephone Number', this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Address 1', this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Address 2', this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('City', this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.city));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Province/State', (this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236) ? (this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.provinceId == null ? "" : this.localStorageService.getProvinceTypes().find(x => x.id == this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name) : this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.province));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.countryId)?.name));
          this.SDPRegisteredOwnerInformation.push(new SectionDetailsModel('Postal Code', this.SDPVehicle?.registeredOwner?.registeredOwnerContact?.postalCode));
        }

        this.SDPVehicleDriverInformation.push(new SectionDetailsModel('Driver Licence Number', this.SDPVehicle?.vehicleDriver?.driversLicenceNumber));
        this.SDPVehicleDriverInformation.push(new SectionDetailsModel('MVID', this.SDPVehicle?.vehicleDriver?.motorVehicleIdentificationNumber));
        this.SDPVehicleDriverInformation.push(new SectionDetailsModel('Surname', this.SDPVehicle?.vehicleDriver?.lastName));
        this.SDPVehicleDriverInformation.push(new SectionDetailsModel('First Name', this.SDPVehicle?.vehicleDriver?.firstName));
        this.SDPVehicleDriverInformation.push(new SectionDetailsModel('Middle Name', this.SDPVehicle?.vehicleDriver?.otherName));

      }

      this.policeServiceInfo = this.policeServiceInfo ?? this.SDPVehicleSeizure.policeServiceInformation[0];
      this.policeFileNumber = this.policeFileNumber ?? this.SDPVehicleSeizure.policeFileNumber;
      var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.policeServiceInfo?.detachmentId);

      // Police Service Section

      this.SDPPoliceServiceInformation.push(new SectionDetailsModel('Name of Police Officer', this.policeServiceInfo?.policeOfficerName));
      this.SDPPoliceServiceInformation.push(new SectionDetailsModel('Police File Number', this.policeFileNumber));
      this.SDPPoliceServiceInformation.push(new SectionDetailsModel('Regimental Number', this.policeServiceInfo?.regimentalNumber));
      this.SDPPoliceServiceInformation.push(new SectionDetailsModel('Police Service', detachment?.agency?.agencyName));
      this.SDPPoliceServiceInformation.push(new SectionDetailsModel('Detachment', detachment?.detachmentName));

      //Supporting Documents

      if (this.contravention != null) {
        if (this.contravention.stopInformation.documents.length > 0)
          this.SDPSupportingDocuments = this.contravention.stopInformation.documents.filter(d => d.isDeleted == null || d.isDeleted != true);
        else if (this.contravention.stopInformation.contraventions.length > 0)
          this.SDPSupportingDocuments = this.contravention.stopInformation.contraventions[0].documents.filter(d => d.isDeleted == null || d.isDeleted != true);
      }

      else {
        if (this.SDPVehicleSeizure.documents.length > 0)
          this.SDPSupportingDocuments = this.SDPVehicleSeizure.documents.filter(d => d.isDeleted == null || d.isDeleted != true);
        else if (this.stopInformation.documents.length > 0)
          this.SDPSupportingDocuments = this.stopInformation.documents.filter(d => d.isDeleted == null || d.isDeleted != true);
      }

      
      var declarationTypes = this.localStorageService.getDeclarationTypes();

      var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.SDPVehicleSeizure.officerSubmissionDeclarationTypeId).description;

      //Declarations
      this.SDPDeclarationInformation.push(new SectionDetailsModel('Full Name of Officer', this.SDPVehicleSeizure.policeOfficerFullName));
      this.SDPDeclarationInformation.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.SDPVehicleSeizure.submissionDate, 'dd MMM, yyyy')));
      this.SDPDeclarationInformation.push(new SectionDetailsModel(officerSubmissionDeclaration, this.SDPVehicleSeizure.isTrueInformation ? "Yes" : "No", true));

      if (this.SDPVehicleSeizure.officerAdditionalDetailsDeclarationTypeId) {
        const officerAdditionalDetailsDeclaration = declarationTypes?.find(d => d.id == this.SDPVehicleSeizure.officerAdditionalDetailsDeclarationTypeId).description;
        this.SDPDeclarationInformation.push(new SectionDetailsModel('Full Name of Officer', this.SDPVehicleSeizure.policeOfficerFullName));
        this.SDPDeclarationInformation.push(new SectionDetailsModel('Date of Additional Details', this.datePipe.transform(this.SDPVehicleSeizure.additionalDetailsDate, 'dd MMM, yyyy')));
        this.SDPDeclarationInformation.push(new SectionDetailsModel(officerAdditionalDetailsDeclaration, "Yes", true));
      }

      // Supporting Document Declarations
      
      this.SDPVehicleSeizure.policeServiceInformation.forEach(p => {
        if (p.declarationInformation != null) {
          var declaration = declarationTypes.find(d => d.id == p.declarationInformation.declarationTypeId);
          this.SDPSupportingDocumentDeclarationInformation.push(new SectionDetailsModel('Name of Submitter', p.declarationInformation.policeOfficerName));
          this.SDPSupportingDocumentDeclarationInformation.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(p.declarationInformation.declarationDate, 'dd MMM, yyyy')));
          this.SDPSupportingDocumentDeclarationInformation.push(new SectionDetailsModel(declaration.description, 'Yes', true));
          this.showSupportingDocumentDeclarations = true;
        }
      });

      this.SDPReServiceInformation.push(new SectionDetailsModel('Re-Service', this.SDPVehicleSeizure.isReServeRequired ? "Yes" : "No"));

      if (this.SDPVehicleSeizure.isReServeRequired) {
        this.SDPReServiceInformation.push(new SectionDetailsModel('Re-Service Date', this.datePipe.transform(this.SDPVehicleSeizure.reServeDate, 'dd MMM, yyyy')));
        this.SDPReServiceInformation.push(new SectionDetailsModel('Re-Service Method', this.localStorageService.getReServeMethodTypes().find(x => x.id == this.SDPVehicleSeizure.reServeMethodTypeId)?.name));
      }
    }

    if (this.contravention != null) {
      this.showPublishedDate = new Date(this.contravention.submissionDate) >= new Date(this.Adjudication.DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE);
    }

    if (this.vehicleSeizure != null) {
      this.showPublishedDate = new Date(this.vehicleSeizure.submissionDate) >= new Date(this.Adjudication.DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE);
    }

    this.review = this.judicialReview.review;

    this.reviewRepresentationTypeName = this.localStorageService.getRepresentationTypes().find(x => x.id == this.review.representation?.representationTypeId)?.name;
    this.reviewMethodTypeName = this.localStorageService.getReviewMethodTypes().find(x => x.id == this.review.reviewMethodTypeId)?.name;
    this.reviewContactMethodTypeName = this.localStorageService.getContactMethodTypes().find(x => x.id == this.review.contactMethodTypeId)?.name;
    this.reviewRepTitle = CommonUtil.getRepresentationTitle(this.review.representation?.representationTypeId);

    if (this.contravention != null) {
      this.showOralReviewElection = new Date(this.contravention.submissionDate) > new Date(Constants.Intake.ORAL_REVIEW_ELECTION_RELEASE_DATE);
    }

    if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral) {
      this.reviewDateTime = new Date(`${new Date(this.review.reviewDate).toDateString()} ${this.review.reviewTime}`);
    }

    this.review.events.forEach(event => {
      let eventDate = new Date(event.eventDate.toString().replace(/[zZ]/, ""));
      eventDate.setMinutes(eventDate.getMinutes() - eventDate.getTimezoneOffset());
      event.eventDate = eventDate;
    });

    this.lateReviewRequest = this.judicialReview.lateReviewRequest;
    this.lateReviewRepresentationTypeName = this.localStorageService.getRepresentationTypes().find(x => x.id == this.lateReviewRequest?.representation?.representationTypeId)?.name;
    this.lateReviewContactMethodTypeName = this.localStorageService.getContactMethodTypes().find(x => x.id == this.lateReviewRequest?.contactMethodTypeId)?.name;

    this.lateReviewRepTitle = CommonUtil.getRepresentationTitle(this.lateReviewRequest?.representation?.representationTypeId);

    this.lateReviewRequest?.events.forEach(event => {
      let eventDate = new Date(event.eventDate.toString().replace(/[zZ]/, ""));
      eventDate.setMinutes(eventDate.getMinutes() - eventDate.getTimezoneOffset());
      event.eventDate = eventDate;
    });

    // Terms and conditions statements
    const termsConditionsList = this.localStorageService.getTermsAndConditionsList();
    const isThirdPartySeizureReview = +this.review.reviewTypeId === ReviewTypes.ThirdPartySeizureReview;
    if (isThirdPartySeizureReview && +this.review.termsConditionsVersion <= 8) {
      this.reviewTermsConditionsStatement = "Agree to Terms & Conditions";
    }
    else {
      const reviewTerms = termsConditionsList.find(x => x.version === this.review.termsConditionsVersion);
      this.reviewTermsConditionsStatement = this.review.isSubmittedByRegistry ? reviewTerms.adminRegistryStatement : reviewTerms.adminDriverStatement;
    }

    if (this.lateReviewRequest) {
      const lateReviewTerms = termsConditionsList.find(x => x.version === this.lateReviewRequest.termsConditionsVersion);
      this.lateReviewTermsConditionsStatement = this.lateReviewRequest.isSubmittedByRegistry ? lateReviewTerms.adminRegistryStatement : lateReviewTerms.adminDriverStatement;
    }

    this.setApplicantName();

    this.showNoticesInformation = true;
  }

  DisplayTestResults(test: ContraventionTest, section: SectionDetailsModel[], isTypeRequired: boolean, isRoadsideAppeal: boolean = false) {
    if (test == null) return;

    var newFormatDate = this.localStorageService.getDriverDeclinedToTestTypes().find(x => x.id == DriverDeclinedToTestTypes.FailedToComply)?.effectiveDate;
    var isNewFormat = new Date(this.contravention.submissionDate) >= new Date(newFormatDate);
    var isMas = this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 2 && !isRoadsideAppeal;
    var isNewFormatV2 = new Date(this.contravention.submissionDate) > new Date(+Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(0, 4), +Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(5, 7) - 1, +Constants.Intake.PAPER_LESS_SUBMISSION_RELEASE_DATE.substring(8, 10));
    var isAIThirdTestVisible = new Date(this.contravention.submissionDate) >= new Date(+Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(8, 10));
    var isNewDREProcess = new Date(this.contravention.submissionDate) >= new Date(+Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(8, 10));

    if (isTypeRequired)
      section.push(new SectionDetailsModel('Screening Method', this.localStorageService.getTestTypes().find(x => x.id == test.testTypeId)?.name));

    switch (test.testTypeId.toString()) {
      case "1": //SFST
        if (isNewFormatV2) {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "SFST Demand"}`, test.standardizedFieldSobrietyTest?.testDemandTime));
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "SFST"} Test Started`, test.standardizedFieldSobrietyTest?.testStartedTime));
        }
        else {
          section.push(new SectionDetailsModel(`Time of SFST ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, test.standardizedFieldSobrietyTest?.testDemandTime));
          section.push(new SectionDetailsModel('Time SFST Test Started', test.standardizedFieldSobrietyTest?.testStartedTime));
        }
        section.push(new SectionDetailsModel('SFST Results', this.localStorageService.getTestResultTypes().find(x => x.id == test.standardizedFieldSobrietyTest?.testResultTypeId)?.name));
        break;
      case "2": //ASD
        section.push(new SectionDetailsModel('ASD Model', test.approvedScreeningDeviceTest?.testModelTypeId == 2 ? test.approvedScreeningDeviceTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testModelTypeId)?.name));
        if (isNewFormatV2) {
          if (test.approvedScreeningDeviceTest?.testDemandTime) {
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : ((isMas && isNewFormat) ? "MAS" : "ASD") + " Demand"}`, test.approvedScreeningDeviceTest?.testDemandTime));
          }
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : ((isMas && isNewFormat) ? "MAS" : "ASD")} Test Started`, test.approvedScreeningDeviceTest?.testStartedTime));
        }
        else {
          if (!isRoadsideAppeal || test.approvedScreeningDeviceTest?.testDemandTime) {
            section.push(new SectionDetailsModel(`Time of ${(isMas && isNewFormat) ? "MAS" : "ASD"} Demand`, test.approvedScreeningDeviceTest?.testDemandTime));
          }
          section.push(new SectionDetailsModel(`Time ${(isMas && isNewFormat) ? "MAS" : "ASD"} Test Started`, test.approvedScreeningDeviceTest?.testStartedTime));
        }
        section.push(new SectionDetailsModel(`${(isMas && isNewFormat) ? "MAS" : "ASD"} Results`, this.localStorageService.getTestResultTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testResultTypeId)?.name));
        break;
      case "3": //ADSE
        section.push(new SectionDetailsModel('ADSE Model', test.drugScreeningEquipmentTest?.testModelTypeId == 5 ? test.drugScreeningEquipmentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.drugScreeningEquipmentTest?.testModelTypeId)?.name));
        if (isNewFormatV2) {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "ADSE Demand"}`, test.drugScreeningEquipmentTest?.testDemandTime));
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "ADSE"} Test Started`, test.drugScreeningEquipmentTest?.testStartedTime));
        }
        else {
          section.push(new SectionDetailsModel(`Time of ADSE ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, test.drugScreeningEquipmentTest?.testDemandTime));
          section.push(new SectionDetailsModel('Time ADSE Test Started', test.drugScreeningEquipmentTest?.testStartedTime));
        }
        section.push(new SectionDetailsModel('ADSE Results', Array.prototype.map.call(this.localStorageService.getTestResultTypes().filter(x => test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes(x.id.toString())), function (item) { return item.name; }).join(", ") + (test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes("10") ? "-" + test.drugScreeningEquipmentTest?.testResultOther : "")));
        break;
      case "4": //DRE
        if (isNewDREProcess) {
          section.push(new SectionDetailsModel(`Time of DRE ${isRoadsideAppeal ? "Request" : "Demand"}`, test.drugRecognitionEvaluationTest?.testDemandTime));
          section.push(new SectionDetailsModel(isRoadsideAppeal ? "Decline?" : "Refusal", this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));

          if (test.driverDeclinedToTestTypeId != DriverDeclinedToTestTypes.No) {
            var fieldLabel = test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal ? (isRoadsideAppeal ? "Declined?" : "Refused") : "Failed to Comply With";
            switch (+test.drugRecognitionEvaluationTest?.refusalOrFailedClassificationId) {
              case RefusalOrFailedClassification.BreathSample:
                section.push(new SectionDetailsModel(fieldLabel, "Breath Sample"));
                break;
              case RefusalOrFailedClassification.DREProcess:
                section.push(new SectionDetailsModel(fieldLabel, "DRE Process"));
                break;
              case RefusalOrFailedClassification.ToxicologicalSample:
                section.push(new SectionDetailsModel(fieldLabel, "Toxicological Sample"));
                break;
            }
          }

          section.push(new SectionDetailsModel(`Breath ${isRoadsideAppeal ? "Request" : "Demand"} (Step 1) Made?`, test.drugRecognitionEvaluationTest?.isBreathDemandMade ? "Yes" : "No"));

          if (test.drugRecognitionEvaluationTest?.isBreathDemandMade) {
            section.push(new SectionDetailsModel(`Time of Breath ${isRoadsideAppeal ? "Request" : "Demand"}`, test.drugRecognitionEvaluationTest?.breathDemandTime));
            section.push(new SectionDetailsModel('Breath Test Results', test.drugRecognitionEvaluationTest?.breathTestResult));
          }

          if (test.drugRecognitionEvaluationTest?.testStartedTime)
            section.push(new SectionDetailsModel('Time DRE Test Started', test.drugRecognitionEvaluationTest?.testStartedTime));

          if (test.drugRecognitionEvaluationTest.sampleClassificationId) {
            var sampleType = test.drugRecognitionEvaluationTest?.sampleClassificationId == SampleClassification.Blood ? "Blood" : "Urine";
            section.push(new SectionDetailsModel(`Type of Sample ${isRoadsideAppeal ? "Requested" : "Demanded"}`, sampleType));
            section.push(new SectionDetailsModel(`Sample ${isRoadsideAppeal ? "Request" : "Demand"} Time`, test.drugRecognitionEvaluationTest.sampleDemandTime));

            if (test.drugRecognitionEvaluationTest.sampleCollectionTime) {
              section.push(new SectionDetailsModel('Sample Time', test.drugRecognitionEvaluationTest.sampleCollectionTime));
              section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.drugRecognitionEvaluationTest?.labTypeId)?.name));
            }
          }

          if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Decline" : "Refusal"}`, test.refusalTime));
        }
        else {
          if (isNewFormatV2) {
            section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "DRE Demand"}`, test.drugRecognitionEvaluationTest?.testDemandTime));
            section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "DRE"} Test Started`, test.drugRecognitionEvaluationTest?.testStartedTime));
          }
          else {
            section.push(new SectionDetailsModel(`Time of DRE ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, test.drugRecognitionEvaluationTest?.testDemandTime));
            section.push(new SectionDetailsModel('Time DRE Test Started', test.drugRecognitionEvaluationTest?.testStartedTime));
          }
        }
        break;
      case "5": //AI
        section.push(new SectionDetailsModel('AI Model', test.approvedInstrumentTest?.testModelTypeId == 7 ? test.approvedInstrumentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedInstrumentTest?.testModelTypeId)?.name));

        if (isAIThirdTestVisible) {
          section.push(new SectionDetailsModel(`Time ${isRoadsideAppeal ? "Roadside Appeal" : "AI"} Test Started`, test.approvedInstrumentTest?.testStartedTime));
        }

        if (isNewFormatV2) {
          section.push(new SectionDetailsModel(`Time of ${isRoadsideAppeal ? "Roadside Appeal Request" : "1st Breath AI Demand"}`, test.approvedInstrumentTest?.firstBreathDemandTime));
          section.push(new SectionDetailsModel(`${isRoadsideAppeal ? "" : "1st "}Evidentiary Test Result`, test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        }
        else {
          section.push(new SectionDetailsModel(`Time of 1st Breath AI ${(isRoadsideAppeal && isNewFormat) ? "Request" : "Demand"}`, test.approvedInstrumentTest?.firstBreathDemandTime));
          section.push(new SectionDetailsModel('1st Evidentiary Test Result', test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        }

        if (!isRoadsideAppeal) {
          section.push(new SectionDetailsModel(`Time of 2nd Breath AI ${isAIThirdTestVisible ? "Request" : "Demand"}`, test.approvedInstrumentTest?.secondBreathDemandTime));
          section.push(new SectionDetailsModel(`2nd Evidentiary Test Result`, test.approvedInstrumentTest?.secondEvidentiaryTestResult));

          if (isAIThirdTestVisible) {
            section.push(new SectionDetailsModel(`Time of 3rd Breath AI Request`, test.approvedInstrumentTest?.thirdBreathDemandTime));
            section.push(new SectionDetailsModel(`3rd Evidentiary Test Result`, test.approvedInstrumentTest?.thirdEvidentiaryTestResult));
          }
        }

        break;
      case "6": //Blood Sample
        if (isNewDREProcess && !isRoadsideAppeal) {
          if (test.bloodSampleTest.isBloodDemand) {
            section.push(new SectionDetailsModel('Type of Sample', 'Blood Demand (320.28)'));
            section.push(new SectionDetailsModel("Refusal?", this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));
            section.push(new SectionDetailsModel('Time of Demand', test.bloodSampleTest?.bloodSampleDemandTime));
            if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No) {
              section.push(new SectionDetailsModel('Blood Sample Time', test.bloodSampleTest?.bloodSampleTime));
              section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
            }

            if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
              section.push(new SectionDetailsModel('Time of Refusal', test.refusalTime));
          }

          if (test.bloodSampleTest.isBloodWarrant) {
            section.push(new SectionDetailsModel('Type of Sample', 'Blood Warrant (320.29)', true));
            section.push(new SectionDetailsModel('Blood Sample Time', test.bloodSampleTest?.bloodWarrantSampleTime));
            section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.bloodWarrantLabTypeId)?.name));
          }

          if (test.bloodSampleTest.isSearchWarrant)
            section.push(new SectionDetailsModel('Type of Sample', 'Search Warrant (487)', true));

          if (test.bloodSampleTest.isHospitalRecord)
            section.push(new SectionDetailsModel('Type of Sample', 'Hospital Records (Production Order or Consent)', true));
        }
        else {
          if (isNewDREProcess)
            section.push(new SectionDetailsModel(isRoadsideAppeal ? "Time of Roadside Appeal Request" : "Blood Sample Demand Time", test.bloodSampleTest?.bloodSampleDemandTime));

          section.push(new SectionDetailsModel('Blood Sample Time', test.bloodSampleTest?.bloodSampleTime));
          section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
          section.push(new SectionDetailsModel('Blood Sample Result', test.bloodSampleTest?.bloodSampleResult));
        }
        break;
      case "9": //Urine Sample
        if (isNewDREProcess)
          section.push(new SectionDetailsModel(isRoadsideAppeal ? "Time of Roadside Appeal Request" : "Urine Sample Demand Time", test.urineSampleTest?.urineSampleDemandTime));

        section.push(new SectionDetailsModel('Urine Sample Time', test.urineSampleTest?.urineSampleTime));
        section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.urineSampleTest?.labTypeId)?.name));
        section.push(new SectionDetailsModel('Urine Sample Result', test.urineSampleTest?.urineSampleResult));
        break;
    }

    if (test.testTypeId != TestTypes.NoScreening && test.testTypeId != TestTypes.NoAppeal && (test.testTypeId != TestTypes.DRE || !isNewDREProcess) && (test.testTypeId != TestTypes.Blood || !isNewDREProcess || isRoadsideAppeal)) //No Screening Method and No Appeal
    {
      section.push(new SectionDetailsModel((isRoadsideAppeal && isNewFormat) ? "Decline?" : "Refusal?", ((isRoadsideAppeal || !isNewFormat) && test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal) ? "Yes" : this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));

      if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
        section.push(new SectionDetailsModel(`Time of ${(isRoadsideAppeal && isNewFormat) ? "Decline" : "Refusal"}`, test.refusalTime));
    }
  }

  setApplicantName(): void {
    if (this.review.reviewTypeId == ReviewTypes.IrsReview)
      this.reviewApplicantName = `${this.review.driverFirstName ?? ""} ${this.review.driverLastName}`;
    else if (this.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview)
      this.reviewApplicantName = `${this.review.seizureDriverFirstName ?? ""} ${this.review.seizureDriverLastName}`;
    else if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview)
      this.reviewApplicantName = `${this.review.reviewApplicant.firstName ?? ""} ${this.review.reviewApplicant.lastName}`;
    else
      this.reviewApplicantName = `${this.review.registeredOwnerFirstName ?? ""} ${this.review.registeredOwnerLastName}`;

    //If submitted at registry  
    if (this.review.isSubmittedByRegistry) {
      if (!this.review.registryAgentInformation[0].isPaidByRecipient) {
        this.reviewApplicantName = `${this.review.registryAgentInformation[0].payerFirstName} ${this.review.registryAgentInformation[0].payerLastName} on behalf of ${this.reviewApplicantName}`;
      }
    }
    else if (this.review.representation != null && this.review.reviewTypeId != ReviewTypes.ThirdPartySeizureReview) {
      this.reviewApplicantName = `${this.review.representation.firstName} ${this.review.representation.lastName} on behalf of ${this.reviewApplicantName}`;
    }

    if (this.lateReviewRequest != null) {
      if (this.lateReviewRequest.reviewTypeId == RequestTypes.LEACancellationRequest) {
        this.lateReviewApplicantName = `${this.lateReviewRequest.requestingOfficer.firstName} ${this.lateReviewRequest.requestingOfficer.lastName}`;
      }
      else {
        // Generate name
        this.lateReviewApplicantName = `${this.lateReviewRequest.driverFirstName} ${this.lateReviewRequest.driverLastName}`;

        if (this.lateReviewRequest.isSubmittedByRegistry) {
          if (!this.lateReviewRequest.registryAgentInformation[0].isPaidByRecipient) {
            this.lateReviewApplicantName = `${this.lateReviewRequest.registryAgentInformation[0].payerFirstName} ${this.lateReviewRequest.registryAgentInformation[0].payerLastName} on behalf of ${this.lateReviewApplicantName}`;
          }
        }
        else if (this.lateReviewRequest.representation != null) {
          this.lateReviewApplicantName = `${this.lateReviewRequest.representation.firstName} ${this.lateReviewRequest.representation.lastName} on behalf of ${this.lateReviewApplicantName}`;
        }
      }
    }
  }

  ClearSectionDetails(): void {
    this.recipientInformation = [];
    this.SDPRecipientInformation = [];
    this.recipientIdentificationInformation = [];
    this.SDPRecipientIdentificationInformation = [];
    this.recipientContactInformation = [];
    this.SDPRecipientContactInformation = [];
    this.contraventionInformation = [];
    this.contraventionType = [];
    this.occurrenceTimeAndLocationInformation = [];
    this.locationInformation = [];
    this.occurrenceInformation = [];
    this.witnessInformation = [];
    this.contraventionGrounds = [];
    this.impairmentScreeningInformation = [];
    this.impairmentScreeningDetails = [];
    this.additionalScreeningInformation = [];
    this.policeNarrativeDetails = [];
    this.SDPPoliceNarrativeDetails = [];
    this.roadsideAppealInformation = [];
    this.roadsideAppealDetails = [];
    this.officerInformation = [];
    this.reasonableGrounds = [];
    this.seizureInformation = [];
    this.SDPSeizureInformation = [];
    this.towLotInformation = [];
    this.SDPTowLotInformation = [];
    this.vehicleInformation = [];
    this.SDPVehicleInformation = [];
    this.trailerInformation = [];
    this.SDPTrailerInformation = [];
    this.registeredOwnerInformation = [];
    this.SDPRegisteredOwnerInformation = [];
    this.vehicleDriverInformation = [];
    this.SDPVehicleDriverInformation = [];
    this.policeServiceInformation = [];
    this.SDPPoliceServiceInformation = [];
    this.declarationInformation = [];
    this.SDPDeclarationInformation = [];
    this.supportingDocumentDeclarationInformation = [];
    this.SDPSupportingDocumentDeclarationInformation = [];
    this.reServiceInformation = [];
    this.SDPReServiceInformation = [];
  }

  getDocumentTypeName(supportingDocument: Document): string {
    var documentTypeName = `${this.localStorageService.getDocumentTypes().find(x => x.id == supportingDocument.documentTypeId)?.name}
    ${(supportingDocument.documentDescription == null || supportingDocument.documentDescription == "") ? "" : " " + supportingDocument.documentDescription}`;

    return documentTypeName;
  }

  getEventTypeName(eventTypeId: number): string {
    return this.localStorageService.getEventTypes().find(x => x.id == eventTypeId).name;
  }

  getDocumentUploadNameAndDate(document: Document): string {
    return `Uploaded By ${document.uploadedBy} on ${(moment(document.uploadedDate)).format('MMM DD, YYYY')}`;
  }

  getDocumentPublishedDate(document: Document): string {
    return `Published on ${(moment(document.publishedDate)).format('MMM DD, YYYY')}`;
  }

  onSubmitDecisionClick(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(JudicialReviewDecisionComponent);

    componentRef.instance.judicialReview = Object.assign({}, this.judicialReview);
    componentRef.instance.close.subscribe((result: JudicialReview) => {
      this.viewContainerRef.clear();
      if (result != null) {
        this.judicialReview = result;
        this.judicialReviewUpdated.emit(this.judicialReview);
      }
    });
  }

  onAddStayOrderModalClick(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(StayOrderComponent);

    componentRef.instance.judicialReview = this.judicialReview;
    componentRef.instance.isAdd = true;
    componentRef.instance.isEdit = false;
    componentRef.instance.isRevoke = false;
    componentRef.instance.close.subscribe((result: JudicialReview) => {
      this.viewContainerRef.clear();
      if (result != null) {
        this.judicialReview = result;
        this.judicialReviewUpdated.emit(this.judicialReview);
      }
    });
  }

  onDownloadStayOrderClick(stayOrder: StayOrder): void {
    this.isStaydownloadStarted = true;
    this.documentRefTypeName = "JudicialReviews/StayOrders";
    this.documentRefTypeNumber = stayOrder?.stayOrderId.toString();
    this.getDownloadPackage(stayOrder?.documents, true);
  }

  onRevokeStayOrderClick(stayOrder: StayOrder): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(StayOrderComponent);

    componentRef.instance.judicialReview = this.judicialReview;
    componentRef.instance.stayOrder = stayOrder;
    componentRef.instance.isAdd = false;
    componentRef.instance.isEdit = false;
    componentRef.instance.isRevoke = true;

    componentRef.instance.close.subscribe((result: JudicialReview) => {
      this.viewContainerRef.clear();
      if (result != null) {
        this.judicialReview = result;
        this.judicialReviewUpdated.emit(this.judicialReview);
      }
    });
  }

  onEditStayOrderClick(stayOrder: StayOrder): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(StayOrderComponent);

    componentRef.instance.judicialReview = this.judicialReview;
    componentRef.instance.stayOrder = stayOrder;
    componentRef.instance.isAdd = false;
    componentRef.instance.isEdit = true;
    componentRef.instance.isRevoke = false;
    componentRef.instance.close.subscribe((result: JudicialReview) => {
      this.viewContainerRef.clear();
      if (result != null) {
        this.judicialReview = result;
        this.judicialReviewUpdated.emit(this.judicialReview);
      }
    });
  }

}
