<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Change Review Method</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <form #changeReviewMethodForm="ngForm">
            <div class="modal-container-body">
                <div class="mb-3">                
                    <label class="modal-body-label required" for="oral">Review Method</label>
                    <div class="d-flex mt-1">                   
                        <div class="form-check custom-control-inline" 
                            *ngFor="let reviewMethodType of reviewMethodTypes; let i=index;"
                            [ngClass]="{'ms-4': i > 0}">
                            <input type="radio" class="form-check-input" 
                                [(ngModel)]="review.reviewMethodTypeId"
                                [value]="reviewMethodType.id.toString()"
                                id="reviewMethodType_{{reviewMethodType.id}}"
                                name="reviewMethodTypeId"
                                (change)="onReviewMethodChange()"
                            />
                            <label class="form-check-label" 
                                for="reviewMethodType_{{reviewMethodType.id}}">{{reviewMethodType.name}}</label>                                                
                        </div>                    
                    </div>                                                                       
                </div>

                <div class="mb-3 row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == enumReviewMethodType.Oral">
                    <div class="col-12">
                        <app-failed-attendance-declaration
                        [review]="review"
                        [isFormSubmitted]="changeReviewMethodForm.submitted"
                        [isRSGPortal]="false">
                        </app-failed-attendance-declaration>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="modal-body-label"
                        [ngClass]="{ 'required': +review.reviewMethodTypeId == 2 }" 
                        for="videoConferenceUrl">Webex Link</label>
                    <input type="text" id="videoConferenceUrl" name="videoConferenceUrl"
                        #videoConferenceUrl="ngModel"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': videoConferenceUrl.invalid && videoConferenceUrl.touched }"
                        [(ngModel)]="review.videoConferenceUrl"
                        [value]="+review.reviewMethodTypeId == 1 ? null : review.videoConferenceUrl"
                        [disabled]="+review.reviewMethodTypeId == 1" />
                    <span *ngIf="videoConferenceUrl.invalid && videoConferenceUrl.touched" 
                        class="invalid-feedback">Webex Link is required</span>                
                </div>

                <div class="mb-3">
                    <label class="modal-body-label"
                        [ngClass]="{ 'required': +review.reviewMethodTypeId == enumReviewMethodType.Oral }" 
                        for="meetingKey">Webex Access Code</label>
                    <input type="text" id="meetingKey" name="meetingKey"
                        #meetingKey="ngModel"
                        class="form-control"
                        [(ngModel)]="review.scheduledEvent.meetingKey"
                        [value]="+review.reviewMethodTypeId == enumReviewMethodType.Written ? null : review.scheduledEvent.meetingKey"
                        [disabled]="+review.reviewMethodTypeId == enumReviewMethodType.Written" />
                                   
                </div>

                <div class="mb-3 mt-4 d-flex flex-column">
                    <label class="modal-body-label" for="eventDetails">Note</label>
                    <textarea 
                        id="eventDetails" 
                        name="eventDetails"
                        [(ngModel)]="eventDetails"
                        #note="ngModel"
                        class="modal-textarea"
                        [ngClass]="{ 'invalid': note.invalid && note.touched  }"                             
                        (input)="onTextareaInput()"></textarea>
                    <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>                
                    <span *ngIf="note.errors?.required != null && note.touched" 
                        class="invalid" style="margin-top: 6px;">Note is required</span>
                </div>
            </div>
            <div class="button-container">
                <button id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</button>

                <button id="changeButton" class="change-button" [disabled]="changeReviewMethodForm.invalid" 
                    (click)="onChangeModalClick($event)">
                    Change
                    <i class="fas fa-cog fa-spin"></i>
                </button>
            </div>
        </form>
    </div>
</div>