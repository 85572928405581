import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ContactMethodTypes, DocumentTypes, RequestTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DecisionRequest } from '@apis/shared/models/decision-request.model';
import { FileUploadModel } from '@apis/shared/models/file-upload.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { DecisionType } from '@apis/shared/models/types/decision-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Guid } from 'guid-typescript';
import { Document } from '@apis/shared/models/document.model';
import { AdditionalDocumentCategory } from '@apis/shared/enums/additional-document-category.enum';
import { RequestService } from '../../../shared/services/request.service';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import * as fileSaver from 'file-saver';
import { ContactAddress } from '@apis/shared/models/contact-address.model';
import { NgForm } from '@angular/forms';
import { Constants } from "@apis/shared/helpers/constants";
import { FileUploadComponent } from '@apis/shared/components/file-upload/file-upload.component';
import { DocumentState } from '@apis/shared/enums/document-state.enum';

declare var $: any

@Component({
  selector: 'app-decision-information',
  templateUrl: './decision-information.component.html',
  styleUrls: ['./decision-information.component.scss']
})
export class DecisionInformationComponent implements OnInit, OnChanges {
  @Input() request: any;
  @Input() requestType: any;
  @Output() decisionSubmitted = new EventEmitter<any>();

  lateReviewRequest: LateReviewRequest;
  fileUploadModel: FileUploadModel;
  decisionDocument: any;

  decisionTypes: DecisionType[] = [];
  RequestTypes = RequestTypes;
  TypeTable = TypeTable;

  requestNumber = '';
  decisionDate = '-';
  decisionDueDate = '-';
  contactMethod = '-';
  contactAddress = '-';
  contactAddressPostalCode = '';
  isFormSubmitted: boolean = false;

  decisionRequest = new DecisionRequest({
    document: new Document({
      documentTypeId: DocumentTypes.RequestDecisionLetter
    }),
    decisionTypeId: null
  });
  errorMessage = '';
  sysErrorMessage = '';
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;  

  @ViewChild("decisionForm") decisionForm: NgForm;
  @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;

  constructor(
    public localStorageService: LocalStorageService,
    private requestService: RequestService,
    private readonly documentService: DocumentService,
    private readonly spinner: NgxSpinnerService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.decisionTypes = this.localStorageService.getRequestDecisionTypes();
    
    //Initialize file upload model and a temporary file storage location
    this.fileUploadModel = new FileUploadModel();
    this.fileUploadModel.tempFileFolder = Guid.create().toString();
    this.fileUploadModel.additionalDocumentsCategory = AdditionalDocumentCategory.ContraventionReview;    
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.request?.currentValue != null) { 
      this.loadData();
    }
  }

  private loadData(): void {
    this.contactMethod = this.getContactMethodTypeName(this.request.contactMethodTypeId);
    this.contactAddress = this.getContactAddress();

    if (this.request.decisionDate) {
      this.decisionDate = this.datePipe.transform(this.request.decisionDate, 'mediumDate');
    }

    if (this.request.decisionDueDate) {
      this.decisionDueDate = this.datePipe.transform(this.request.decisionDueDate, 'mediumDate');
    }

    this.lateReviewRequest = this.request;
    this.requestNumber = this.lateReviewRequest.lateReviewRequestNumber;

    if (this.request.documents?.length > 0)
    {
      this.decisionDocument = this.request.documents.find(x => x.documentTypeId == DocumentTypes.RequestDecisionLetter);
    }
  }


  onCloseIconClick(): void {  
    $('#submitDecisionModal').modal('hide');
    this.decisionForm.resetForm();
  }

  onCancelDcoument(ev: any): void { 
  }

  onSubmitClick() {    
    if (!this.isFormSubmitted && this.validateDocuments() && this.decisionForm.valid) {
      this.spinner.show();
      this.isFormSubmitted = true;
      this.requestService.SubmitLateReviewDecision(this.decisionRequest)
          .pipe(
            finalize(() => {
              this.spinner.hide(); 
            })
          )
          .subscribe(
            (result: any) => {
              $('#submitDecisionModal').modal('hide');
              this.request = result;              
              this.lateReviewRequest = result;

              this.loadData();
              this.decisionSubmitted.emit(this.request);
              this.isFormSubmitted = false;
            },
            (error: any) => {
              this.sysErrorMessage = 'An error occurred while processing the request. Please contact admin.';
              this.isFormSubmitted = false;
            });
      
    }
  }

  showDecisionModal() {
    this.decisionRequest = new DecisionRequest({
      document: new Document({
        documentTypeId: DocumentTypes.RequestDecisionLetter
      }),
      decisionTypeId: null,
      requestNumber: this.requestNumber,
      tempFolderName: this.fileUploadModel.tempFileFolder
    });
    this.errorMessage = '';
    this.sysErrorMessage = '';
    this.fileUploadComponent.documentState = DocumentState.NotUploaded;

    $('#submitDecisionModal').modal();
  }

  downloadDocument(document: DocumentModel)
  {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", "Requests", this.requestNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService.downloadDocument("", "Requests", this.requestNumber, storageFileName , document.documentName)
    .subscribe((result: Blob) => {
      if (result)
      { 
        fileSaver.saveAs(result, document.documentName);
      }
    });
  }

  validateDocuments(): boolean {
    //Check if any document is not uploaded
    this.errorMessage = '';

    if (this.decisionRequest.document.documentName == null || this.decisionRequest.document.documentName.trim().length == 0) 
    {   
      this.errorMessage = "All documents are required";
      return false;
    }

    return true;
  }

  getContactMethodTypeName(contactMethodTypeId: any) : string {
    switch (contactMethodTypeId) {
      case ContactMethodTypes.Phone.toString():
        return 'Mailing Address';
      case ContactMethodTypes.Text.toString():
        return 'Text/SMS'
      case ContactMethodTypes.Email.toString():
        return 'Email';
      default:
        return '-';
    }
  }

  getContactAddress(): string {
    switch (this.request.contactMethodTypeId) {
      case ContactMethodTypes.Email.toString():
        return this.request.email;
      case ContactMethodTypes.Text.toString():
        return this.request.phoneNumber;
      case ContactMethodTypes.Phone.toString():
        return this.formatContactAddress(this.request.contactAddress);
      default:
        return '-';
    }
  }

  formatContactAddress(contactAddress: ContactAddress): string {
    let address = contactAddress.addressLine1;

    if (contactAddress.addressLine2){
      address = address.concat(', ', contactAddress.addressLine2);
    }

    this.contactAddressPostalCode = contactAddress.postalCode;
    
    return address;
  }

  deleteDecisionLetter() {
    this.requestService.deleteDecision(this.requestNumber)
      .subscribe((result: any) => {
        this.request = result;
        this.decisionSubmitted.emit(this.request);
        this.loadData();
      });
  }

  onDeleteDecisionLetterClick(): void {
    if (this.decisionDocument == null) {
      return;
    }

    $('#confirmModal').modal();
  }
}
