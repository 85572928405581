<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Log Refund Details</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="mb-3 row">
                <div class="col-5">
                    <label class="modal-body-label" for="transactionIdTxt">Transaction ID</label>
                    <input type="text" readonly id="transactionIdTxt" name="transactionIdTxt" class="form-control"
                        [ngModel]="logRefundRequest.sourceTransactionId">
                </div>
                <div class="col-5">
                    <label class="modal-body-label" for="refundAmountTxt">Amount Refunded</label>
                    <input type="text" readonly id="refundAmountTxt" name="refundAmountTxt" class="form-control"
                        [ngModel]="logRefundRequest.refundAmount | currency">
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-5">
                    <label class="modal-body-label required" for="refundIdTxt">Refund Confirmation ID</label>
                    <input type="text" id="refundIdTxt" name="refundIdTxt" class="form-control" maxlength="40"
                        [(ngModel)]="logRefundRequest.refundTransactionId" #refundIdModel="ngModel" required>
                    <div class="invalid-feedback"
                        [ngClass]="{ 'show': refundIdModel.invalid && isSubmitClicked }">
                        <div>Refund Confirmation ID is required.</div>
                    </div>
                </div>
                <div class="col-5">
                    <label class="modal-body-label required" for="refundDt">Refund Date</label>
                    <input type="text"
                        required
                        class="form-control"
                        id="refundDt"
                        name="refundDt"
                        [(ngModel)]="logRefundRequest.refundDate"
                        #dp="bsDatepicker"
                        #refundDateControl="ngModel"
                        bsDatepicker
                        [bsConfig]="datePickerConfig"
                        [dateCustomClasses]="dateCustomClasses"
                        placeholder="MMM DD, YYYY"
                    />
                    <span class="calendar-icon" (click)="dp.toggle()"><i class="far fa-calendar"></i></span>
                    <div class="invalid-feedback" [ngClass]="{ 'show': refundDateControl.errors?.required && isSubmitClicked }">Refund Date is required</div>
                </div>
            </div>
            <div>
                <label for="noteTextarea" class="bold-text">Note</label>
                <textarea id="noteTextarea" name="noteTextarea"
                    class="modal-textarea"
                    [(ngModel)]="logRefundRequest.note"
                    (input)="onTextareaInput($event)"
                    ></textarea>
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            </div>
        </div>
        <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()">Cancel</div>
            <div id="confirmButton" class="confirm-button" (click)="onConfirmModalClick(refundIdModel.valid && refundDateControl.valid)">
                Confirm
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>
