<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Submit JR Outcome</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="mb-3 row">
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label" for="courtActionNumber">Court Action #</label>
                    <input type="text" [readOnly]="isCourtActionNumberReadonly" id="courtActionNumber"
                        name="courtActionNumber" #courtActionNumberModel="ngModel"
                        [(ngModel)]="judicialReview.courtActionNumber" placeholder="Enter Court Action Number"
                        [ngClass]="{'alert-border': courtActionNumberModel.invalid && formSubmitted }"
                        class="form-control"/>                                            
                </div>
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label required" for="judicialReviewDecisionDate">JR Decision Date</label>
                    <input type="text" required class="form-control" id="judicialReviewDecisionDate"
                        name="judicialReviewDecisionDate" #dp="bsDatepicker" bsDatepicker [bsConfig]="datePickerConfig"
                        [dateCustomClasses]="dateCustomClasses" placeholder="mm/dd/yyyy"
                        [(ngModel)]="judicialReview.judicialReviewDecisionDate"
                        #judicialReviewDecisionDateModel="ngModel"
                        [ngClass]="{ 'alert-border': judicialReviewDecisionDateModel.invalid && formSubmitted}" />
                    <span class="calendar-icon" (click)="dp.toggle()"><i class="far fa-calendar"></i></span>                                           
                </div>
                <div class="col-12 col-lg-4" style="position: relative;">
                    <label class="modal-body-label required" for="judicialReviewDecisionType">JR Decision</label>
                    <select id="judicialReviewDecisionType" name="judicialReviewDecisionType"
									#judicialReviewDecisionTypeModel="ngModel"
									[(ngModel)]="judicialReview.judicialReviewDecisionTypeId"									
									class="form-select modal-select"
                                    [ngClass]="{ 'alert-border': judicialReview.judicialReviewDecisionTypeId == null && formSubmitted}">
									<option class="modal-select-option" [ngValue]="null">-</option>
									<option class="modal-select-option"                        
										*ngFor="let judicialReviewDecisionType of judicialReviewDecisionTypes"                                                 
										[value]="judicialReviewDecisionType.id">{{judicialReviewDecisionType.name}}</option>                                        
					</select>                                                    
                </div>
            </div>
            
            <label class="label" for="uploadDecisionLetter">Court decision letter (Required)</label>            
            <file-upload [document]="selectedDocument" [fileUploadModel]="fileUploadModel"
            [isDisabled]="disableFileUpload" (onCancelEvent)="onCancelDocument($event)"></file-upload>
            <div class="document-upload-hint">Allowed: {{this.validFileExtensions.join(', ')}}</div> 
            <div *ngIf="errorMessage.length > 0">
                <span class="error">{{errorMessage}}</span>
            </div>
        </div>
        <div class="button-container">
            <div id="submitButton" class="submit-button" [ngClass]="{ 'disabled': this.selectedDocument.isUploading }" (click)="onSubmitClick($event)">
                Submit
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>