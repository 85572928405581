import { Component, Input, OnInit, ViewContainerRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Document } from '@apis/shared/models/document.model';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from "file-saver";
import { DocumentService } from '@apis/shared/services/document.service';
import { SubmitDecisionModalComponent } from './modals/submit-decision-modal/submit-decision-modal.component';
import { Review } from '@apis/shared/models/review.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DocumentTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { ReviewService } from '../../../shared/services/review.service';
import { Constants } from "@apis/shared/helpers/constants";
import { AccessControlComponent } from '@apis/shared/components/access-control/access-control.component';
import { CommonMethods } from '../../../shared/helpers/common-methods';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
declare var $: any

@Component({
  selector: 'decision',
  templateUrl: './decision.component.html',
  styleUrls: ['./decision.component.scss']
})
export class DecisionComponent implements OnInit, OnChanges {
  @Input() contravention: Contravention;
  @Input() review: Review;
  @Output() decisionSubmitted = new EventEmitter<Review>();

  preferredContactMethodName: string;
  address: string;
  decisionLetter: Document;
  additionalDecisionDocuments: Document[] = [];
  additionalDecisionDocumentTypeId: number = DocumentTypes.AdditionalDecisionDocument;
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  errorMessage: string;
  decisionDueDate = '-';
  reviewOutcome = '-';
  Resource: any= Constants.Resource;
  Permission: any = Constants.Permission;
  ReviewTypes = ReviewTypes;
  isDecisionDateEdited: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  decisionDateErrorMessage: string = "";
  isSaving: boolean = false;
  constructor(private readonly documentService: DocumentService,
    private readonly localStorageService: LocalStorageService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly reviewService: ReviewService,
    private readonly datePipe: DatePipe) { 
      this.datePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true
          })
    }

  ngOnInit(): void {}

  ngOnChanges(simpleChanges: SimpleChanges): void {    
    if (simpleChanges?.review?.currentValue != null) {
      
      this.documentRefTypeName = "Reviews";
      this.documentRefTypeNumber = this.review.reviewNumber;

      this.populateDecisionSendMethod();
      this.populateDecisionDetails();
      this.populateAdditionalDecisionDocuments();
      this.errorMessage = '';
    }     
  }

  populateDecisionSendMethod(): void {
    this.preferredContactMethodName = this.localStorageService.getTypeItemDescriptionById(this.review.contactMethodTypeId, TypeTable.ContactMethodType);

    if (this.review.contactAddress != null) {
      let contactAddress = this.review.contactAddress;
      let addressLine1 = contactAddress.addressLine1;
      let city = contactAddress.city;
      let provinceCode = this.localStorageService.getProvinceTypes()?.find(p => +p.id == +contactAddress.provinceId)?.code;
      let postalCode = contactAddress.postalCode;

      this.address = `${addressLine1}. ${city}, ${provinceCode}  ${postalCode}`;
    }            
  }

  populateDecisionDetails(): void {    
    if (this.review?.reviewTypeId != ReviewTypes.SuspendedDriverVehicleSeizureReview && this.review?.reviewTypeId != ReviewTypes.ThirdPartySeizureReview) {
      this.decisionDueDate = this.datePipe.transform(this.review?.decisionDueDate, 'mediumDate');
    }
    if (this.review?.outcome?.reviewOutcomeTypeId != null) {
      this.reviewOutcome = this.localStorageService.getTypeItemDescriptionById(this.review.outcome.reviewOutcomeTypeId, TypeTable.ReviewOutcomeType);
    }
    this.decisionLetter = this.review.documents.find(d => d.documentTypeId == DocumentTypes.ReviewDecisionLetter);    
  }

  getDecisionTypeName(decisionTypeId: any) {
    return CommonMethods.renameDecisionByReviewTypeId(
      this.localStorageService.getTypeItemDescriptionById(decisionTypeId, TypeTable.DecisionType),
      this.review.reviewTypeId);
  }

  populateAdditionalDecisionDocuments(): void {
    this.additionalDecisionDocuments = this.review.documents.filter(d => +d.documentTypeId == DocumentTypes.AdditionalDecisionDocument);
  }
    
  onSubmitDecisionClick(ev: any): void {
    // Check review outcome is set, if not restrict user from submitting a decision
    var outcome = this.review.outcome;
    if (outcome && outcome.reviewOutcomeTypeId == null) {
      this.errorMessage = "Please select a review outcome to submit a decision.";
      return;
    }

    this.viewContainerRef.clear();
    
    const reviewTypeName = this.localStorageService.getTypeItemDescriptionById(+this.review.reviewTypeId, TypeTable.ReviewType);

    const componentRef = this.viewContainerRef.createComponent(SubmitDecisionModalComponent);    
    componentRef.instance.contravention = this.contravention;
    componentRef.instance.review = this.review;
    componentRef.instance.reviewTypeName = reviewTypeName;
           
    componentRef.instance.close.subscribe((result: Review) => {
      this.viewContainerRef.clear();

      if (result != null) {
        this.review = result;  
        this.decisionSubmitted.emit(this.review);
        this.populateDecisionDetails();
      }      
    });
  }
  
  onDecisionLetterClick(): void {
    let storageFileName = `${this.decisionLetter.contentGuid}.${this.decisionLetter.documentExtension}`;
    this.documentService.downloadDocument("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, this.decisionLetter.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, this.decisionLetter.documentName);
      },
        (error: any) => {});
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    this.documentService.downloadDocument("", this.documentRefTypeName, this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, document.documentName);
      },
        (error: any) => {});
  }

  onDeleteDecisionLetterClick(): void {
    if (this.decisionLetter == null) {
      return;
    }

    $('#confirmModal').modal('show');
  }

  deleteDecisionLetter() {
    this.reviewService.deleteDecision(this.review.reviewId)
      .subscribe((result: Review) => {
        this.review = result;
        this.decisionSubmitted.emit(this.review);
        this.populateDecisionDetails();
      });
  }

  onAdditionalDecisionDocumentAdded(document: Document): void { 
    this.reviewService.postDocument(this.review.reviewId, document)
      .subscribe((result: Review) => {
        this.review = result;
        this.populateAdditionalDecisionDocuments();
      });        
  }

  onDeleteAdditionalDecisionDocumentClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    this.documentService.deleteFinalizedDocument(storageFileName, this.documentRefTypeName, this.documentRefTypeNumber)
      .subscribe((result: any) => {});    

    this.reviewService.deleteDocument(this.review.reviewId, document.documentId)
      .subscribe((review: Review) => {
        if (review != null) {
          this.review = review;
          this.populateAdditionalDecisionDocuments();
        }
      });
  }

  getContraventionTypeName(contraventionTypeId: number): string {
    var contraventionType = this.localStorageService.getContraventionTypes().find(t => t.id == contraventionTypeId);
    if (contraventionType) {
      return `${contraventionType.formattedChargeCode}`;
    } else {
      return '';
    }
  }

  getCancellationReasonName(reviewCancellationReasonTypeId: number): string {
    return this.localStorageService.getReviewCancellationReasonTypes().find(t => t.id == reviewCancellationReasonTypeId)?.name;
  }

  getCancellationReasonDescription(reviewCancellationReasonTypeId: number): string {
    return this.localStorageService.getReviewCancellationReasonTypes().find(t => t.id == reviewCancellationReasonTypeId)?.description;
  }

  getCancellationSecondaryReasonName(reviewCancellationSecondaryReasonTypeId: number): string {
    return this.localStorageService.getReviewCancellationSecondaryReasonTypes().find(t => t.id == reviewCancellationSecondaryReasonTypeId)?.name;
  }

  editDecisionDateClick()
  {
    this.isDecisionDateEdited = true;
  }

  editDecisionDateSaveClick(isValid: boolean)
  {
    if (isValid) {
      this.isSaving = true;
      this.reviewService
        .updateReviewDecisionDate(
          this.review.reviewId,
          this.datePipe.transform(this.review.decisionDate, 'yyyy-MM-dd')
        )
        .subscribe(
          (result: Review) => {
            this.decisionDateErrorMessage = '';
            this.review = result;
            this.isDecisionDateEdited = false;
            this.decisionSubmitted.emit(this.review);
            this.isSaving = false;
          },
          (error: any) => {
            if (error.status == 401 || error.status == 403) {
              this.decisionDateErrorMessage =
                'You are unauthorized to perform this operation';
            } else {
              this.isSaving = false;
              this.decisionDateErrorMessage = this.getErrorMessage(error);
            }
          }
        );
    }
  }

  getErrorMessage(error: any): string {
    if (error?.error && Array.isArray(error?.error))
      return error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      return error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      return error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      return error.error.errors;
    else
      return "Something went wrong. Please try again later.";
  }
}