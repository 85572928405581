import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestTypes, DocumentTypes, RepresentationTypes, TransactionTypes, EventTypes } from '@apis/shared/enums/app.enum';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { CommonUtil } from '@apis/shared/helpers/common-util'
import { Document } from '@apis/shared/models/document.model';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import * as fileSaver from "file-saver";
import { DocumentService } from '@apis/shared/services/document.service';
import { Constants } from "@apis/shared/helpers/constants";
import { PaymentDataEditRequest } from '@apis/shared/models/payment-data-edit-request.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequestService } from '../../../shared/services/request.service';
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { LateReviewRequestPayment } from '@apis/shared/models/late-review-request-payment.model';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';
import { LogRefundModalComponent } from '../../../shared/components/modals/log-refund-modal/log-refund-modal.component';
import { RefundStatus } from '@apis/shared/enums/refund-status.enum';
import { AdditionalDocumentModalComponent } from '../../../shared/components/supporting-documents/modals/additional-document-modal/additional-document-modal.component';
import { Event } from '@apis/shared/models/event.model';
import { AdjudicationService } from '../../../shared/services/adjudication.service';

@Component({
  selector: 'app-request-information',
  templateUrl: './request-information.component.html',
  styleUrls: ['./request-information.component.scss']
})
export class RequestInformationComponent implements OnChanges {
  @Input() request: any;
  @Input() requestType: any;
  @Output() viewReceiptClicked = new EventEmitter();
  @Output() paymentInfoEdited = new EventEmitter<LateReviewRequest>();

  lateReviewRequest: LateReviewRequest;
  noticeCancellationRequest: NoticeCancellationRequest;

  reasonForRequestInformation: SectionDetailsModel[] = [];
  applicantInformation: SectionDetailsModel[] = [];
  registryInformation: SectionDetailsModel[] = [];
  supportingDocuments: Document[] = [];
  documentRefTypeName = "Requests";
  documentRefTypeNumber: string;
  documentRefId: number;
  termsConditionsStatement: string;

  Resource: any = Constants.Resource;
  Permission: any = Constants.Permission;
  RepresentationTypes = RepresentationTypes;
  TransactionTypes = TransactionTypes;
  paymentDataEditRequest: PaymentDataEditRequest;
  isSubmitClicked: boolean = false;
  RequestTypes=RequestTypes;
  requestDateTime: Date;

  canLogRefund: boolean = false;
  RefundStatus = RefundStatus;

  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private readonly documentService: DocumentService,
    private datePipe: DatePipe,
    private readonly spinner: NgxSpinnerService,
    private readonly requestService: RequestService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly adjudicationService: AdjudicationService
  ) {
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.canLogRefund = this.localStorageService.hasPermission(Constants.Resource.REQUEST, Constants.Permission.LOG_REFUND);
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {    
    if (simpleChanges?.request?.currentValue != null) {  
      this.reasonForRequestInformation = [];
      this.applicantInformation= [];   
      this.registryInformation= [];
      if (this.requestType == RequestTypes.LEACancellationRequest.toString()) {
        this.noticeCancellationRequest = this.request;

        this.requestDateTime = new Date(this.noticeCancellationRequest.requestDate.toString().replace(/[zZ]/, ""));
        this.requestDateTime.setMinutes(this.requestDateTime.getMinutes() - this.requestDateTime.getTimezoneOffset());

        this.applicantInformation.push(new SectionDetailsModel('Issuing Officer', `${this.noticeCancellationRequest.issuingOfficerName}`));
        this.applicantInformation.push(new SectionDetailsModel('Regimental Number', `${this.noticeCancellationRequest.issuingOfficerRegimentalNumber}`));
        this.applicantInformation.push(new SectionDetailsModel('Issuing Police Service', `${this.noticeCancellationRequest.issuingAgencyCode}`));

        this.applicantInformation.push(new SectionDetailsModel('Issuing Detachment', `${this.noticeCancellationRequest.issuingDetachmentName}`));
        this.applicantInformation.push(new SectionDetailsModel('Request Submitted', `${this.datePipe.transform(this.requestDateTime, "mediumDate")} at ${this.datePipe.transform(this.requestDateTime, "shortTime")}`));

        this.applicantInformation.push(new SectionDetailsModel('Supervising Officer', `${this.noticeCancellationRequest.requestingOfficer.firstName} ${this.request.requestingOfficer.lastName}`));
            
        this.reasonForRequestInformation.push(new SectionDetailsModel('Reason for Cancellation', this.localStorageService.getTypeItemDescriptionById(this.noticeCancellationRequest.noticeCancellationReasonTypeId, TypeTable.NoticeCancellationReasonType), true));
        this.reasonForRequestInformation.push(new SectionDetailsModel('Additional notes about this cancellation request', this.noticeCancellationRequest.noticeCancellationReasonNotes, true));

        this.supportingDocuments = this.noticeCancellationRequest.documents;
        this.documentRefTypeNumber = this.noticeCancellationRequest.noticeCancellationRequestNumber;
        this.documentRefId = this.noticeCancellationRequest.noticeCancellationRequestId;
      }
      else {
        // Applicant information
        // Generate applicant name
        var applicantName = `${this.request.driverFirstName} ${this.request.driverLastName}`;
        
        if (this.request?.isSubmittedByRegistry && !this.request?.registryAgentInformation[0]?.isPaidByRecipient)
          applicantName = `${this.request.registryAgentInformation[0].payerFirstName} ${this.request.registryAgentInformation[0].payerLastName} on behalf of ${applicantName}`;  
        else if (this.request.representation != null) 
          applicantName = `${this.request.representation.firstName} ${this.request.representation.lastName} on behalf of ${applicantName}`;
        
          this.applicantInformation.push(new SectionDetailsModel('Applicant Name', applicantName));

        if (this.request.driverEmail) {
          this.applicantInformation.push(new SectionDetailsModel('Email Address', this.request.driverEmail));
        }

        //Registry Information
        if (this.request.isSubmittedByRegistry)
        {
          this.registryInformation.push(new SectionDetailsModel('Registry Agent Username', this.request.registryAgentInformation[0].userName));
          this.registryInformation.push(new SectionDetailsModel('Organization Code', this.request.registryAgentInformation[0].organizationCode));
          this.registryInformation.push(new SectionDetailsModel('Payer', this.request.registryAgentInformation[0].isPaidByRecipient?'Recipient':`${this.request.registryAgentInformation[0].payerFirstName} ${this.request.registryAgentInformation[0].payerLastName} (${this.request.registryAgentInformation[0].payerMVID})`));
        }

        if (this.request.representation) {
          // Representation Informaton
          const repTypeName = this.localStorageService.getRepresentationTypes()
            .find(x => x.id == this.request.representation.representationTypeId)?.name;

          this.applicantInformation.push(new SectionDetailsModel('Type of Representation', repTypeName));
          const repTitle = CommonUtil.getRepresentationTitle(this.request.representation.representationTypeId);

          this.applicantInformation.push(new SectionDetailsModel(`${repTitle}Name`, `${this.request.representation.firstName} ${this.request.representation.lastName}`));

          if ([RepresentationTypes.Agent, RepresentationTypes.Lawyer].includes(this.request.representation.representationTypeId)) {
            this.applicantInformation.push(new SectionDetailsModel(`${repTitle}Practice Name`, this.request.representation.lastName, true));
          }

          if (this.request.representation.representationTypeId == RepresentationTypes.Other) {
            this.applicantInformation.push(new SectionDetailsModel('Other Representation Type', this.request.representation.otherRepresentationType, true));
          }
        }

        // Preferred Contact Information
        this.applicantInformation.push(new SectionDetailsModel('Preferred Contact Method', this.getContactMethodTypeName(this.request.contactMethodTypeId)));
        if (this.request.phoneNumber) {
          this.applicantInformation.push(new SectionDetailsModel('Phone Number', this.request.phoneNumber));
        }
        else {
          this.applicantInformation.push(new SectionDetailsModel('Email Address', this.request.email));
        }      

        this.lateReviewRequest = this.request;
        this.documentRefTypeNumber = this.lateReviewRequest.lateReviewRequestNumber;
        this.documentRefId = this.lateReviewRequest.lateReviewRequestId;
          
        if(this.requestType==RequestTypes.LateSeizureReview){
        this.reasonForRequestInformation.push(new SectionDetailsModel('Reason for Request', this.lateReviewRequest.requestReason, true));
        }
        
        let documentTypes = [DocumentTypes.RequestDecisionLetter, DocumentTypes.AdditionalDecisionDocument, DocumentTypes.Note];    
        this.supportingDocuments = this.request.documents.filter((d: Document) => !documentTypes.includes(+d.documentTypeId));

        // Terms and conditions statement
        const termsConditionsList = this.localStorageService.getTermsAndConditionsList();
        const reviewTerms = termsConditionsList.find(x => x.version === this.request.termsConditionsVersion);
        this.termsConditionsStatement = this.request.isSubmittedByRegistry ? reviewTerms.adminRegistryStatementFormatted : reviewTerms.adminDriverStatementFormatted;

        // Set payment refund status
        this.request.payments.forEach(p => {
          if (p.financialTransaction.transactionTypeId == TransactionTypes.RefundLateReviewRequestFee) {
            p.refundStatus = RefundStatus.NonRefundable;
          } else {
            var isPaymentRefunded = this.request.payments.some(rp => rp.financialTransaction.sourceTransactionId == p.financialTransaction.transactionId.toString());
            p.refundStatus = isPaymentRefunded ? RefundStatus.Completed : RefundStatus.Refundable;
          }
        });
      }
    }
  }

  addChargeCodeDescSection(contraventionTypeId: number):void{
    var chargeCode = this.getChargeCode(contraventionTypeId);
    var chargeCodeDesc = this.getChargeDescription(contraventionTypeId);
    if(chargeCodeDesc)
    {
      this.reasonForRequestInformation.push(new SectionDetailsModel('',  `${chargeCode} - ${chargeCodeDesc}` , true));                   
    }
    else
    {
      this.reasonForRequestInformation.push(new SectionDetailsModel('',  `${chargeCode}` , true));
    } 
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    let documentRefTypeNumber: string;
    if (this.requestType == RequestTypes.LEACancellationRequest.toString()) {
      documentRefTypeNumber = document.documentLocation.replace('Requests/', '');
    }
    else {
      documentRefTypeNumber = this.documentRefTypeNumber;
    }

    if (document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", this.documentRefTypeName, documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService.downloadDocument("", this.documentRefTypeName, documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {
        fileSaver.saveAs(result, document.documentName);
      },
        (error: any) => { });
  }

  onNAPDocumentNameClick(document: Document) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;
    
    var documentRefTypeName = this.request.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 ? "Contraventions" : "Stop";
    var documentRefTypeNumber = this.request.contravention.stopSubmissionVersion == Constants.SubmissionVersion.PHASE1 
        ? this.request.contravention.contraventionNumber 
        : this.request.contravention.stopInformationId.toString();

    if (document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });
      
      return;
    }

    this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName, document.documentName, document.documentLocation)
    .subscribe((result: Blob) => {
      if (result) {         
          fileSaver.saveAs(result, document.documentName);
      }
    });
  }

  getContactMethodTypeName(contactMethodTypeId: number): string {
    switch (contactMethodTypeId?.toString()) {
      case '1':
        return 'Email';
      case '2':
        return 'Text/SMS'
      case '3':
        return 'Phone';
      default:
        return '';
    }
  }

  getDocumentTypeName(documentTypeId: number): string {
    return this.localStorageService.getTypeItemDescriptionById(documentTypeId, TypeTable.DocumentType);
  }

  getChargeCode(contraventionTypeId: number) {
    return this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId).formattedChargeCode;
  }

  getChargeDescription(contraventionTypeId: number) {
    var contraventionType = this.localStorageService.getContraventionTypes().find( x => x.id == contraventionTypeId);
    return contraventionType.isIRS ? contraventionType.name : contraventionType.description;
  }

  onViewReceiptClick() {
    this.viewReceiptClicked.emit();
  }

  onEditPaymentClick(payment: any) {
    this.paymentDataEditRequest = new PaymentDataEditRequest();
    this.paymentDataEditRequest.financialTransactionId = payment.financialTransactionId;
    this.paymentDataEditRequest.transactionId = payment.financialTransaction.transactionId;

    payment.edited = true;
  }

  onEditPaymentSaveClick(isValid: boolean) {
    this.isSubmitClicked = true;

    if (isValid) {
      this.spinner.show();
      this.requestService.updateLateReviewRequestPaymentInformation(this.request.lateReviewRequestNumber, this.paymentDataEditRequest)
        .subscribe((result: LateReviewRequest) => {
          this.lateReviewRequest = result;
          this.paymentInfoEdited.emit(result);
          this.spinner.hide();
        }, (error: any) => {
          this.spinner.hide();
        });
    }
  }

  onEditPaymentCancelClick(p: any) {
    p.edited = false;
  }

  onLogRefundClick(payment: LateReviewRequestPayment) {
    this.viewContainerRef.clear();

    var logRefundRequest = new LogRefundRequest({
      recordNumber: this.lateReviewRequest.lateReviewRequestNumber,
      refundAmount: payment.amount,
      sourceTransactionId: payment.financialTransaction.transactionId,
      transactionTypeId: payment.financialTransaction.transactionTypeId
    });

    let componentRef = this.viewContainerRef.createComponent(LogRefundModalComponent);
    componentRef.instance.logRefundRequest = logRefundRequest;
    componentRef.instance.close.subscribe((result: any) => {
      componentRef.destroy();
      if (result != null) {
        this.paymentInfoEdited.emit(result);
      }
    });
  }

  public AddOptionalDocument()
  {
    this.viewContainerRef.clear();
    let componentRef = this.viewContainerRef.createComponent(AdditionalDocumentModalComponent);

    componentRef.instance.documentRefTypeName = this.documentRefTypeName;
    componentRef.instance.documentRefTypeNumber = this.documentRefTypeNumber;
    componentRef.instance.documentRefId = this.documentRefId;
    
    componentRef.instance.close.subscribe((result: Document[]) => {
      this.viewContainerRef.clear();
      if (result != null) {        
        result.forEach((document: Document) => {
            this.addEvents(document, EventTypes.DocumentUploaded);
            this.supportingDocuments.push(document);
        });
        this.sortSupportingDocuments();
      }
    });
  }

  addEvents(document, eventType: EventTypes) {
    var events = [];

    if (this.lateReviewRequest?.lateReviewRequestId != null) {
      var documentEvent = this.createEvent(document, eventType);
      documentEvent.lateReviewRequestId = this.lateReviewRequest.lateReviewRequestId;

      events.push(documentEvent);
    }

    this.spinner.show();

    this.adjudicationService.postEvents(events).subscribe((events: Event[]) => {
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });      
  }


  createEvent(document, eventType: EventTypes) {
    var documentTypes = this.localStorageService.getDocumentTypes();
    var documentEvent = new Event();

    documentEvent.eventTypeId = eventType;
    documentEvent.eventDetails = `${documentTypes.find(d => d.id == document.documentTypeId).name} - ${document.documentName}`
    documentEvent.userName = this.localStorageService.getUser().userName;

    return documentEvent;
  }

  getNAPDocuments() {
    return this.request.contravention.documents.filter(x => x.documentTypeId == DocumentTypes.NAPDocument || x.documentTypeId == DocumentTypes.OriginalPaperContravention);
  }

  private sortSupportingDocuments(): void {
    this.supportingDocuments.sort((a: Document, b: Document) => {
      if (+a.documentTypeId > +b.documentTypeId) {
            return 1;
      }

      if (+a.documentTypeId < +b.documentTypeId) {
          return -1
      }

      return 0;
    });
  }
}
