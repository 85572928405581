import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, Subscriber, throwError } from 'rxjs';
import { map, find, catchError } from "rxjs/operators";
import { environment } from "apps/adjudication/src/environments/environment";
import { RequestView } from '@apis/shared/models/request-view.model';
import { PaymentAdditionalTimeRequest } from '@apis/shared/models/payment-additional-time-request.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { DecisionRequest } from '@apis/shared/models/decision-request.model';
import { Event } from '@apis/shared/models/event.model';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RequestPagedSearch } from "@apis/shared/models/request-paged-search.model";
import { PagedResult } from "@apis/shared/models/paged-result.model";
import { Notification } from '@apis/shared/models/notification.model';
import { PaymentDataEditRequest } from '@apis/shared/models/payment-data-edit-request.model';
import {NoticeCancellationRequest} from '@apis/shared/models/notice-cancellation-request.model';
import 'rxjs/add/operator/catch';
import { LateReviewRequestItem } from '@apis/shared/models/late-review-request-item.model';
import { LogRefundRequest } from '@apis/shared/models/log-refund-request.model';

@Injectable()
export class RequestService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }

  getRequests(): Observable<RequestView[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/requests`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<RequestView>(response));
        }
        
        return [new RequestView(response)];
      })
    );
  }

  getRequestsByPagedSearch(requestPagedSearch: RequestPagedSearch): Observable<PagedResult> {
    this.user = this.localStorageService.getUser();

    let params = new HttpParams();
    Object.entries(requestPagedSearch).forEach((pair: [string, any]) => {
      params = params.append(pair[0], pair[1]);
    });
    
    return this.httpClient.get(`${this.apiUrl}/requests/findall`, {
      headers: { "Authorization": `Bearer ${this.user.token}` },
      params: params      
    }).pipe(
      map(response => new PagedResult(response))
    );
  }  

  openAdditionalTimeToPayRequest(requestNumber: string): Observable<PaymentAdditionalTimeRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/additional-time-to-pay-requests/${requestNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new PaymentAdditionalTimeRequest(response))
      );
  }

  openLateReviewRequest(requestNumber: string): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/late-review-requests/${requestNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map(response => new LateReviewRequest(response))
    )
    .catch((error: any) => {     
      return throwError(error);
  });
  }

  getLateReviewRequestItems(requestId: number): Observable<LateReviewRequestItem[]> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/late-review-request-items/${requestId}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => {  
                      
        if (Array.isArray(response)) {
          return (Array.from<LateReviewRequestItem>(response));
        }
        
        return [new LateReviewRequestItem(response)];
      })
    )
    .catch((error: any) => {     
      return throwError(error);
  });
  }

  openNoticeCancellationRequest(requestNumber: string): Observable<NoticeCancellationRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/notice-cancellation-requests/${requestNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new NoticeCancellationRequest(response))
      );
  }

  getLateReviewRequest(requestNumber: string): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/late-review-requests/find/${requestNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new LateReviewRequest(response))
      );
  }

  submitAdditionalTimeToPayDecision(request: DecisionRequest): Observable<PaymentAdditionalTimeRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/additional-time-to-pay-requests/decision`, request, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new PaymentAdditionalTimeRequest(response))
      );
  }

  SubmitLateReviewDecision(request: DecisionRequest): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/late-review-requests/decision`, request, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new LateReviewRequest(response))
      );
  }

  putAdditionalTimeToPayRequestNotification(notification: Notification): Observable<Notification> {    
    return this.httpClient.put(`${this.apiUrl}/additional-time-to-pay-requests/notification`, notification)
      .pipe(
        map(response => new Notification(response))
      );
  }

  putLateReviewRequestNotification(notification: Notification): Observable<Notification> {    
    return this.httpClient.put(`${this.apiUrl}/late-review-requests/notification`, notification)
      .pipe(
        map(response => new Notification(response))
      );
  }

  getFeePaymentReceipt(requestNumber: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/late-review-requests/${requestNumber}/payment-receipt`,
      {
          params: {
          },
          responseType: 'blob'
      });
  }

  updateLateReviewRequestPaymentInformation(requestNumber: string, paymentDataEditRequest: PaymentDataEditRequest): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/late-review-requests/${requestNumber}/edit-payment`, paymentDataEditRequest,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new LateReviewRequest(response))
    );
  }

  deleteDecision(requestNumber: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/late-review-requests/decision`, 
    { 
      params: {
        requestNumber: requestNumber
      },
      headers: {'Authorization': `Bearer ${this.user.token}`}
    }).pipe(
      map(response => response)
    );
  }

  assignAdjudicatorLateReviewRequest(request:LateReviewRequest):Observable<LateReviewRequest>{
    this.user = this.localStorageService.getUser()
    return this.httpClient.put(`${this.apiUrl}/late-review-requests/assignadjudicator`, request,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new LateReviewRequest(response))
      )
  }

  putLateReviewRequestContactMethod(request: LateReviewRequest): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/late-review-requests/contactmethod`, request,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new LateReviewRequest(response))
      );
  }


  cancelLateReviewRequest(lateReviewRequest: LateReviewRequest): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/late-review-requests/cancelrequest`, lateReviewRequest,
      { 
        headers: { "Authorization": `Bearer ${this.user.token}` }
      })
      .pipe(
        map(response => new LateReviewRequest(response))
      );
  }

  submitNoticeCancellationRequestDecision(request: DecisionRequest): Observable<NoticeCancellationRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.put(`${this.apiUrl}/notice-cancellation-requests/decision`, request, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
      .pipe(
        map(response => new NoticeCancellationRequest(response))
      );
  }

  downloadRequestsCSVExport(): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(`${this.apiUrl}/requests/exportcsv`, 
      { 
        responseType: "blob",
        reportProgress: true,
        observe: "events"
      });      
  }

  logRefund(logRefundRequest: LogRefundRequest): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/late-review-requests/${logRefundRequest.recordNumber}/payments/${logRefundRequest.sourceTransactionId}/log-refund`,
     logRefundRequest, { headers: { 'Authorization': `Bearer ${this.user.token}` } })
      .pipe(
        map(response => new LateReviewRequest(response)));
  }

  updateLateReviewRequestDecisionDate(lateReviewRequestId: number, decisionDate: string): Observable<LateReviewRequest> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.patch(`${this.apiUrl}/late-review-requests/decision/${lateReviewRequestId}/decisionDate/${decisionDate}`, null,   
    { 
      headers: {
        'Authorization': `Bearer ${this.user.token}`
      } 
    }).pipe(
      map(response => new LateReviewRequest(response))
    );
  }
}
