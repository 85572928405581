<div class="d-flex flex-column">                    
    <div class="seizure-recent-activity-summary-header">
        <div>
            All Activity
            <app-access-control
                    [Resource]="Resource.SEIZURE" [Permission]="Permission.VIEW_DRIVER_LOGS">
                <driver-log [getSearchRequestCallback]="getDriverLogSearchRequest" (onSelectionChanged)="onDriverLogsSelectionChanged($event)"></driver-log>
            </app-access-control>
        </div>
        <app-access-control [Resource]="Resource.SEIZURE" [Permission]="Permission.ACTIVITY_NOTE">
            <div class="d-flex justify-content-end align-items-center">
                <div class="add-note-icon">
                    <i class="fas fa-plus-circle"></i>
                </div>
                <div class="add-note-link"  (click)="onAddNoteLinkClick()">Add a note</div>
            </div>
        </app-access-control>
    </div>
   
    <div class="mb-3 seizure-recent-activity-group" >                 
        <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
            <div class="seizure-recent-activity-connector"></div>
            <div *ngFor="let recentActivity of recentActivities; let i=index;"
                class="seizure-recent-activity-container">
                <div class="seizure-recent-activity-dot"
                    [ngClass]="{ 'seizure-recent-activity-dot-submitted': i==recentActivities.length-1}"></div>
                <div class="seizure-recent-activity-header">
                    <div>{{recentActivity.date}}</div>
                    <div class="d-flex">by {{recentActivity.by}} <span class="log-flag" *ngIf="recentActivity.isFromDriverLogs">Log</span></div>
                </div>
                <div class="seizure-recent-activity-title">{{recentActivity.title}}</div>
                <div *ngIf="recentActivity.body != null && isCancelledByLEArequest(recentActivity)"
                    class="seizure-recent-activity-content">
                    This seizure has been cancelled as the result of
                    <a [routerLink]="['/requests', recentActivity.body]" [queryParams]="{type: requestType}">
                        {{recentActivity.body}}
                    </a>
                </div>
                <div *ngIf="recentActivity.body != null && !isCancelledByLEArequest(recentActivity)"
                    class="seizure-recent-activity-content">{{recentActivity.body}}</div>
                <ng-container *ngIf="recentActivity.documents?.length > 0">
                    <div class="seizure-recent-activity-document" *ngFor="let document of recentActivity.documents">
                        <img class="download-file-icon" src="assets/images/download-file.png" />
                        <a (click)="onRecentActivityDocumentNameClick(document)"
                            class="download-file-link">{{document.documentName}}</a>
                    </div>
                </ng-container>
                <app-access-control
                    *ngIf="canModifyNote(recentActivity)"
                    [Resource]="Resource.SEIZURE" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="seizure-recent-activity-edit">
                        <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                        <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete
                        </div>
                    </div>
                </app-access-control>
            </div>
        </ng-container>                                   
    </div>
</div>