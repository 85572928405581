<div class="d-flex flex-column h-100">
    <div class="header">
        <div class="top-header">
            <div class="d-flex">
                Request {{requestNumber}}
                <span class="badge rounded-pill request-status"
                    [class.badge-status-new]="request?.requestStatusTypeId == 1"
                    [class.badge-status-pending]="request?.requestStatusTypeId == 2"
                    [class.badge-status-complete]="request?.requestStatusTypeId == 3"
                    [class.badge-status-cancelled]="request?.requestStatusTypeId == 5">
                        {{localStorageService.getTypeItemDescriptionById(request?.requestStatusTypeId, TypeTable.RequestStatusType)}}
                    </span>
                    <span *ngIf="request?.isRecipientYouth" class="youth-flag">YOUTH</span>
            </div>
            <select [hidden]="requestType == RequestTypes.LEACancellationRequest" id="manageRequest" class="manage-request form-select" (change)="onManageRequestChange($event)">                
                <option value="Manage Request...">Manage Request...</option>
                <option value="Assign To" *ngIf="canAssignAdjudicator">Assign To</option>                   
                <option value="Change Contact Method" *ngIf="canUpdateContactMethod">Change Contact Method</option>                                             
                <option value="Cancel Request" *ngIf="canCancelRequest">Cancel Request</option>                
            </select>
        </div>
    </div>
    <div class="main">
        <div class="summary-section" *ngIf="request != null">            
            <div class="summary-header">Summary</div>
            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/contravention-white.svg" />                      
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Request Type</div>
                        <div class="summary-item-value">{{localStorageService.getTypeItemDescriptionById(requestType, TypeTable.RequestType)}}</div>
                    </div>                    
                </div>               
            </div>            
            <div class="mb-3" *ngIf="requestType != RequestTypes.LEACancellationRequest">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Recipient</div>
                        <div class="summary-item-value">
                            {{ applicantName }}
                        </div>
                    </div>                    
                </div>            
            </div> 
            <div class="mb-3" *ngIf="requestType == RequestTypes.LateReview">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Occurrence Date</div>
                        <div class="summary-item-value">
                            {{request.contraventionOccurrenceDate | date:'mediumDate'}}
                        </div>
                    </div>  
                </div>               
            </div>      
            <div class="mb-3" *ngIf="requestType == RequestTypes.LateReview">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Issuance Date</div>
                        <div class="summary-item-value">
                            {{request.contraventionIssuanceDate | date:'mediumDate'}}
                        </div>
                    </div>  
                </div>               
            </div>    
            <div class="mb-3" *ngIf="requestType == RequestTypes.LEACancellationRequest">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Issuance Date</div>
                        <div class="summary-item-value">
                            {{request.issueDate | date:'mediumDate'}}
                        </div>
                    </div>  
                </div>               
            </div>    
            <div class="mb-3" *ngIf="requestType == RequestTypes.LateSeizureReview">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Seizure Date</div>
                        <div class="summary-item-value">
                            {{request.vehicleSeizureDate | date:'mediumDate'}}
                        </div>
                    </div>  
                </div>               
            </div>      
            <div class="mb-3" *ngIf="requestType != RequestTypes.LEACancellationRequest">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Request Submitted</div>
                        <div class="summary-item-value">
                            {{requestDateTime | date:'mediumDate'}} at {{requestDateTime | date: 'shortTime'}}
                        </div>
                    </div>  
                </div>               
            </div>            
            <div class="mb-3" *ngIf="requestType == RequestTypes.LEACancellationRequest">
                <div class="summary-item-container">
                    <div class="summary-item-icon">
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Recipient</div>
                        <div class="summary-item-value">
                            {{ this.request.recipientName }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="requestType == RequestTypes.LEACancellationRequest">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/contravention-white.svg" />                      
                    </div>
                    <div class="d-flex flex-column">
                        <div *ngIf="contraventionTypeId && seizureTypeId == null" class="summary-item-label">Contravention Type</div>
                        <div *ngIf="(contraventionTypeId && seizureTypeId == SeizureTypes.IRSContraventionSelected) || (seizureTypeId && seizureTypeId != SeizureTypes.IRSContraventionSelected)" class="summary-item-label">Seizure Type</div>
                        <div class="summary-item-value">
                            {{ getNoticeType() }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="requestType != RequestTypes.LEACancellationRequest" class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/adjudicator-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Assigned Adjudicator</div>
                        <div class="summary-item-value">
                            <ng-container *ngIf="!adjudicatorName">
                                Unassigned
                            </ng-container>
                            <ng-container *ngIf="adjudicatorName">
                                {{ adjudicatorName }}
                            </ng-container>                             
                        </div>
                    </div>  
                </div>               
            </div>
            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/chain-link.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Linked files</div>                        
                        <ng-container *ngIf="requestType != RequestTypes.LEACancellationRequest && request.lateReviewRequestItems">
                            <ng-container *ngFor="let item of request.lateReviewRequestItems">
                                <div class="summary-item-value">
                                    <a *ngIf="item.contraventionId" [routerLink]="['/contravention', item.recordNumber]">Contravention {{item.recordNumber}}</a>
                                    <a *ngIf="item.vehicleSeizureId" [routerLink]="['/seizure', item.recordNumber]">Seizure {{item.recordNumber}}</a>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="requestType == RequestTypes.LEACancellationRequest">
                            <ng-container *ngFor="let item of request.noticeCancellationRequestMappings">
                                <div class="summary-item-value">
                                    <a *ngIf="item.contraventionId; else noContraventionId" [routerLink]="['/contravention', item.recordNumber]">Contravention {{item.recordNumber}}</a>
                                    <ng-template #noContraventionId>
                                        <a *ngIf="item.vehicleSeizureId" [routerLink]="['/seizure', item.recordNumber]">Seizure {{item.recordNumber}}</a>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>                    
                </div>            
            </div> 

            <div class="mb-3 divider"></div>
            
            <div class="recent-activity-summary-header">
                Recent Activity
                <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="add-note-icon">
                            <i class="fas fa-plus-circle"></i>
                        </div>
                        <button class="add-note-link"  (click)="onAddNoteLinkClick()">                        
                            Add a note
                        </button>
                    </div>
                </app-access-control>
            </div>
            <div class="mb-3 recent-activity-group">                 
                <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
                    <div class="recent-activity-connector"></div>
                    <div *ngFor="let recentActivity of recentActivities; let i=index;" class="recent-activity-container">
                        <div class="recent-activity-dot" [ngClass]="{ 'recent-activity-dot-submitted': i==0}"></div>
                        <div class="recent-activity-header">
                            <div>{{recentActivity.date}}</div>
                            <div *ngIf="recentActivity.by != null">by {{recentActivity.by}}</div>
                        </div>
                        <div *ngIf="recentActivity.title != null" class="recent-activity-title">{{recentActivity.title}}</div>
                        <div *ngIf="recentActivity.body != null" class="recent-activity-content">{{recentActivity.body}}</div>
                        <ng-container *ngIf="recentActivity.documents?.length > 0">
                            <div class="recent-activity-document" *ngFor="let document of recentActivity.documents">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onEventDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                            </div>
                        </ng-container>
                        <app-access-control
                            *ngIf="canModifyNote(recentActivity)"
                            [Resource]="Resource.REQUEST" [Permission]="Permission.ACTIVITY_NOTE">
                            <div class="recent-activity-edit">
                                <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                                <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                            </div>
                        </app-access-control>
                    </div>                    
                </ng-container>
                
            </div> 
        </div>

        <div class="tabs-container">            
            <div class="tabs">
                <div class="tab-header" (click)="onTabHeaderClick($event)">
                    <div id="tabHeader_RequestDeatils" class="tab-header-item selected">Request Details</div>                    
                    <div id="tabHeader_LawEnforcementSubmissions" class="tab-header-item" *ngIf="isLeaSubmissionsActive">
                        LEA Submissions

                        <div class="alert-icon" *ngIf="request?.hasDocumentsRequiringRedaction == true">
                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                        </div>
                        <div class="warning-icon" *ngIf="request?.hasDocumentsWithPendingSubmissions == true">
                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                        </div>
                    </div>
                    <div  *ngIf="requestType == RequestTypes.LEACancellationRequest && canApproveOrDenyLEARequest" id="tabHeader_ApproveOrDenyRequest" class="tab-header-item">Approve or Deny Request</div>                    
                    <div  *ngIf="requestType != RequestTypes.LEACancellationRequest" id="tabHeader_Decision" class="tab-header-item">Decision</div>
                    <div id="tabHeader_RequestActivity" class="tab-header-item"  (click)="requestActivityTabSelected()">
                        Request Activity
                        <div class="phone-icon" *ngIf="request?.isPhoneContactRequired == true">
                            <i class="fas fa-phone fa-xs"></i>
                        </div>
                    </div>
                    <div class="tab-header-item-fill"></div>
                </div>
                <div class="tab-content">
                    <div id="tab_RequestDeatils" class="tab-content-item selected">
                        <app-request-information [request]="request"
                            [requestType]="requestType"
                            [contravention]="contravention"
                            [isLeaSubmissionsActive]="isLeaSubmissionsActive"
                            (viewReceiptClicked)="onViewReceiptClicked()"
                            (paymentInfoEdited)="refreshRequest($event)"></app-request-information>
                    </div>
                    <div id="tab_LawEnforcementSubmissions" class="tab-content-item" *ngIf="isLeaSubmissionsActive && stopInformation != null">
                        <app-law-enforcement-submissions #leaSubmissions
                            [lateReviewRequest]="request"
                            [contravention]="contravention"
                            [applicantName]="applicantName"
                            [stopInformation]="stopInformation"></app-law-enforcement-submissions>
                    </div>
                    <div id="tab_ApproveOrDenyRequest" class="tab-content-item" *ngIf="requestType == RequestTypes.LEACancellationRequest">
                        <app-approve-or-deny-request [request]="request" [requestType]="requestType" (decisionSubmitted)="refreshRequest($event)"></app-approve-or-deny-request>
                    </div>
                    <div id="tab_Decision" class="tab-content-item" *ngIf="requestType != RequestTypes.LEACancellationRequest">
                        <app-decision-information 
                            [request]="request" 
                            [requestType]="requestType"
                            (decisionSubmitted)="refreshRequest($event)"></app-decision-information>
                    </div>
                    <div id="tab_RequestActivity" class="tab-content-item">
                        <request-activity #requestActivity [request]="request" [requestType]="requestType" (activityNoteSubmitted)="refreshRequest($event)"></request-activity>
                    </div>                    
                </div>
            </div>               
        </div>                
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>