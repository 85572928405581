<div class="d-flex flex-column h-100">
    <div class="header">
        Judicial Reviews
    </div>
    <div class="main">
        <div class="filter-section">            
            <div class="filter-by-header">Filter by:</div>
            <div class="mb-3">
                <div class="filter-type-header">Contravention Type</div>
                <div class="filter-type-container">                    
                    <select class="form-select w-75" id="contraventionTypesDropdown" (change)="onContraventionTypeChange($event)"
                     [(ngModel)]="selectedContraventionTypeId">
                     <option class="contravention-type-option" value="0">All</option>
                     <option class="contravention-type-option" value="1">IRS Review</option>
                     <option class="contravention-type-option" value="2">Seizure Review</option>
                    </select>                                        
                </div>
            </div>
            <div class="mb-3">
                <div class="filter-type-header">Stay Order</div>
                <div class="filter-type-container">                    
                    <select class="form-select w-75" id="stayOrderDropdown" (change)="onStayOrderChange($event)"
                     [(ngModel)]="selectedStayOrderId">
                        <option class="contravention-type-option" value="0">All</option>
                        <option class="contravention-type-option" value="1">No</option>
                        <option class="contravention-type-option" value="2">Yes</option>
                    </select>                                        
                </div>
            </div>
            
            <div class="filter-type-header d-flex align-items-center justify-content-between">
                Outcome
            </div>
            <div class="filter-type-container">
                <div *ngFor="let judicialReviewDecisionType of judicialReviewDecisionTypes; let i = index;">
                    <div id="checkbox_{{judicialReviewDecisionType.id}}"  class="action-checkbox-container" (click)="onCheckboxClick(judicialReviewDecisionType.id)">   
                        <div class="action-checkbox">                            
                        </div>
                        <div class="action-label">{{ judicialReviewDecisionType.name }}</div>
                    </div>
                </div>
            </div>

            <div class="clear-filters disabled" (click)="onClearAllFiltersClick($event)">Clear All filters</div>     
        </div>
        <div class="w-100 ps-4 pe-4">
            <div class="d-flex justify-content-between align-items-center judicial-review-container">
                <div class="search-box">
                    <input id="searchTextbox" type="text" class="form-control" placeholder="Search by name or number"
                        (keydown)="onSearchTextboxKeyDown($event)" [(ngModel)]="searchText" />
                    <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick()"><i
                            class="fas fa-search fa-lg"></i></button>
                </div>
                <div class="d-flex align-items-center">
                    <div class="paging align-self-end">
                        <label for="pageSizeTop" class="page-size-label">Page Size</label>
                        <select class="form-select page-size-select" id="pageSizeTop"
                            (change)="onPageSizeChange($event)">
                            <option value="10" [selected]="+pageSize == 10">10</option>
                            <option value="25" [selected]="+pageSize == 25">25</option>
                            <option value="50" [selected]="+pageSize == 50">50</option>
                            <option value="100" [selected]="+pageSize == 100">100</option>
                        </select>
                        <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg" />
                        <div class="previous-link" [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                            (click)="onPreviousLinkClick($event)">Previous</div>

                        <ng-container *ngFor="let page of pageNumbers; let i=index;">
                            <div id="pageNumberTop_{{page}}" class="page-number"
                                [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                                (click)="onPageNumberClick($event, page)">{{page}}</div>
                        </ng-container>

                        <ng-container *ngIf="totalPages > 5 && !isLastRange">
                            <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                            <div id="pageNumberTop_{{totalPages}}" class="page-number ms-0"
                                [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                                (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                        </ng-container>

                        <div class="next-link" [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                            (click)="onNextLinkClick($event)">Next</div>
                        <img class="next-link-icon" src="assets/images/paging-left-arrow.svg" />
                    </div>
                    <div class="export-list" (click)="onExportListClick($event)">
                        <img class="export-list-icon" src="assets/images/export-list.svg" />
                        <div class="ms-2">Export List</div>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-3">
                <table id="judicialReviewsTable" class="table table-hover">
                    <thead>
                        <tr>
                            <th style="width: 128px !important;">
                                <div class="d-flex">
                                    <span>JR Served</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('servedDate')"></i></span>
                                </div>
                            </th>
                            <th style="width: 85px !important;">
                                <div class="d-flex">
                                    <span>JR Review<br />Number</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('judicialReviewNumber')"></i></span>
                                </div>
                            </th>
                            <th style="width: 128px !important; position: relative; padding-left: 10px;">
                                <div class="d-flex">
                                    <span> Court Action<br />Number</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('courtActionNumber')"></i></span>
                                </div>
                            </th>
                            <th style="width: 155px !important; padding-left: 10px;">
                                <div class="d-flex">
                                    <span>Original Review<br />Number</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('reviewNumber')"></i></span>
                                </div>
                            </th>
                            <th style="width: 135px !important; padding-left: 8px;">
                                <div class="d-flex">
                                    <span>T & C Date</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('termsAndConditionsDate')"></i></span>
                                </div>
                            </th>
                            <th
                                style="width: 83px !important; padding-left: 6px !important; padding-right: 20px !important;">
                                <div class="d-flex">
                                    <span>Recipient</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('recipient')"></i></span>
                                </div>
                            </th>
                            <th style="width: 148px !important; padding-left: 0px;">
                                <div class="d-flex">
                                    <span>Order to Stay</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('hasStayOrder')"></i></span>
                                </div>
                            </th>
                            <th style="width: 170px; padding-left: 0px; position: relative;">
                                <div class="d-flex">
                                    <span>JR Outcome</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('judicialReviewDecisionTypeId')"></i></span>
                                </div>
                            </th>
                            <th style="width: 170px; padding-left: 0px; position: relative;">
                                <div class="d-flex">
                                    <span>JR Decision Date</span>
                                    <span class="sort-icon"><i class="fas fa-sort"
                                            (click)="onSortIconClick('judicialReviewDecisionDate')"></i></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredJudicialReviews.length > 0">
                        <tr *ngFor="let judicialReview of filteredJudicialReviews">
                            <td>{{judicialReview?.servedDate | date:'mediumDate'}}</td>
                            <td><a class="judicial-review-number"
                                    [routerLink]="['/judicial-reviews', judicialReview?.judicialReviewNumber]">{{judicialReview?.judicialReviewNumber}}</a>
                            </td>
                            <td>{{judicialReview?.courtActionNumber}}</td>
                            <td style="padding-left: 10px;">{{judicialReview?.originalReviewNumber}}</td>
                            <td style="padding-left: 10px;">{{judicialReview?.termsAndConditionsDate |
                                date:'mediumDate'}}</td>
                            <td style="padding-left: 8px;">{{judicialReview?.recipientName}}</td>
                            <td style="padding-left: 6px !important;">
                                <ng-container *ngIf="judicialReview?.hasStayOrder">
                                    Yes
                                </ng-container>
                                <ng-container *ngIf="!judicialReview?.hasStayOrder">
                                    -
                                </ng-container>
                            </td>
                            <td style="padding-left: 0px !important;">
                                {{localStorageService.getTypeItemDescriptionById(judicialReview?.judicialReviewDecisionTypeId,
                                TypeTable.JudicialReviewDecisionType)}}
                            </td>
                            <td style="padding-left: 10px;">{{judicialReview?.judicialReviewDecisionDate |
                                date:'mediumDate'}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="filteredJudicialReviews.length === 0">
                        <tr>
                            <td colspan="8">No judicial reviews found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end">
                <div class="paging">
                    <label for="pageSizeBottom" class="page-size-label">Page Size</label>
                    <select class="form-select page-size-select" id="pageSizeBottom"
                        (change)="onPageSizeChange($event)">
                        <option value="10" [selected]="+pageSize == 10">10</option>
                        <option value="25" [selected]="+pageSize == 25">25</option>
                        <option value="50" [selected]="+pageSize == 50">50</option>
                        <option value="100" [selected]="+pageSize == 100">100</option>
                    </select>

                    <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg" />
                    <div class="previous-link" [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                        (click)="onPreviousLinkClick($event)">Previous</div>

                    <ng-container *ngFor="let page of pageNumbers; let i=index;">
                        <div id="pageNumberBottom_{{page}}" class="page-number"
                            [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                            (click)="onPageNumberClick($event, page)">{{page}}</div>
                    </ng-container>

                    <ng-container *ngIf="totalPages > 5 && !isLastRange">
                        <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                        <div id="pageNumberBottom_{{totalPages}}" class="page-number ms-0"
                            [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                            (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                    </ng-container>

                    <div class="next-link" [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                        (click)="onNextLinkClick($event)">Next</div>
                    <img class="next-link-icon" src="assets/images/paging-left-arrow.svg" />
                </div>
            </div>

        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
</div>