<div class="main-content">
  <div class="content-header">
    <!-- <div class="w-100 mb-4">Adjudicator Management</div> -->
    <div class="d-flex">
      <div class="d-flex flex-grow-1 align-items-center">
        <div class="cal-date-nav" 
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="loadResourceView()"
          [excludeDays]="excludeDays">
          <img src="assets/images/chevron-left.svg">
        </div>
        <div class="cal-date-nav"
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="loadResourceView()">
          <img class="calendar" src="assets/images/calendar-white.svg">
        </div>
        <div class="cal-date-nav"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="loadResourceView()"
          [excludeDays]="excludeDays">
          <img src="assets/images/chevron-right.svg">
        </div>
        <div class="ms-3">
          {{ viewDate | calendarDate: view + 'ViewTitle':locale }}
        </div>
      </div>
      <div>
        <div class="btn-group me-2">
          <div
            class="btn btn-primary"
            (click)="onViewChange(CalendarView.Week)"
            [class.active]="view === 'week'"
          >
            Week
          </div>
          <div
            class="btn btn-primary"
            (click)="onViewChange(CalendarView.Day)"
            [class.active]="view === 'day'"
          >
            Day
          </div>
        </div>

        <app-access-control [Resource]="Resource.DASHBOARD" [Permission]="Permission.AVAILABILITY_REPORT">
          <button
            class="button-primary me-2"
            (click)="onAvailabilityReportClick()"
          >
            Availability Report
          </button>
        </app-access-control>
        <app-access-control [Resource]="Resource.DASHBOARD" [Permission]="Permission.MANAGE_REVIEWS_EDIT">
          <button
          class="button-primary custom-button"
          (click)="onNewEventClick()"
        >
          New Event
        </button>
        </app-access-control>
      </div>
    </div>
  </div>
  <div class="cal-time-line" [ngSwitch]="view">
      <resource-time-line-view
        *ngSwitchCase="'day'"
        [viewDate]="viewDate"
        [events]="events"
        [resources]="resources"
        [dayStartHour]="9"
        [dayEndHour]="15"
        (eventClicked)="onEventClicked($event)"
      >
      </resource-time-line-view>
      <resource-time-line-week-view
        *ngSwitchCase="'week'"
        [excludeDays]="excludeDays"
        [viewDate]="viewDate"
        [events]="events"
        [resources]="resources"
        [dayStartHour]="9"
        [dayEndHour]="15"
        (eventClicked)="onEventClicked($event)"
      >
      </resource-time-line-week-view>
  </div>
  <div class="d-flex flex-grow-1 align-items-center legend">
    <span class="irs-review-written">
      <img src="assets/images/pencil-black.svg"/>
      IRS Review Written
    </span>
    <span class="irs-review-oral">
      <img src="assets/images/camera-black.svg"/>
      IRS Review Oral
    </span>
    <span class="seizure-review">
      <img src="assets/images/pencil-black.svg"/>
      Seizure Review
    </span>
    <span class="admintime-event">
      Admin Time
    </span>
    <span class="unavailable-event">
      Unavailable
    </span>
  </div>
</div>
<div class="comments-sidebar" *ngIf="showEvent" [@slideInOut]>
  <span
    class="close-button"
    (click)="onCloseEventClick()"
    iesIconButton
    aria-label="Collapse Comments"
    iesIconButton
    data-cy="comments-collapse-panel-button"
  >
  <i class="far fa-times-circle"></i>
  </span>
  <app-schedule-event *ngIf="componentID == 1" #scheduleEvent [showAdjudicator]="true" (close)="onEventClose($event)"></app-schedule-event>
  <app-availability-report *ngIf="componentID == 2" (close)="onEventClose()"></app-availability-report>
</div>