import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AvailabilityReportRequest } from '@apis/shared/models/availability-report-request.model';

@Injectable()
export class ReportService {
    private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;

    constructor(private readonly httpClient: HttpClient){}

    downloadFinancialJournalReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/finance/journal/export`,
        {
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    getAvailabilityReport(availabilityReportRequest: AvailabilityReportRequest): Observable<HttpEvent<Blob>> {
      return this.httpClient.post(`${this.apiUrl}/reports/schedules/availability`, availabilityReportRequest,
        {
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadContraventionsExtractReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/contraventions-extract`,
        {
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadSeizuresExtractReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/seizures-extract`,
        {
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadReviewCancellationsReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/review-cancellations`,
        {
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadReviewExtractReport(startdate: string, enddate: string, reportType?: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/review-extract`,
        {
          params: {
            startdate: startdate,
            enddate: enddate,
            reportType
        },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadReviewRepresentationsReport(): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/review-representations`,
        {
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }
}
