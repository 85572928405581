<div class="d-flex flex-column" *ngIf="request">
    <div class="heading">Applicant Information</div>
    <app-section-details sectionTitle="" [sectionDetails]="applicantInformation"></app-section-details>

    <ng-container *ngIf="request?.isSubmittedByRegistry">
        <div class="heading">Registry Information</div>
        <app-section-details sectionTitle="" [sectionDetails]="registryInformation"></app-section-details>
    </ng-container>

    <div *ngIf="!noticeCancellationRequest" class="heading">Reason for Request</div>
    <div *ngIf="noticeCancellationRequest" class="heading">Request Details</div>    

    <div *ngIf="lateReviewRequest && requestType == RequestTypes.LateReview">
        <div class="row" *ngFor="let item of lateReviewRequest?.lateReviewRequestItems">
            <div *ngIf="item.contraventionId" class="col-12">                
                <div class="label">
                    Contravention
                </div>
                <div class="value pb-0">
                    {{item.recordNumber}}
                </div>
                <div class="value pt-0">
                    <ng-container *ngIf="getChargeDescription(item.contraventionTypeId)">                   
                        <span>{{getChargeCode(item.contraventionTypeId)}} - {{getChargeDescription(item.contraventionTypeId)}}</span>       
                    </ng-container>    
                    <ng-container *ngIf="!getChargeDescription(item.contraventionTypeId)">                   
                        <span>{{getChargeCode(item.contraventionTypeId)}}</span>       
                    </ng-container>           
                </div>
                <div *ngIf="item.secondaryContraventionTypeId" class="value pt-0">
                    <ng-container *ngIf="getChargeDescription(item.secondaryContraventionTypeId)">                   
                        <span>{{getChargeCode(item.secondaryContraventionTypeId)}} - {{getChargeDescription(item.secondaryContraventionTypeId)}}</span>       
                    </ng-container>    
                    <ng-container *ngIf="!getChargeDescription(item.secondaryContraventionTypeId)">                   
                        <span>{{getChargeCode(item.secondaryContraventionTypeId)}}</span>       
                    </ng-container>           
                </div>
                <div *ngIf="item.tertiaryContraventionTypeId" class="value pt-0">
                    <ng-container *ngIf="getChargeDescription(item.tertiaryContraventionTypeId)">                   
                        <span>{{getChargeCode(item.tertiaryContraventionTypeId)}} - {{getChargeDescription(item.tertiaryContraventionTypeId)}}</span>       
                    </ng-container>    
                    <ng-container *ngIf="!getChargeDescription(item.tertiaryContraventionTypeId)">                   
                        <span>{{getChargeCode(item.tertiaryContraventionTypeId)}}</span>       
                    </ng-container>           
                </div>                
            </div>
        </div>
        <div class="label">
            Grounds for Review
        </div>
        <div class="value">
            {{lateReviewRequest?.requestReason}}
        </div>
        <div class="label mt-3" [innerHTML]="termsConditionsStatement"></div>
        <div class="value pb-0">
            Yes
        </div>
    </div>

    <div *ngIf="lateReviewRequest && requestType == RequestTypes.LateSeizureReview">
        <app-section-details sectionTitle="" [sectionDetails]="reasonForRequestInformation"></app-section-details>
        <div class="label mt-3" [innerHTML]="termsConditionsStatement"></div>
        <div class="value pb-0">
            Yes
        </div>
    </div>

    <div *ngIf="noticeCancellationRequest">
        <app-section-details sectionTitle="" [sectionDetails]="reasonForRequestInformation"></app-section-details>
    </div>    

    <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.VIEW_SUPPORTING_DOCUMENTS">
        <div *ngIf="this.documentRefTypeNumber && noticeCancellationRequest">
            <div class="heading-table">LEA Uploaded Documents</div>
            <div class="d-flex mb-3">        
                <table class="table">
                    <thead>
                        <tr>                            
                            <th>Filename</th>
                            <th>Date Submitted</th>                            
                            <th>File Size</th>                                          
                        </tr>
                    </thead>
                                
                    <tbody *ngIf="supportingDocuments?.length > 0">
                        
                        <tr *ngFor="let document of supportingDocuments">                            
                            <td>                       
                                <div class="d-flex align-items-center">
                                    <img class="download-file-icon" src="assets/images/download-file.png" />
                                    <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                                </div>                                 
                            </td>
                            <td>{{document.uploadedDate | date: "mediumDate"}}</td>                            
                            <td>{{document.documentSize}}</td>              
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="supportingDocuments?.length == 0">
                        <tr>
                            <td colspan="5">No documents found.</td>
                        </tr>
                    </tbody> 
                </table>        
            </div>
        </div>
    </app-access-control>

    <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.VIEW_SUPPORTING_DOCUMENTS">
        <div *ngIf="this.documentRefTypeNumber && lateReviewRequest">
            <div class="heading-table">Applicant-Submitted Supporting Documents</div>
            <div class="d-flex mb-3">        
                <table class="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Filename</th>
                            <th>Date Submitted</th>
                            <th>Format</th>
                            <th>File Size</th>                                          
                        </tr>
                    </thead>
                                
                    <tbody *ngIf="supportingDocuments?.length > 0">
                        
                        <tr *ngFor="let document of supportingDocuments">
                            <td>
                                <div>{{getDocumentTypeName(document.documentTypeId)}}</div>                                              
                            </td>
                            <td>                       
                                <div class="d-flex align-items-center">
                                    <img class="download-file-icon" src="assets/images/download-file.png" />
                                    <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                                </div>                                 
                            </td>
                            <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                            <td>{{document.documentExtension}}</td>
                            <td>{{document.documentSize}}</td>              
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="supportingDocuments?.length == 0">
                        <tr>
                            <td colspan="5">No applicant-submitted supporting documents found.</td>
                        </tr>
                    </tbody> 
                </table>        
            </div>
            <app-access-control [Resource]="Resource.SUPPORTING_DOCUMENT" [Permission]="Permission.ADD_ADDITIONAL_DOCUMENT">
                <button class="btn button-secondary-outline float-end mb-4" (click)="AddOptionalDocument()">Add Additional Document</button>
            </app-access-control>
        </div>
    </app-access-control>

    <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.VIEW_SUPPORTING_DOCUMENTS">
        <div *ngIf="!isLeaSubmissionsActive && this.documentRefTypeNumber && lateReviewRequest && requestType == RequestTypes.LateReview && contravention">
            <div class="heading-table">LEA Supporting Documents</div>
            <div class="d-flex mb-3">        
                <table class="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Filename</th>
                            <th>Published Date</th>
                            <th>Uploaded Date</th>
                            <th>Submitter</th>
                            <th>Format</th>
                            <th>Size</th>                                          
                        </tr>
                    </thead>
                                
                    <tbody>
                        <tr *ngFor="let document of getNAPDocuments()">
                            <td>
                                <div>{{getDocumentTypeName(document.documentTypeId)}}</div>                                              
                            </td>
                            <td>              
                                <div *ngIf="document.documentName == null else documentAvailable" class="d-flex align-items-center">
                                    <div>Pending Submission</div>
                                </div>         
                                <ng-template #documentAvailable>
                                    <div class="d-flex align-items-center">
                                        <img class="download-file-icon" src="assets/images/download-file.png" />
                                        <a (click)="onNAPDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                                    </div>
                                </ng-template>
                            </td>
                            <td>{{document.publishedDate | date: "mediumDate"}}</td>
                            <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                            <td>{{document.uploadedBy }}</td>
                            <td>{{document.documentExtension}}</td>
                            <td>{{document.documentSize}}</td>              
                        </tr>                      
                    </tbody>
                </table>        
            </div>
        </div>
    </app-access-control>

    <form #paymentHistoryForm="ngForm">
        <ng-container *ngIf="lateReviewRequest">
            <div class="heading">Request Payment Information</div>
            <div class="d-flex mb-3">        
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 220px;">Payment Method</th>
                            <th style="width: 200px;">Transaction ID</th>
                            <th>
                                <div class="payment-amount-column">Amount</div>
                            </th>   
                            <th class="receipt-cell">Action</th>
                            <th class="refund-cell" *ngIf="canLogRefund">Refund</th>
                        </tr>
                    </thead>
                                
                    <tbody *ngIf="lateReviewRequest?.payments != null && lateReviewRequest?.payments?.length > 0">
                        
                        <tr *ngFor="let payment of lateReviewRequest.payments">
                            <td *ngIf="lateReviewRequest.isSubmittedByRegistry">Registry</td>
                            <td *ngIf="!lateReviewRequest.isSubmittedByRegistry">{{ payment.cardType | cardtype }} ending in {{ payment.cardLastFourDigits }}</td>
                            
                            <td *ngIf="lateReviewRequest.isSubmittedByRegistry && !payment.edited">{{ payment.financialTransaction.transactionId }}</td>
                            <td *ngIf="!lateReviewRequest.isSubmittedByRegistry && !payment.edited">{{ payment.financialTransactionId | transactionid }}</td>
                            <td *ngIf="payment.edited">
                                <div class="row">
                                    <div class="col-11">
                                        <input class="form-control" required [appTransactionIdFormatter]="true" [appTransactionIdValidator]="true" #transactionIDControl="ngModel" type="text" maxlength="19" id="transactionID" name="transactionID" placeholder="Transaction ID"  [(ngModel)]="paymentDataEditRequest.transactionId" [ngClass]="{'is-invalid': (transactionIDControl.touched || isSubmitClicked) && transactionIDControl.invalid}">
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div class="payment-amount-column">{{payment.amount | currency}}</div>
                            </td> 
                            <td class="receipt-cell">
                                <a class="receipt-link" *ngIf="!lateReviewRequest.isSubmittedByRegistry"
                                    (click)="onViewReceiptClick()">Download Receipt</a>
                                <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.DATA_EDITS">
                                    <div class="col d-flex justify-content-end">
                                        <div *ngIf="lateReviewRequest.isSubmittedByRegistry && !payment.edited" class="data-edit-button" (click)="onEditPaymentClick(payment)"></div>
                                        <div *ngIf="lateReviewRequest.isSubmittedByRegistry && payment.edited" class="data-edit-ok-button" (click)="onEditPaymentSaveClick(paymentHistoryForm.valid)"></div>
                                        <div *ngIf="lateReviewRequest.isSubmittedByRegistry && payment.edited" class="data-edit-cancel-button" (click)="onEditPaymentCancelClick(payment)"></div>
                                    </div>
                                </app-access-control>
                            </td>
                            <td class="refund-cell" *ngIf="canLogRefund">
                                <div *ngIf="payment.refundStatus == RefundStatus.Completed"> Completed </div>
                                <div class="log-refund-link" *ngIf="payment.refundStatus == RefundStatus.Refundable" (click)="onLogRefundClick(payment)">Log Refund</div>
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="lateReviewRequest?.payments == null || lateReviewRequest?.payments?.length == 0">
                        <tr>
                            <td colspan="5">No payments found.</td>
                        </tr>
                    </tbody> 
                </table>        
            </div>
        </ng-container>
    </form>
</div>