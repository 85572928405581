
<div class="d-flex flex-column h-100">    
    <div class="header">
        <div class="top-header">
            <div class="d-flex">Review {{reviewNumber}} 
                <div class="review-status" [ngClass]="getReviewStatusTypeCssClass(review?.reviewStatusTypeId)">
                    {{getReviewStatusTypeName(review?.reviewStatusTypeId)}}                
                </div>
                <span *ngIf="isRecipientYouth" class="youth-flag">YOUTH</span>
                <div *ngIf="isAnotherSeizureExists" class="additional-seizure-info">
                    <img class="additional-seizure-info-flag" src="assets/images/triangular-flag.svg" />
                    {{ anotherSeizureTypeId == SeizureTypes.IRSContraventionSelected? "IRS Seizure (" + anotherSeizureStatus + ")": "Suspended Driver Seizure (" + anotherSeizureStatus + ")" }}
                </div>
            </div>
            <select id="manageReview" class="manage-review form-select" (change)="onManageReviewChange($event)">
                <option value="Manage Review...">Manage Review...</option>
                <option value="Change Status" *ngIf="canChangeReviewStatus">Change Status</option>
                <option value="Reassign Adjudicator" *ngIf="canReassignAdjudicator">Reassign Adjudicator</option>                
                <option value="Change Contact Method" *ngIf="canChangeContactMethod">Change Contact Method</option>
                <option value="Change Review Method" *ngIf="canChangeReviewMethod">Change Review Method</option>
                <option value="Reschedule Review" *ngIf="canRescheduleReview">Reschedule Review</option>
                <option value="Change Decision Due Date" *ngIf="canChangeDecisionDueDate && this.review?.reviewTypeId != ReviewTypes.SuspendedDriverVehicleSeizureReview 
                    && this.review?.reviewTypeId != ReviewTypes.ThirdPartySeizureReview">Change Decision Due Date</option>
                <option value="Cancel Review" *ngIf="canCancelReview">Cancel Review</option>
                <option value="Judicial Review Served" *ngIf="CanServeJudicialReview()">Judicial Review Served</option>
            </select>
        </div>

        <div *ngIf="review?.hasDocumentsWithPendingSubmissions" class="review-warning">
            <div class="warning-icon">                
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="flex-fill ps-4">Pending submission.</div>
            <a  (click)="onViewClick()">View</a>
        </div>
        
        <div class="review-timeline">
            <div class="review-timeline-state-container">
                <div class="review-timeline-state new">NEW</div>
                <div class="review-timeline-state scheduling-prep">SCHEDULING & PREP</div>
                <div class="review-timeline-state pending-review">PENDING REVIEW</div>
                <div class="review-timeline-state pending-decision">PENDING DECISION</div>
                <div class="review-timeline-state complete">COMPLETE</div>
            </div>
            <div class="review-timeline-event-container">
                <div *ngIf="!isLateReviewRequestApproved && (review?.reviewTypeId == ReviewTypes.IrsReview || review?.reviewTypeId == ReviewTypes.TrafficReview || review?.reviewTypeId == ReviewTypes.AteReview)" class="review-timeline-event application-received">
                    Issuance Date
                    <div>{{contravention?.issueDate | date:"mediumDate"}}</div>
                </div>
                <div *ngIf="!isLateReviewRequestApproved && (review?.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview || review?.reviewTypeId == ReviewTypes.ThirdPartySeizureReview)" class="review-timeline-event application-received">
                    Seizure Date
                    <div>{{vehicleSeizure?.seizureDate | date:"mediumDate"}}</div>
                </div>
                <div *ngIf="isLateReviewRequestApproved" class="review-timeline-event application-received">
                    Late Review Approved Date
                    <div>{{lateReviewRequestApprovalDate | date:"mediumDate"}}</div>
                </div>

                <div class="review-timeline-event review-date">
                    Review Date
                    <div *ngIf="reviewDateTime != null">{{reviewDateTime | date:"mediumDate"}} at {{reviewDateTime | date:"HH:mm"}}</div>
                    <div *ngIf="reviewDateTime == null">Unschedule</div>
                </div>
                <div class="review-timeline-event decision-due-date" *ngIf="review?.reviewTypeId != ReviewTypes.SuspendedDriverVehicleSeizureReview && review?.reviewTypeId != ReviewTypes.ThirdPartySeizureReview && review?.decisionDueDate">
                    Decision Due Date
                    <div>{{review?.decisionDueDate | date:"mediumDate"}}</div>
                </div>
            </div>
        </div>

    </div>
    <div class="main">
        <div class="summary-section" *ngIf="review != null">            
            <div class="summary-header">Summary</div>                
            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/user-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">{{review?.reviewTypeId == ReviewTypes.ThirdPartySeizureReview? "Applicant" : "Recipient"}}</div>
                        <div class="summary-item-value">{{representationName}}</div>
                    </div>                    
                </div>            
            </div> 
            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/contravention-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Review Disputed</div>
                        <div class="summary-item-value">
                            <div>{{reviewTypeName}}</div>
                            <a *ngIf="review?.reviewTypeId != ReviewTypes.ThirdPartySeizureReview && contravention != null" [routerLink]="['/contravention', contravention?.contraventionNumber]">Contravention {{contravention?.contraventionNumber}}</a>
                            <a *ngIf="vehicleSeizure != null" [routerLink]="['/seizure', vehicleSeizure?.seizureNumber]">Seizure {{vehicleSeizure?.seizureNumber}}</a>                            
                        </div>
                    </div>                    
                </div>             
            </div> 
            
            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Application Received</div>
                        <div class="summary-item-value">{{reviewRequestDateTime | date:"mediumDate"}}</div>                                          
                    </div>  
                </div>       
            </div>

            <div class="mb-3" *ngIf="review?.reviewTypeId != ReviewTypes.ThirdPartySeizureReview && review?.reviewDueDate">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/calendar-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Day 21 Deadline</div>
                        <div class="summary-item-value">{{review?.reviewDueDate | date:"mediumDate"}}</div>                                          
                    </div>  
                </div>       
            </div>

            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/adjudicator-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Assigned To</div>
                        <div class="summary-item-value">
                            <ng-container *ngIf="review?.adjudicatorLastName">
                                {{review?.adjudicatorLastName}}<span *ngIf="review?.adjudicatorFirstName">, {{review?.adjudicatorFirstName}}</span>
                            </ng-container>
                            <ng-container *ngIf="!review?.adjudicatorLastName">
                                Unassigned
                            </ng-container>
                        </div>
                    </div>  
                </div>               
            </div>            
            <div class="mb-3">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <img src="assets/images/oral-hearing-white.svg" />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Review Method</div>
                        <div class="summary-item-value">{{reviewMethodTypeName}}</div>                                          
                    </div>  
                </div>       
            </div>  
            <div class="mb-3" *ngIf="review?.isAccompainedByInterpreter">
                <div class="summary-item-container">
                    <div class="summary-item-icon">     
                        <i class="far fa-comment-alt fa-lg"></i>                        
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Interpreter</div>
                        <div class="summary-item-value">Attending</div>
                    </div> 
                </div>
            </div>

            <div class="mb-3" *ngIf="review?.linkedThirdPartyReviewNumber != null">
                <div class="summary-item-container">
                    <div class="summary-item-icon">                        
                        <i class="fas fa-link fa-lg"></i>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="summary-item-label">Linked Files</div>                        
                        <div class="summary-item-value">
                            <a [routerLink]="['/review', review?.linkedThirdPartyReviewNumber]">Review {{review?.linkedThirdPartyReviewNumber}}</a>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="summary-section-line"></div>

            <div class="recent-activity-summary-header">
                Recent Activity
                <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.ACTIVITY_NOTE">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="add-note-icon">
                            <i class="fas fa-plus-circle"></i>
                        </div>
                        <div class="add-note-link"  (click)="onAddNoteLinkClick()">Add a note</div>
                    </div>
                </app-access-control>
            </div>
            <div class="mb-3 recent-activity-group">                 
                <ng-container *ngIf="recentActivities != null && recentActivities.length > 0">
                    <div class="recent-activity-connector"></div>
                    <div *ngFor="let recentActivity of recentActivities; let i=index;" class="recent-activity-container">
                        <div class="recent-activity-dot" [ngClass]="{ 'recent-activity-dot-submitted': i==0}"></div>
                        <div class="recent-activity-header">
                            <div>{{recentActivity.date}}</div>
                            <div>by {{recentActivity.by}}</div>
                        </div>
                        <div class="recent-activity-title">{{recentActivity.title}}</div>
                        <div *ngIf="recentActivity.body != null" class="recent-activity-content">{{recentActivity.body}}</div>
                        <ng-container *ngIf="recentActivity.documents?.length > 0">
                            <div class="recent-activity-document" *ngFor="let document of recentActivity.documents">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onEventDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                            </div>
                        </ng-container>
                        <app-access-control 
                            *ngIf="canModifyNote(recentActivity)"
                            [Resource]="Resource.REVIEW" [Permission]="Permission.ACTIVITY_NOTE">
                            <div class="recent-activity-edit">
                                <div class="edit-note-link" (click)="onEditNoteLinkClick(recentActivity.id)">Edit</div>
                                <div class="edit-note-link" (click)="onDeleteNoteLinkClick(recentActivity.id)">Delete</div>
                            </div>
                        </app-access-control>
                    </div>                    
                </ng-container>                                        
            </div> 
        </div>

        <div class="tabs-container">            
            <div class="tabs">
                <div class="tab-header" (click)="onTabHeaderClick($event)">
                    <div id="tabHeader_ApplicantSubmissions" class="tab-header-item selected">Applicant Submissions</div>
                    <div id="tabHeader_LawEnforcementSubmissions" class="tab-header-item">
                        LEA Submissions
                        
                        <div class="alert-icon" *ngIf="review?.hasDocumentsRequiringRedaction == true">                
                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                        </div>
                        <div class="warning-icon" *ngIf="review?.hasDocumentsWithPendingSubmissions == true">                
                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                        </div>                                                
                    </div>
                    <div id="tabHeader_Review" class="tab-header-item">
                        Review
                        <div class="closure-icon" *ngIf="review?.isActionRequired">                
                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                        </div>
                    </div>
                    <div id="tabHeader_Decision" class="tab-header-item">Decision</div>

                    <app-access-control [Resource]="Resource.REVIEW" [Permission]="Permission.REVIEW_ACTIVITY"
                        class="d-flex">
                        <div id="tabHeader_ReviewActivity" class="tab-header-item" (click)="reviewActivityTabSelected()" >
                                Review Activity
                            <div class="phone-icon" *ngIf="review?.isPhoneContactRequired == true">
                                <i class="fas fa-phone fa-xs"></i>
                            </div>                                           
                        </div>
                    </app-access-control>

                    <div class="tab-header-item-fill"></div>
                </div>
                <div class="tab-content">
                    <div id="tab_ApplicantSubmissions" class="tab-content-item selected">
                        <applicant-submissions 
                            [review]="review"
                            [applicantName]="applicantName"
                            [isRecipientYouth]="isRecipientYouth"
                            (viewReceiptClicked)="onViewReceiptClicked()"
                            (reviewInfoEdited)="refreshReview($event)">
                        </applicant-submissions>
                    </div>
                    <div id="tab_LawEnforcementSubmissions" class="tab-content-item">
                        <app-law-enforcement-submissions #leaSubmissions
                            [review]="review"
                            [contravention]="contravention"
                            [vehicleSeizure]="vehicleSeizure"
                            [applicantName]="applicantName"
                            [stopInformation]="stopInformation"></app-law-enforcement-submissions>
                    </div>
                    <div id="tab_Review" class="tab-content-item">
                        <review [review]="review" (outcomeUpdated)="refreshReview($event)" 
                            (documentPublished)="refreshReview($event)"
                            (fileClosureCompleted)="refreshReview($event)"></review>
                    </div>
                    <div id="tab_Decision" class="tab-content-item">
                        <decision 
                            [contravention]="contravention"
                            [review]="review"
                            (decisionSubmitted)="refreshReview($event)"></decision>
                    </div>
                    <div id="tab_ReviewActivity" class="tab-content-item">
                        <review-activity #reviewActivity [review]="review" [contravention]="contravention" [vehicleSeizure]="vehicleSeizure" (activityNoteSubmitted)="refreshReview($event)"></review-activity>
                    </div>
                </div>
            </div>               
        </div>                
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>
