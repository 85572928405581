<div class="d-flex flex-column mb-4">
    <div class="heading">Decision Information</div>
    <div class="d-flex justify-content-between">
        <div class="d-flex flex-row">
            <img src="assets/images/calendar-blue.svg" class="icon" />
            <div class="ms-3">
                <span class="info-label mb-auto">Decision Due Date</span>
                <span class="d-block">{{ decisionDueDate }}</span>
            </div>
        </div>
        <div class="d-flex flex-row" *ngIf="request?.decisionDate != null || isDecisionDateEdited">
            <img src="assets/images/calendar-blue.svg" class="icon" />
            <div class="ms-3">
                <div class="d-flex justify-content-start">
                    <span class="info-label mb-auto">Decision Made</span>
                    <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.DATA_EDITS">
                        <div class="data-edit-button" *ngIf="requestType == 2" (click)="editDecisionDateClick()"></div>
                    </app-access-control>
                </div>
                <div *ngIf="!isDecisionDateEdited" class="info-text">{{request?.decisionDate | date: "mediumDate"}}</div>
                <div *ngIf="isDecisionDateEdited">
                    <div class="d-flex justify-content-start editableField">
                        <input required #decisionDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control form-control-sm custom-text-box" id="decisionDate" name="decisionDate" placeholder="yyyy/mm/dd" [(ngModel)]="request.decisionDate" [ngClass]="{'is-invalid':decisionDateControl.dirty && decisionDateControl.invalid}">
                        <button [disabled]="isSaving" class="button-primary custom-button" (click)="editDecisionDateSaveClick(!decisionDateControl.invalid)">Ok</button>
                        <div class="invalid-feedback">
                            <div *ngIf="decisionDateControl.hasError('required')">Decision Date is required</div>
                        </div>
                    </div>
                    <span class="error">{{decisionDateErrorMessage}}</span>
                </div>
            </div>
        </div>        
        <div class="d-flex flex-row">
            <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.APPROVE_OR_DENY_REQUEST">
                <button class="submit-decision-button" [disabled]="request?.requestDecisionTypeId != null"
                [ngClass]="{'disabled': request?.requestDecisionTypeId != null}" (click)="showDecisionModal()">Submit
                Decision</button>
            </app-access-control>
        </div>                  
    </div>

    <div class="heading">Decision Send Method</div>
    <div class="d-flex justify-content-between">
        <div class="flex-fill">
            <span class="info-label mb-auto">Send Decision Via</span>
            <span class="d-block mt-2">{{ contactMethod }}</span>
        </div>
        <div class="flex-fill">
            <span class="info-label mb-auto">Address</span>
            <span class="d-block mt-2">{{ contactAddress }}</span>
            <span class="d-block mt-2">{{ contactAddressPostalCode }}</span>
        </div>
        <div class="flex-fill"></div>
    </div>


    <ng-container *ngIf="decisionDocument != null">
        <div class="heading">Decision Details</div>
        <div class="d-flex mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 200px;">Request Number</th>
                        <th style="width: 200px;">Decision</th>
                        <th style="width: 200px;">Date</th>
                        <th style="width: *;">Decision Letter</th>
                        <!-- <th style="width: 100px;"></th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>{{requestNumber}}</td>
                        <td>{{localStorageService.getTypeItemDescriptionById(request?.requestDecisionTypeId,
                            TypeTable.RequestDecisionType)}}</td>
                        <td>{{request.decisionDate | date:'mediumDate' }}</td>
                        <td>
                            <button class="download-file-link" (click)="downloadDocument(decisionDocument)">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                {{decisionDocument.documentName}}
                            </button>
                        </td>
                        <td>
                            <app-access-control [Resource]="Resource.REQUEST" [Permission]="Permission.DELETE_DECISION">
                                <a class="remove-file-link" (click)="onDeleteDecisionLetterClick()">Delete</a>
                            </app-access-control>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

</div>

<!-- Modal -->
<div class="modal" id="submitDecisionModal" tabindex="-1" role="dialog" aria-labelledby="submitDecisionModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <form #decisionForm="ngForm">
                <div class="modal-header border-0 pt-2">
                    <span class="modal-title h4" id="submitDecisionModalLabel">
                        Submit a decision
                    </span>
                    <div class="close-icon" (click)="onCloseIconClick()">
                        <i class="far fa-times-circle fa-lg"></i>
                    </div>
                </div>
                <div class="modal-body py-0">
                    <div class="d-flex">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width: 200px;">Request Number</th>
                                    <th>Type</th>
                                    <th style="width: 180px;" class="required">Decision</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ requestNumber }}</td>
                                    <td>{{ localStorageService.getTypeItemDescriptionById(requestType,
                                        TypeTable.RequestType) }}</td>
                                    <td>
                                        <select class="form-select modal-select mt-1 mb-1" name="decisionType"
                                            id="decisionType" #decisionType="ngModel" required
                                            [ngClass]="{ 'is-invalid': decisionForm.submitted && decisionType.invalid }"
                                            [(ngModel)]="decisionRequest.decisionTypeId">
                                            <option class="contravention-type-option" [ngValue]="null">--</option>
                                            <option class="contravention-type-option"
                                                *ngFor="let decisionType of decisionTypes" value="{{decisionType.id}}">
                                                {{decisionType.name}}</option>
                                        </select>
                                        <div *ngIf="decisionForm.submitted && decisionType.errors?.required"
                                            class="text-danger pb-1">
                                            Please select a decision
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="mt-3">
                        <label class="required fw-bold">Upload decision letter</label>
                        <file-upload [document]="decisionRequest.document" [fileUploadModel]="fileUploadModel"
                            [isDisabled]="isFormSubmitted" (onCancelEvent)="onCancelDcoument($event)"></file-upload>
                        <div class="text-danger">{{ errorMessage }}</div>
                    </div>
                    <div class="text-danger">{{ sysErrorMessage }}</div>
                </div>
                <div class="modal-footer border-0 d-flex flex-row-reverse justify-content-between">
                    <button id="saveSubmitButton" class="save-submit-button" [ngClass]="{ 'disabled': isFormSubmitted }" (click)="onSubmitClick()">
                        Save and Submit
                        <i class="fas fa-cog fa-spin"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel"
    aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmModalLabel">
                    Delete Decision Letter
                </span>
                <div class="close-icon" data-bs-dismiss="modal">
                    <i class="far fa-times-circle fa-lg"></i>
                </div>
            </div>
            <div class="modal-body py-0">
                <div class="delete-alert">
                    <div class="alert-icon">
                        <i class="fas fa-exclamation-triangle fa-lg"></i>
                    </div>
                    <div class="delete-alert-text">
                        <span>Deleting this file will remove it from the system. This action cannot be undone.</span>
                        <div class="bold-text">Are you sure?</div>
                    </div>
                </div>
                <div class="button-container float-end">
                    <button id="btnGoBack" type="button" class="goa-button goa--secondary"
                        data-bs-dismiss="modal">Cancel</button>
                    <button id="btnConfirm" type="button" class="goa-button goa--primary me-0" data-bs-dismiss="modal"
                        (click)="deleteDecisionLetter()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Processing... </p>
</ngx-spinner>