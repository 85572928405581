<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Change Contact Method</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="mb-3 row">
                <div class="col-12 col-lg-5">
                    <label class="modal-body-label" for="contactMethodTypeId">Preferred contact method</label>
                    <select id="contactMethodTypeId" 
                        name="contactMethodTypeId" 
                        #contactMethodTypeId="ngModel"
                        [(ngModel)]="request.contactMethodTypeId"
                        (change)="onContactMethodTypeChange()"
                        class="form-select modal-select">
                        <option class="modal-select-option" [ngValue]="0">Select a Preferred Contact Method</option>
                        <option class="modal-select-option"                        
                            *ngFor="let contactMethodType of contactMethodTypes"                                                 
                            [ngValue]="contactMethodType.id">{{contactMethodType.name}}</option>
                    </select>
                </div>
            </div>            
            <div class="contact-method-container">                    
                <form #phoneContactForm="ngForm">
                    <div class="phone-contact-method">
                        <div class="mb-3 row">
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="phoneNumber">Phone number</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend3">+1</span>
                                    </div>
                                    <input type="text" id="phoneNumber" name="phoneNumber" required 
                                        class="phone-number"
                                        maxlength="10"
                                        #phoneNumber="ngModel"                               
                                        [(ngModel)]="tempPhoneNumber"
                                        (ngModelChange)="request.phoneNumber = '1'+tempPhoneNumber"
                                        placeholder="numbers only"      
                                        aria-describedby="inputGroupPrepend3" 
                                        pattern="[0-9]{10}$"
                                        [ngClass]="{'alert-border': phoneNumber.invalid && phoneFormSubmitted}"/>
                                    <span class="alert-text" 
                                        [ngClass]="{'show': phoneNumber?.errors?.required && phoneFormSubmitted}">Phone number is required</span>
                                    <span class="alert-text" 
                                        [ngClass]="{'show': phoneNumber?.errors?.pattern && phoneFormSubmitted}">
                                        Invalid Phone Number
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="confirmPhoneNumber">Confirm phone number</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend2">+1</span>
                                    </div>
                                    <input type="text" id="confirmPhoneNumber" name="confirmPhoneNumber" 
                                        class="phone-number"
                                        maxlength="10"
                                        required
                                        #phoneNumberConfirmation="ngModel"
                                        [(ngModel)]="confirmPhoneNumber"                                        
                                        placeholder="numbers only"      
                                        aria-describedby="inputGroupPrepend2" 
                                        pattern="[0-9]{10}$"                              
                                        [ngClass]="{'alert-border': phoneNumberConfirmation.invalid && phoneFormSubmitted}"
                                        />                            
                                    <span class="alert-text" 
                                        [ngClass]="{'show': phoneNumberConfirmation?.errors?.required && phoneFormSubmitted}">
                                        Confirm phone number is required
                                    </span>
                                    <span class="alert-text" 
                                        [ngClass]="{'show': phoneNumberConfirmation?.errors?.pattern && phoneFormSubmitted}">
                                        Invalid Phone Number
                                    </span>
                                    <span class="alert-text" 
                                        [ngClass]="{'show': phoneNumberConfirmation?.errors?.mustMatch && phoneFormSubmitted}">
                                        Must match phone number
                                    </span>
                                </div>
                            </div>
                        </div>  
                        <div class="mb-3 row">
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="addressLine1">Address 1</label>
                                <input type="text" id="addressLine1" name="addressLine1" required 
                                    #addressLine1="ngModel"
                                    [(ngModel)]="request.contactAddress.addressLine1"
                                    placeholder="Ex. 123 Street, PO Box, or Range Road"
                                    [ngClass]="{'alert-border': addressLine1.invalid && phoneFormSubmitted}"
                                />
                                <span class="alert-text" 
                                    [ngClass]="{'show': addressLine1.invalid && phoneFormSubmitted}">
                                    Address 1 is required
                                </span>
                            </div>
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label" for="addressLine2">Address 2</label>
                                <input type="text" id="addressLine2" name="addressLine2" 
                                    #addressLine1="ngModel"
                                    [(ngModel)]="request.contactAddress.addressLine2"
                                    placeholder="Ex. Unit Number" />
                            </div>
                        </div>     
                        <div class="mb-3 row">
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="countryId">Country</label>
                                <select id="countryId" 
                                    name="countryId"
                                    required
                                    #countryId="ngModel"
                                    [(ngModel)]="request.contactAddress.countryId"                                    
                                    class="form-select modal-select"
                                    [ngClass]="{'alert-border': countryId.invalid && phoneFormSubmitted }">
                                    <option class="modal-select-option" [ngValue]="undefined">Select a Country</option>
                                    <option class="modal-select-option"                        
                                        *ngFor="let country of countryTypes"                                                 
                                        [ngValue]="country.id">{{country.name}}</option>
                                </select>
                                <span class="alert-text"
                                    [ngClass]="{'show': countryId.invalid && phoneFormSubmitted }">Country is required</span>
                            </div>
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="provinceId">Province/State</label>
                                <select id="provinceId" 
                                    name="provinceId" 
                                    required
                                    #provinceId="ngModel"
                                    [(ngModel)]="request.contactAddress.provinceId"                                    
                                    class="form-select modal-select"                                                                     
                                    [ngClass]="{'alert-border': provinceId.invalid && phoneFormSubmitted }">
                                    <option class="modal-select-option" [ngValue]="undefined">Select a Province/State</option>
                                    <option class="modal-select-option"                        
                                        *ngFor="let province of provinceTypes"                                                 
                                        [ngValue]="province.id">{{province.name}}</option>
                                </select>
                                <span class="alert-text" 
                                    [ngClass]="{'show': provinceId.invalid && phoneFormSubmitted }">Province is required</span>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="city">City/Town/Village</label>
                                <input type="text" id="city" name="city" required 
                                    #city="ngModel"
                                    [(ngModel)]="request.contactAddress.city"
                                    placeholder="City/Town/Village"
                                    [ngClass]="{'alert-border': city.invalid && phoneFormSubmitted}"
                                />
                                <span class="alert-text"
                                    [ngClass]="{'show': city.invalid && phoneFormSubmitted }">City is required</span>
                            </div>
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label" for="postalCode">Postal Code</label>
                                <input type="text" id="postalCode" name="postalCode" required 
                                    #postalCode="ngModel"
                                    [(ngModel)]="request.contactAddress.postalCode"
                                    placeholder="Ex. A9A 9A9" 
                                    [ngClass]="{'alert-border': postalCode.invalid && phoneFormSubmitted }"
                                    />
                                <span class="alert-text" 
                                    [ngClass]="{'show': postalCode.invalid && phoneFormSubmitted }">Postal Code is required</span>
                            </div>
                        </div>                           
                    </div>
                </form>
                                
                <div class="text-contact-method mb-3">
                    <form #textContactForm="ngForm">
                        <div class="text-contact-method">
                            <div class="mb-3 row">
                                <div class="col-12 col-lg-6">
                                    <label class="modal-body-label required" for="textPhoneNumber">Phone number</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroupPrepend3">+1</span>
                                        </div>
                                        <input type="text" id="textPhoneNumber" name="phoneNumber" required 
                                            class="phone-number"
                                            maxlength="10"
                                            #textPhoneNumber="ngModel"                               
                                            [(ngModel)]="tempPhoneNumber"
                                            (ngModelChange)="request.phoneNumber = '1'+tempPhoneNumber"
                                            placeholder="numbers only"      
                                            aria-describedby="inputGroupPrepend3" 
                                            pattern="[0-9]{10}$"
                                            [ngClass]="{'alert-border': textPhoneNumber.invalid && textFormSubmitted}"/>
                                        <span class="alert-text" 
                                            [ngClass]="{'show': textPhoneNumber?.errors?.required && textFormSubmitted}">Phone number is required</span>
                                        <span class="alert-text" 
                                            [ngClass]="{'show': textPhoneNumber?.errors?.pattern && textFormSubmitted}">
                                            Invalid Phone Number
                                        </span>
                                    </div>                                                                        
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label class="modal-body-label required" for="confirmTextPhoneNumber">Confirm phone number</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroupPrepend2">+1</span>
                                        </div>
                                        <input type="text" id="confirmTextPhoneNumber" name="confirmTextPhoneNumberModel"
                                            class="phone-number"
                                            maxlength="10"
                                            required
                                            #confirmTextPhoneNumber="ngModel"
                                            [(ngModel)]="confirmTextPhoneNumberModel"                                        
                                            placeholder="numbers only"      
                                            aria-describedby="inputGroupPrepend2" 
                                            pattern="[0-9]{10}$"                              
                                            [ngClass]="{'alert-border': confirmTextPhoneNumber.invalid && textFormSubmitted}"
                                            />                            
                                        <span class="alert-text" 
                                            [ngClass]="{'show': confirmTextPhoneNumber?.errors?.required && textFormSubmitted}">
                                            Confirm phone number is required
                                        </span>
                                        <span class="alert-text" 
                                            [ngClass]="{'show': confirmTextPhoneNumber?.errors?.pattern && textFormSubmitted}">
                                            Invalid Phone Number
                                        </span>
                                        <span class="alert-text" 
                                            [ngClass]="{'show': confirmTextPhoneNumber?.errors?.mustMatch && textFormSubmitted}">
                                            Must match phone number
                                        </span>
                                    </div>                                    
                                </div>
                            </div>  
                        </div>
                    </form>
                </div>
                                
                <form #emailContactForm="ngForm">
                    <div class="email-contact-method">
                        <div class="mb-3 row">
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="email">Email address</label>
                                <input type="text" id="email" name="email" required                                     
                                    #email="ngModel"                               
                                    [(ngModel)]="request.email"
                                    placeholder="Email address"                                     
                                    [ngClass]="{'alert-border': email.invalid && emailFormSubmitted}"/>
                                <span class="alert-text" 
                                    [ngClass]="{'show': email.invalid && emailFormSubmitted}">Email address is required</span>
                            </div>
                            <div class="col-12 col-lg-6">
                                <label class="modal-body-label required" for="confirmEmail">Confirm email address</label>
                                <input type="text" id="confirmEmail" name="confirmEmail"                                     
                                    required
                                    #emailConfirmation="ngModel"
                                    [(ngModel)]="confirmEmail"
                                    placeholder="Email address"                                    
                                    [ngClass]="{'alert-border': emailConfirmation.invalid && emailFormSubmitted}"
                                    />                            
                                <span class="alert-text" 
                                    [ngClass]="{'show': emailConfirmation?.errors?.required && emailFormSubmitted}">
                                    Confirm email address is required
                                </span>
                                <span class="alert-text" 
                                    [ngClass]="{'show': emailConfirmation?.errors?.mustMatch && emailFormSubmitted}">
                                    Must match email address
                                </span>
                            </div>
                        </div>  
                    </div>
                </form>                
            </div>
            
            <div class="mb-3">
                <label class="modal-body-label" for="eventDetails">Note</label>
                <textarea 
                    id="eventDetails" 
                    name="eventDetails"
                    [(ngModel)]="eventDetails"
                    class="modal-textarea"                                   
                    (input)="onTextareaInput($event)"></textarea>
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            </div>            
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()" >Cancel</div>
            <div id="changeButton" class="change-button" [ngClass]="{'disabled': isChangeButtonDisabled() }" (click)="onChangeModalClick($event)">
                Change
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>
