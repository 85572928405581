<div class="d-flex flex-column h-100">
    <div class="header">
        Contraventions
    </div>
    <div class="main">    
        <div class="filter-section">            
            <div class="filter-by-header">Filter by:</div>
            <div class="mb-3">
                <div class="filter-type-header">Contravention Type</div>
                <div class="filter-type-container">                    
                    <select class="form-select w-75" id="contraventionTypesDropdown" (change)="onContraventionTypeChange($event)"
                     [(ngModel)]="selectedContraventionTypeId">
                        <option class="contravention-type-option" value="0">All</option>
                        <option class="contravention-type-option" *ngFor="let contraventionType of contraventionTypes" value="{{contraventionType.id}}">{{contraventionType.name}}</option>
                    </select>                                        
                </div>
            </div>
            
            <div class="mb-3">               
                <div class="filter-type-header d-flex align-items-center justify-content-between">
                    Status
                    <div class="select-all-filters" (click)="onSelectAllFiltersClick($event)">Select All Filters</div>
                </div>
                <div class="filter-type-container">
                    <status-checkboxes 
                        [statusTypes]="contraventionStatusTypes" 
                        (change)="onContraventionStatusCheckboxClick($event)"></status-checkboxes>
                </div>
            </div>
            
            <action-checkboxes
                (change)="onActionCheckboxesChange($event)"
                [showContact]="false"
                [showClosure]="false">
            </action-checkboxes>

            <div class="clear-filters disabled" (click)="onClearAllFiltersClick($event)">Clear All filters</div>     
        </div>

        <div class="w-100 ps-4 pe-4">
            <div class="d-flex justify-content-between align-items-center">
                <div class="search-box">
                    <input id="searchTextbox" type="text" class="form-control" placeholder="Search by name or number" 
                        [(ngModel)]="searchText"
                        (keydown)="onSearchTextboxKeyDown($event)" />                
                    <button class="searchButton" type="button" id="searchButton" (click)="onSearchButtonClick()"><i class="fas fa-search fa-lg"></i></button>                
                </div>
                <div class="d-flex align-items-center">
                    <div class="paging align-self-end">     
                        <label for="pageSizeTop" class="page-size-label">Page Size</label>
                        <select class="form-select page-size-select" id="pageSizeTop" (change)="onPageSizeChange($event)">                            
                            <option value="10" [selected]="+pageSize == 10">10</option>
                            <option value="25" [selected]="+pageSize == 25">25</option>
                            <option value="50" [selected]="+pageSize == 50">50</option>
                            <option value="100" [selected]="+pageSize == 100">100</option>
                        </select>                               
                        <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>                    
                        <div class="previous-link" 
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                            (click)="onPreviousLinkClick($event)">Previous</div>
                      
                        <ng-container *ngFor="let page of pageNumbers; let i=index;">
                            <div id="pageNumberTop_{{page}}" class="page-number" 
                                [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                                (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                        </ng-container>
                        
                        <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                            <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                            <div id="pageNumberTop_{{totalPages}}" class="page-number ms-0" 
                            [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                            (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                        </ng-container>              

                        <div class="next-link"
                            [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                            (click)="onNextLinkClick($event)">Next</div>                        
                        <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>                        
                    </div>
                    <app-access-control [Resource]="Resource.CONTRAVENTION" [Permission]="Permission.EXPORT_LIST">
                        <div class="export-list" (click)="onExportListClick($event)">
                            <img class="export-list-icon" src="assets/images/export-list.svg" />
                            <div class="ms-2">Export List</div>
                        </div>
                    </app-access-control>
                </div>
            </div>
            <div class="d-flex mt-3">
                <table id="contraventionsTable" class="table table-hover">
                    <thead>
                        <tr class="header-row">
                            <th style="width: 150px;">
                                <div class="d-flex">
                                    <span>Issuance Date</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('issueDate')"></i></span>
                                </div>
                            </th>
                            <th style="width: 130px;">
                                <div class="d-flex">
                                    <span>Re-Service<br />Date</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('reServeDate')"></i></span>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex">
                                    <span>Contravention<br />Number</span>
                                    <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('contraventionNumber')"></i></span>
                                </div>
                            </th>
                            <th>Type <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('contraventionTypeName')"></i></span></th>
                            <th>Recipient Name <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('recipientName')"></i></span></th>
                            <th style="position: relative;">
                                Amt<br />Owing</th>
                            <th style="width: 120px; padding-left: 0px !important;">
                                Status <span class="sort-icon"><i class="fas fa-sort" (click)="onSortIconClick('contraventionStatusTypeName')"></i></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredContraventions.length > 0">
                        <tr *ngFor="let contravention of filteredContraventions">
                            <td>{{contravention.issueDate | date: "mediumDate"}}</td>
                            <td>{{getReserveDate(contravention)}}</td>
                            <td><a [routerLink]="['/contravention', contravention.contraventionNumber]">{{contravention.contraventionNumber}}</a></td>
                            <td>{{contravention.contraventionTypeName}}</td>
                            <td>{{contravention.recipientName}}</td>
                            <td>{{contravention.dueAmount | currency}}</td>
                            <td class="ps-0">
                                <div class="d-flex flex-wrap">
                                    <div class="contravention-status-pill mb-2"                                         
                                        [ngClass]="getStatusCssClass(contravention.contraventionStatusTypeId)">
                                        {{contravention.contraventionStatusTypeName}}
                                    </div>  
                                    
                                    <div class="d-flex">                                        
                                        <div *ngIf="contravention.hasDocumentsRequiringRedaction" class="contravention-action-alert-icon">                
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>
                                        <div *ngIf="contravention.hasDocumentsWithPendingSubmissions" class="contravention-action-warning-icon">                
                                            <i class="fas fa-exclamation-triangle fa-xs"></i>
                                        </div>                                        
                                    </div>
                                </div>
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="filteredContraventions.length === 0">
                        <tr>
                            <td colspan="7">No contraventions found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-end">            
                <div class="paging">      
                    <label for="pageSizeBottom" class="page-size-label">Page Size</label>
                    <select class="form-select page-size-select" id="pageSizeBottom" (change)="onPageSizeChange($event)">                        
                        <option value="10" [selected]="+pageSize == 10">10</option>
                        <option value="25" [selected]="+pageSize == 25">25</option>
                        <option value="50" [selected]="+pageSize == 50">50</option>
                        <option value="100" [selected]="+pageSize == 100">100</option>
                    </select>     

                    <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>
                    <div class="previous-link" 
                        [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                        (click)="onPreviousLinkClick($event)">Previous</div>

                    <ng-container *ngFor="let page of pageNumbers; let i=index;">                            
                        <div id="pageNumberBottom_{{page}}" class="page-number" 
                            [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                            (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                    </ng-container>
                    
                    <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                        <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                        <div id="pageNumberBottom_{{totalPages}}" class="page-number ms-0" 
                        [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                        (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                    </ng-container>

                    <div class="next-link" 
                        [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                        (click)="onNextLinkClick($event)">Next</div>
                    <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>
                </div>
            </div>            
        </div>                
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>         
</div>