<div class="modal-overlay modal-show">
    <form #scheduledEventForm="ngForm" (ngSubmit)="onSubmit(scheduledEventForm.valid)">
        <div class="modal-container">
            <div class="modal-container-header">
                <div class="modal-title">Availability Report</div>
            </div>
            <div class="modal-container-body d-flex flex-column">
                <div class="d-flex flex-row">
                <div class="mb-3 me-3">
                    <label class="modal-body-label required" for="eventStartDate"
                        >Start date</label
                    >
                    <div class="d-flex">
                        <div class="">
                        <input
                            type="text"
                            required
                            class="form-control"
                            id="eventStartDate"
                            name="eventStartDate"
                            #eventStartDate="ngModel"
                            [(ngModel)]="reportRequest.startDate"
                            [ngClass]="{'alert-border': eventStartDate.errors?.required && scheduledEventForm.submitted}"
                            bsDatepicker
                            [bsConfig]="datePickerConfig"
                            placeholder="MMM DD, YYYY"
                        />
                    </div>
                </div>
                <span
                    class="alert-text"
                    [ngClass]="{'show': eventStartDate.errors?.required && scheduledEventForm.submitted}"
                    >
                    Start date is required
                    </span>
                </div>
                <div class="mb-3">
                    <label class="modal-body-label required" for="eventEndDate">End date</label>
                    <div class="d-flex">
                        <div class="">
                        <input
                            type="text"
                            required
                            class="form-control"
                            id="eventEndDate"
                            name="eventEndDate"
                            #eventEndDate="ngModel"
                            [(ngModel)]="reportRequest.endDate"
                            [ngClass]="{'alert-border': eventEndDate.errors?.required && scheduledEventForm.submitted}"
                            bsDatepicker
                            [bsConfig]="datePickerConfig"
                            placeholder="MMM DD, YYYY"
                        />
                    </div>
                </div>
                <span
                    class="alert-text"
                    [ngClass]="{'show': eventEndDate.errors?.required && scheduledEventForm.submitted}"
                    >
                    End date is required
                    </span>
                </div>
                </div>
                <div>
                    <label class="modal-body-label required" for="eventEndDate">Meeting type</label>
                    <div class="d-flex flex-row">
                        <div class="custom-checkbox me-3">
                            <input type="checkbox" id="chkIrsReviewWritten" name="chkIrsReviewWritten" 
                            [(ngModel)]="irsReviewWritten">
                            <label for="chkIrsReviewWritten">IRS Review Written</label>
                        </div>
                        <div class="custom-checkbox me-3">
                            <input type="checkbox" id="chkIrsReviewOral" name="chkNoReviewHeldByDate" 
                            [(ngModel)]="irsReviewOral">
                            <label for="chkIrsReviewOral">IRS Review Oral</label>
                        </div>
                        <div class="custom-checkbox">
                            <input type="checkbox" id="chkSeizureReview" name="chkSeizureReview" 
                            [(ngModel)]="seizureReview">
                            <label for="chkSeizureReview">Seizure Review</label>
                        </div>
                    </div>
                    <span class="alert-text show" *ngIf="!irsReviewWritten && !irsReviewOral && !seizureReview && scheduledEventForm.submitted">
                        Meeting type is required
                    </span>
                </div>
            </div>
            <div class="button-container">
                <button type="button" class="goa-button goa--secondary" (click)="onCancelClick()">Cancel</button>
                <button type="submit" class="goa-button">Generate Report</button>
            </div>      
        </div>
    </form>
</div>