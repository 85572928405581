<div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center" style="margin-top: 22px;">
        <div class="heading-table">Judicial Review</div>
    </div>
    <div class="d-flex mt-3 mb-3 flex-column">
        <table class="table">
            <thead>
                <tr>
                    <th>Court Action Number</th>
                    <th>JR Served</th>
                    <th>JR Package</th>
                    <th>JR Decision</th>
                    <th>JR Decision Date</th>
                </tr>
            </thead>

            <tbody *ngIf="judicialReview">
                <tr>
                    <td>
                        <div>{{judicialReview.courtActionNumber}}</div>
                    </td>
                    <td>
                        <div>{{judicialReview.servedDate | date:"mediumDate"}}</div>
                    </td>
                    <td>
                        <a *ngIf="!isJrdownloadStarted" (click)="onDownloadPackageClick()" class="download-package-link">Download</a>
                        <ng-template [ngIf]="isJrdownloadStarted">
                            <div class="align-self-center">
                                <div class="progress doc-progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                                        [attr.aria-valuenow]="progress" [style.width]="progress +'%'" aria-valuemin="0" aria-valuemax="100">{{progress +'%'}}
                                    </div>
                                </div>       
                            </div>
                        </ng-template>
                    </td>
                    <td class="outcome-type">
                        <app-access-control [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.SUBMIT_DECISION">
                            <div *ngIf="!judicialReview.judicialReviewDecisionTypeId">
                                <a (click)="onSubmitDecisionClick()" class="submit-decision-link">Submit</a>
                            </div>                        
                        </app-access-control>
                        <div
                            *ngIf="judicialReview.reReviewId == null
                            && (judicialReview.judicialReviewDecisionTypeId == JudicialReviewDecisionTypes.CourtOrderedReReview
                            || judicialReview.judicialReviewDecisionTypeId == JudicialReviewDecisionTypes.ConsentToReReview);
                            else jrDecisionText">
                            <a [routerLink]="['/judicial-reviews', judicialReview?.judicialReviewNumber, 're-review']">
                                {{localStorageService.getTypeItemDescriptionById(judicialReview?.judicialReviewDecisionTypeId,TypeTable.JudicialReviewDecisionType)}}
                            </a>
                        </div>
                        <ng-template #jrDecisionText>
                            {{localStorageService.getTypeItemDescriptionById(judicialReview?.judicialReviewDecisionTypeId,TypeTable.JudicialReviewDecisionType)}}
                        </ng-template>
                    </td>
                    <td>
                        <div *ngIf="!judicialReview.judicialReviewDecisionDate">-</div>
                        <div *ngIf="judicialReview.judicialReviewDecisionDate">
                            {{judicialReview.judicialReviewDecisionDate | date:"mediumDate"}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="judicialReview && judicialReview.vehicleSeizureId == null">
        <div class="d-flex justify-content-between align-items-center" style="margin-top: 22px;">
            <div class="heading-table">Orders to Stay</div>
        </div>

        <div *ngIf="judicialReview?.stayOrders && judicialReview?.stayOrders?.length >0"
            class="d-flex mt-3 mb-3 flex-column">
            <table class="table">
                <thead>
                    <tr>
                        <th>Effective Date</th>
                        <th>Expiration Date</th>
                        <th>Stay Status</th>
                        <th>Consent Order</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let stayOrder of judicialReview.stayOrders; let i=index">
                        <td>
                            <div>{{stayOrder.effectiveDate | date:"mediumDate"}}</div>
                        </td>
                        <td>
                            <div>{{stayOrder.expirationDate | date:"mediumDate"}}</div>
                        </td>
                        <td>
                            {{localStorageService.getTypeItemDescriptionById(stayOrder.stayOrderStatusTypeid,TypeTable.StayOrderStatusType)}}
                        </td>
                        <td>
                            <div class="d-flex align-items-center" *ngIf="stayOrder?.documents?.length > 0 && !isStaydownloadStarted">
                                <img class="download-file-icon" src="assets/images/download-file.png" />
                                <a (click)="onDownloadStayOrderClick(stayOrder)"
                                    class="download-file-link">StayOrder</a>
                            </div>
                            <ng-template [ngIf]="isStaydownloadStarted">
                                <div class="align-self-center">
                                    <div class="progress doc-progress">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                                            [attr.aria-valuenow]="progress" [style.width]="progress +'%'" aria-valuemin="0" aria-valuemax="100">{{progress +'%'}}
                                        </div>
                                    </div>       
                                </div>
                            </ng-template>
                        </td>
                        <td>
                            <app-access-control [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.STAY_ORDER_EDIT">
                                <div
                                    *ngIf="stayOrder.stayOrderStatusTypeid != StayOrderStatusTypes.Revoked && stayOrder.stayOrderStatusTypeid != StayOrderStatusTypes.Cancelled">
                                <img class="pencil-icon" src="assets/images/pencil.svg"
                                    (click)="onEditStayOrderClick(stayOrder)">
                                </div>                            
                            </app-access-control>
                        </td>
                        <td>
                            <app-access-control [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.STAY_ORDER_EDIT">
                                <div
                                    *ngIf="stayOrder.stayOrderStatusTypeid != StayOrderStatusTypes.Revoked && stayOrder.stayOrderStatusTypeid != StayOrderStatusTypes.Cancelled">
                                    <a class="revoke-link" (click)="onRevokeStayOrderClick(stayOrder)">Revoke</a>
                                </div>                            
                            </app-access-control>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-access-control [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.STAY_ORDER_EDIT">
            <div id="addStayOrderButton" class="add-button" (click)="onAddStayOrderModalClick()">Add Stay Order</div>
        </app-access-control>
    </div>

    <div class="d-flex justify-content-between align-items-center" style="margin-top: 42px;">
        <div class="heading-table">Document Uploads</div>
    </div>
    <div class="d-flex mt-3 mb-3 flex-column">
        <table class="table">
            <thead>
                <tr>
                    <th>Filename</th>
                    <th>Type</th>
                    <th>Date Added</th>
                    <th>Format</th>
                    <th>File Size</th>
                    <th></th>
                </tr>
            </thead>

            <tbody *ngIf="judicialReviewUploads?.length > 0">
                <tr *ngFor="let document of judicialReviewUploads">
                    <td>
                        <div class="d-flex align-items-center">
                            <img class="download-file-icon" src="assets/images/download-file.png" />
                            <a (click)="onDocumentNameClick(document)"
                                class="download-file-link">{{document.documentName}}</a>
                        </div>
                    </td>
                    <td>
                        <div>{{getDocumentTypeName(document)}}</div>
                    </td>
                    <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                    <td class="document-format">{{document.documentExtension}}</td>
                    <td>{{document.documentSize}}</td>
                    <td>
                        <app-access-control [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.DELETE_DOCUMENT">
                            <a class="remove-file-link" (click)="onDeleteUploadClick(document)">Delete</a>
                        </app-access-control>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="judicialReviewUploads?.length == 0">
                <tr>
                    <td colspan="5">No judicial review uploads found.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-access-control [Resource]="Resource.JUDICIAL_REVIEW" [Permission]="Permission.UPLOAD_DOCUMENT">
        <document-upload documentRefTypeName="JudicialReviews"
            [documentRefTypeNumber]="judicialReview?.judicialReviewNumber"
            [documentTypeId]="judicialReviewUploadsDocumentTypeId" [showDropZoneAfterUpload]="true"
            [uploadAndFinalize]="true" (documentAdded)="onJudicialReviewUploadAdded($event)"></document-upload>
    </app-access-control>
</div>

<div #contraventionNotice *ngIf="contravention && contravention.stopInformation && showNoticesInformation">
    <h3>Advanced Projects Information System (APIS) Export</h3>
    <div class="report-title">Contravention Export</div>
    <div class="report-number">IRS Contravention {{ contravention.contraventionNumber }} <span
            *ngIf="contravention.vehicleSeizure?.seizureNumber"> / Seizure {{ contravention.vehicleSeizure.seizureNumber
            }} </span> <span *ngIf="contravention.policeFileNumber"> / Police File #{{ contravention.policeFileNumber
            }}</span>
        <span *ngIf="!contravention.policeFileNumber"> / Police File -
        </span>
    </div>
    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Recipient Information</div>
        </div>
        <div class="card-body">
            <label *ngIf="recipient.movesLookupDate">The following recipient information and contact information was
                generated from the Motor Vehicle System (MOVES) on {{recipient.movesLookupDate | date:'mediumDate'}} at
                {{recipient.movesLookupDate | date:'HH:mm'}}</label>
            <app-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientInformation">
            </app-section-details>
            <app-section-details sectionTitle="Identification Information"
                [sectionDetails]="recipientIdentificationInformation"></app-section-details>

            <ng-container
                *ngIf="(recipient?.movesLookupDate && !recipientContact?.isAddressDifferentFromMOVES) || !recipient?.movesLookupDate">
                <app-section-details sectionTitle="Recipient Contact Information"
                    [sectionDetails]="recipientContactInformation"></app-section-details>
            </ng-container>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Contravention Details</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="Contravention Information" [sectionDetails]="contraventionType">
            </app-section-details>
            <app-section-details sectionTitle="Occurrence Time & Location"
                [sectionDetails]="occurrenceTimeAndLocationInformation"></app-section-details>
            <app-section-details sectionTitle="Location Information" [sectionDetails]="locationInformation">
            </app-section-details>
            <app-section-details sectionTitle="Witness Information" [sectionDetails]="witnessInformation">
            </app-section-details>
            <app-section-details sectionTitle="Occurrence Information" [sectionDetails]="occurrenceInformation">
            </app-section-details>

            <div class="row" *ngIf="showPassengers && recipient.recipientTypeId!=RecipientTypes.NotInVehicle">
                <div class="col-12 col-sm-4">
                    <div class="label">Passengers</div>
                    <div class="value">{{contravention.stopInformation?.isPassengerInVehicle? 'Yes': 'No'}}</div>
                </div>
                <ng-container *ngIf="contravention.stopInformation?.isPassengerInVehicle">
                    <div class="col-12 col-sm-4">
                        <div class="label">Passenger Positions</div>
                        <div class="value ps-2">
                            <app-passenger-position [stopInformation]="contravention.stopInformation"
                                [isReadOnly]="true"></app-passenger-position>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="label">Additional Passenger Location Details</div>
                        <div class="value">{{contravention.stopInformation?.vehicleOccupantsNotes}}</div>
                    </div>
                </ng-container>
            </div>

            <div class="row" *ngIf="isBodycamVideoActive">
                <div class="col-12 col-sm-4">
                    <div class="label">Is there any video in relation to this event?</div>
                    <div class="value" *ngIf="contravention.stopInformation?.hasBodycamVideo == null; else bodycamVideoHasValue">-</div>
                    <ng-template #bodycamVideoHasValue>
                        <div class="value">{{contravention.stopInformation?.hasBodycamVideo? 'Yes': 'No'}}</div>
                    </ng-template>
                </div>
            </div>

            <app-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds">
            </app-section-details>
            <app-section-details sectionTitle="Impairment Screening Information"
                [sectionDetails]="impairmentScreeningInformation"></app-section-details>
            <app-section-details sectionTitle="Impairment Screening Details"
                [sectionDetails]="impairmentScreeningDetails"></app-section-details>
            <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                <app-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}"
                    [sectionDetails]="additionalTest"></app-section-details>
            </div>

            <app-section-details *ngIf="contravention?.roadsideAppeal" sectionTitle="Roadside Appeal Information"
                [sectionDetails]="roadsideAppealInformation"></app-section-details>
            <app-section-details *ngIf="contravention?.roadsideAppeal" sectionTitle="Roadside Appeal Details"
                [sectionDetails]="roadsideAppealDetails"></app-section-details>

            <app-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds">
            </app-section-details>
            <app-section-details sectionTitle="Officer Information" [sectionDetails]="officerInformation">
            </app-section-details>
            <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails">
            </app-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isVehicleSeizureSectionVisible">
        <div class="card-header">
            <div class="section-header">Vehicle Seizure Details</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="Seizure Information" [sectionDetails]="seizureInformation">
            </app-section-details>
            <label *ngIf="vehicle.movesLookupDate">The following vehicle information was generated from the Motor
                Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate |
                date:'HH:mm'}}</label>

            <ng-container *ngIf="(vehicle?.movesLookupDate && !vehicle?.isStolenPlate) || !vehicle?.movesLookupDate">
                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation">
                </app-section-details>
            </ng-container>

            <app-section-details *ngIf="trailerInformation.length > 0" sectionTitle="Trailer Information"
                [sectionDetails]="trailerInformation"></app-section-details>

            <ng-container
                *ngIf="recipient.recipientTypeId==RecipientTypes.Driver || recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
                <label *ngIf="vehicle.movesLookupDate && !vehicle?.registeredOwner?.isSameAsDriver && !vehicle?.isNoRegisteredOwner && !vehicle?.isStolenPlate">The following
                    registered owner information was generated from the Motor Vehicle System (MOVES) on
                    {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate |
                    date:'HH:mm'}}</label>
            </ng-container>

            <app-section-details sectionTitle="Registered Owner Information"
                [sectionDetails]="registeredOwnerInformation"></app-section-details>

            <app-section-details sectionTitle="Vehicle Location & Tow Lot Information"
                [sectionDetails]="towLotInformation"></app-section-details>
        </div>
    </div>

    <div  *ngIf="supportingDocuments.length > 0" class="card mt-4">
        <div class="card-header">
            <div class="section-header">Supporting Documents</div>
        </div>

        <div class="card-body">
            <div *ngFor="let document of supportingDocuments">
                <div class="row" style="border-bottom: solid 1px #333;">
                    <div class="col-12 mt-3 pb-0">
                        <label
                            [ngClass]="{'required': !document.isOptional}">{{getDocumentTypeName(document)}}</label>
                    </div>
                    <div class="col-12 mb-0 pb-0">
                        <span *ngIf="document.documentName != null">File Name: {{document.documentName}}
                            {{(document.documentSize)}} {{document.isPublished ? "Published" : "Hidden"}}</span>
                        <div *ngIf="document.documentName == null && document.isSubmitLater">
                            Will submit at later date
                        </div>
                    </div>
                    <div *ngIf="document.uploadedDate" class="col-12 mb-3">
                        <span>{{getDocumentUploadNameAndDate(document)}} </span>
                    </div>
                    <div *ngIf="document.publishedDate && showPublishedDate" class="col-12 mb-3">
                        <span>{{getDocumentPublishedDate(document)}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="supportingDocumentDeclarationInformation.length > 0" class="card mt-4">
        <div class="card-header">
            <div class="section-header">Supporting Document Declarations</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarationInformation">
            </app-section-details>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Police Service Information</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Declaration</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="" [sectionDetails]="declarationInformation"></app-section-details>
            <app-section-details *ngIf="contravention.isReServeRequired" sectionTitle="Re-Service Information"
                [sectionDetails]="reServiceInformation">
            </app-section-details>
        </div>
    </div>
</div>

<div #seizureNotice *ngIf="SDPVehicleSeizure && showNoticesInformation">
    <h3>Advanced Projects Information System (APIS) Export</h3>
    <div class="report-title">Seizure Export</div>
    <div class="report-number">Seizure {{ SDPVehicleSeizure.seizureNumber }}
        <span *ngIf="SDPVehicleSeizure.policeFileNumber"> / Police File #{{ SDPVehicleSeizure.policeFileNumber }}
        </span>
        <span *ngIf="!SDPVehicleSeizure.policeFileNumber"> / Police File -
        </span>
    </div>
    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Recipient Information</div>
        </div>
        <div class="card-body">
            <label *ngIf="SDPRecipient.movesLookupDate">The following recipient information and contact information was
                generated from the Motor Vehicle System (MOVES) on {{SDPRecipient.movesLookupDate | date:'mediumDate'}}
                at
                {{SDPRecipient.movesLookupDate | date:'HH:mm'}}</label>
            <app-section-details sectionTitle="Recipient Information" [sectionDetails]="SDPRecipientInformation">
            </app-section-details>
            <app-section-details sectionTitle="Identification Information"
                [sectionDetails]="SDPRecipientIdentificationInformation"></app-section-details>

            <ng-container
                *ngIf="(SDPRecipient?.movesLookupDate && !SDPRecipientContact?.isAddressDifferentFromMOVES) || !SDPRecipient?.movesLookupDate">
                <app-section-details sectionTitle="Recipient Contact Information"
                    [sectionDetails]="SDPRecipientContactInformation"></app-section-details>
            </ng-container>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Vehicle Seizure Details</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="Seizure Information" [sectionDetails]="SDPSeizureInformation">
            </app-section-details>
            <label *ngIf="SDPVehicle.movesLookupDate">The following vehicle information was generated from the Motor
                Vehicle System (MOVES) on {{SDPVehicle.movesLookupDate | date:'mediumDate'}} at
                {{SDPVehicle.movesLookupDate |
                date:'HH:mm'}}</label>

            <ng-container
                *ngIf="(SDPVehicle?.movesLookupDate && !SDPVehicle?.isStolenPlate) || !SDPVehicle?.movesLookupDate">
                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="SDPVehicleInformation">
                </app-section-details>
            </ng-container>

            <app-section-details *ngIf="SDPTrailerInformation.length > 0" sectionTitle="Trailer Information"
                [sectionDetails]="SDPTrailerInformation"></app-section-details>

            <ng-container
                *ngIf="SDPRecipient.recipientTypeId==RecipientTypes.Driver || SDPRecipient.recipientTypeId==RecipientTypes.RegisteredOwner">
                <label *ngIf="SDPVehicle.movesLookupDate && !SDPVehicle?.registeredOwner?.isSameAsDriver && !SDPVehicle?.isNoRegisteredOwner && !SDPVehicle?.isStolenPlate">The following
                    registered owner information was generated from the Motor Vehicle System (MOVES) on
                    {{SDPVehicle.movesLookupDate | date:'mediumDate'}} at {{SDPVehicle.movesLookupDate |
                    date:'HH:mm'}}</label>
                <app-section-details sectionTitle="Registered Owner Information"
                    [sectionDetails]="SDPRegisteredOwnerInformation"></app-section-details>
            </ng-container>

            <app-section-details sectionTitle="Vehicle Location & Tow Lot Information"
                [sectionDetails]="SDPTowLotInformation"></app-section-details>

            <app-section-details sectionTitle="Police Narrative" [sectionDetails]="SDPPoliceNarrativeDetails">
            </app-section-details>
        </div>
    </div>

    <div *ngIf="SDPSupportingDocuments.length > 0" class="card mt-4">
        <div class="card-header">
            <div class="section-header">Supporting Documents</div>
        </div>

        <div class="card-body">
            <div *ngFor="let document of SDPSupportingDocuments">
                <div class="row" style="border-bottom: solid 1px #333;">
                    <div class="col-12 mt-3 pb-0">
                        <label
                            [ngClass]="{'required': !document.isOptional}">{{getDocumentTypeName(document)}}</label>
                    </div>
                    <div class="col-12 mb-0 pb-0">
                        <span *ngIf="document.documentName != null">File Name: {{document.documentName}}
                            {{(document.documentSize)}} {{document.isPublished ? "Published" : "Hidden"}}</span>
                        <div *ngIf="document.documentName == null && document.isSubmitLater">
                            Will submit at later date
                        </div>
                    </div>
                    <div *ngIf="document.uploadedDate" class="col-12 mb-3">
                        <span>{{getDocumentUploadNameAndDate(document)}} </span>
                    </div>
                    <div *ngIf="document.publishedDate && showPublishedDate" class="col-12 mb-3">
                        <span>{{getDocumentPublishedDate(document)}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="SDPSupportingDocumentDeclarationInformation.length > 0" class="card mt-4">
        <div class="card-header">
            <div class="section-header">Supporting Document Declarations</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="" [sectionDetails]="SDPSupportingDocumentDeclarationInformation">
            </app-section-details>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Police Service Information</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="" [sectionDetails]="SDPPoliceServiceInformation"></app-section-details>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Declaration</div>
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="" [sectionDetails]="SDPDeclarationInformation"></app-section-details>
            <app-section-details *ngIf="SDPVehicleSeizure.isReServeRequired" sectionTitle="Re-Service Information"
                [sectionDetails]="SDPReServiceInformation">
            </app-section-details>
        </div>
    </div>
</div>

<div #reviewRequestNotice *ngIf="review && showNoticesInformation">
    <p style="text-align:center;"><strong><span
                style="font-size:21px;line-height:107%;color:#333333;">Advanced&nbsp;</span></strong><strong><span
                style="font-size:21px;line-height:107%;color:#333333;">Projects Information&nbsp;System (APIS)
                Export</span></strong></p>
    <p style="border:none;padding:0in;"><strong><span style="font-weight: bold;font-size:27px;line-height:107%;">Request
                for
                Review</span></strong></p>
    <p>No. {{ review.reviewNumber }}</p>
    <table style="border-collapse:collapse;border:none;margin-top: 1%;">
        <tbody>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                    <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                style="font-weight: bold;font-size:16px;">Applicant Information</span></strong></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 191.8pt;border-top: none;border-right: none;border-bottom: none;border-image: initial;border-left: 1pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Applicant Name</p>
                    <p><span style="font-size:13px;">{{ reviewApplicantName }}</span></p>
                </td>
                <td style="width: 191.85pt;border: none;padding: 8.65pt;vertical-align: top;">
                    <p>Email Address</p>
                    <p><span style="font-size:13px;">{{ review.email }}</span></p>
                </td>
                <td
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p><span style="font-size:13px;">&nbsp;</span></p>
                </td>
            </tr>
            <ng-container *ngIf="review.representation">
                <tr>
                    <td colspan="3"
                        style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                        <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                    style="font-weight: bold;font-size:16px;">Representation Information</span></strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="width: 191.8pt;border-top: none;border-right: none;border-bottom: none;border-image: initial;border-left: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                        <p>Type of Representation</p>
                        <p><span style="font-size:13px;">{{ reviewRepresentationTypeName }} <span
                                    *ngIf="review.representation.representationTypeId == RepresentationTypes.Other">, {{
                                    review.representation.otherRepresentationType }}</span> </span></p>
                    </td>
                    <td style="width: 191.85pt;border: none;border-right: none;padding: 8.65pt;vertical-align: top;">
                        <p>{{ reviewRepTitle }} Name</p>
                        <p><span style="font-size:13px;">{{ review.representation.firstName }} {{
                                review.representation.lastName }}</span></p>
                    </td>
                    <td *ngIf="review.representation.representationTypeId == RepresentationTypes.Agent || review.representation.representationTypeId == RepresentationTypes.Lawyer"
                        style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                        <p>{{ reviewRepTitle }} Practice Name</p>
                        <p><span style="font-size:13px;">{{ review.representation.practiceName }}</span></p>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                    <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                style="font-weight: bold;font-size:16px;">Review
                                Details</span></strong></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 191.8pt;border-top: none;border-right: none;border-bottom: none;border-image: initial;border-left: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Review Requested On</p>
                    <p><span style="font-size:13px;">{{ review.requestDate | date: "mediumDate" }}</span></p>
                </td>
                <td style="width: 191.85pt;border: none;padding: 8.65pt;vertical-align: top;">
                    <p>Review Method</p>
                    <p><span style="font-size:13px;">{{ reviewMethodTypeName }}</span></p>
                </td>
                <td *ngIf="review.reviewMethodTypeId == reviewMethodTypes.Written"
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Review Date</p>
                    <p><span style="font-size:13px;">{{ review.reviewDate | date: "mediumDate" }}</span></p>
                </td>
                <td *ngIf="review.reviewMethodTypeId == reviewMethodTypes.Oral"
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Review Date</p>
                    <p><span style="font-size:13px;">{{ review.reviewDate | date: "mediumDate" }} at {{ reviewDateTime |
                            date: "HH:mm" }}</span></p>
                </td>
            </tr>
            <tr *ngIf="showOralReviewElection && review.reviewMethodTypeId == reviewMethodTypes.Oral">
                <td colspan="3"
                    style="width: 575.5pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: none;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Oral Review Election</p>
                    <p><span style="font-size:13px;">I am satisfied with my opportunity to participate in the review. I want a decision on the basis of written materials I have filed in support of my request.</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: none;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Grounds for Review</p>
                    <p><span style="font-size:13px;">{{ reviewItems[0].reviewGrounds }}</span></p>
                </td>
            </tr>
            <tr *ngIf="review.additionalRequestNotes">
                <td colspan="3"
                    style="width: 575.5pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: none;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Additional Request Notes</p>
                    <p><span style="font-size:13px;">{{ review.additionalRequestNotes }}</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: none;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Interpreter</p>
                    <p><span style="font-size:13px;">{{ review.isAccompainedByInterpreter ? "Yes" : "No" }}</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                    <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                style="font-weight: bold;font-size:16px;">Contact
                                Information</span></strong></p>
                </td>
            </tr>
            <tr *ngIf="review.contactMethodTypeId == ContactMethodTypes.Email">
                <td
                    style="width: 191.8pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: 0.5pt solid rgb(191, 191, 191);border-right: none;padding: 8.65pt;vertical-align: top;">
                    <p>Preferred Contact Method</p>
                    <p><span style="font-size:13px;">{{ reviewContactMethodTypeName }}</span></p>
                </td>
                <td colspan="2"
                    style="width: 191.85pt;border-top: none;border-right: 0.5pt solid rgb(191, 191, 191);border-left: none;border-image: initial;border-bottom: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Email Address</p>
                    <p><span style="font-size:13px;">{{ review.email }}</span></p>
                </td>
            </tr>
            <ng-container *ngIf="review.contactMethodTypeId == ContactMethodTypes.Phone">
                <tr>
                    <td
                        style="width: 191.8pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: 0;border-right: none;padding: 8.65pt;vertical-align: top;">
                        <p>Preferred Contact Method</p>
                        <p><span style="font-size:13px;">{{ reviewContactMethodTypeName }}</span></p>
                    </td>
                    <td
                        style="width: 191.85pt;border-top: none;border-right: none;border-left: none;border-image: initial;border-bottom: 0;padding: 8.65pt;vertical-align: top;">
                        <p>Phone</p>
                        <p><span style="font-size:13px;">{{ review.phoneNumber }}</span></p>
                    </td>
                    <td
                        style="width: 191.85pt;border-top: none;border-right: 0.5pt solid rgb(191, 191, 191);border-left: none;border-image: initial;border-bottom: 0;padding: 8.65pt;vertical-align: top;">
                        <p>Address Line 1</p>
                        <p><span style="font-size:13px;">{{ review.contactAddress.addressLine1 }}</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="width: 191.85pt;border-top: none;border-right: none;border-left: 0.5pt solid rgb(191, 191, 191);border-image: initial;border-bottom: 0;padding: 8.65pt;vertical-align: top;">
                        <p>Address Line 2</p>
                        <p><span style="font-size:13px;">{{ review.contactAddress.addressLine2 }}</span></p>
                    </td>
                    <td
                        style="width: 191.85pt;border-top: none;border-right: none;border-left: none;border-image: initial;border-bottom: 0;padding: 8.65pt;vertical-align: top;">
                        <p>Country</p>
                        <p><span style="font-size:13px;">{{ review.contactAddress.country.name }}</span></p>
                    </td>
                    <td
                        style="width: 191.85pt;border-top: none;border-right: 0.5pt solid rgb(191, 191, 191);border-left: none;border-image: initial;border-bottom: 0;padding: 8.65pt;vertical-align: top;">
                        <p>Province</p>
                        <p><span style="font-size:13px;">{{ review.contactAddress.province }}</span></p>
                    </td>
                </tr>
                <tr>
                    <td
                        style="width: 191.85pt;border-top: none;border-right: none;border-left: 0.5pt solid rgb(191, 191, 191);border-image: initial;border-bottom: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                        <p>City</p>
                        <p><span style="font-size:13px;">{{ review.contactAddress.city }}</span></p>
                    </td>
                    <td colspan="2"
                        style="width: 191.85pt;border-top: none;border-right: 0.5pt solid rgb(191, 191, 191);border-left: none;border-image: initial;border-bottom: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                        <p>Postal Code</p>
                        <p><span style="font-size:13px;">{{ review.contactAddress.postalCode }}</span></p>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="review.contactMethodTypeId == ContactMethodTypes.Text">
                <td
                    style="width: 191.8pt;border-top: none;border-left: 0.5pt solid rgb(191, 191, 191);border-bottom: 0.5pt solid rgb(191, 191, 191);border-right: none;padding: 8.65pt;vertical-align: top;">
                    <p>Preferred Contact Method</p>
                    <p><span style="font-size:13px;">{{ reviewContactMethodTypeName }}</span></p>
                </td>
                <td colspan="2"
                    style="width: 191.85pt;border-top: none;border-right: 0.5pt solid rgb(191, 191, 191);border-left: none;border-image: initial;border-bottom: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>SMS</p>
                    <p><span style="font-size:13px;">{{ review.phoneNumber }}</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border-right: 0.5pt solid rgb(191, 191, 191);border-bottom: 0.5pt solid rgb(191, 191, 191);border-left: 0.5pt solid rgb(191, 191, 191);border-image: initial;border-top: none;padding: 8.65pt;vertical-align: top;">
                    <p>{{reviewTermsConditionsStatement}}</p>
                    <p><span style="font-size:13px;">Yes</span></p>
                </td>
            </tr>
        </tbody>
    </table>
    <p>&nbsp;</p>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                        style="font-weight: bold;font-size:16px;">Applicant-Submitted Supporting
                        Documents</span></strong></p>
        </td>
    </tr>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 0;vertical-align: top;">
            <div class="d-flex mb-3">

                <table style="margin-bottom: 0;" class="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Filename</th>
                            <th *ngIf="showPublishedDate">Date Published</th>
                            <th>Date Submitted</th>
                            <th>Format</th>
                            <th>File Size</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="reviewSupportingDocuments?.length > 0">

                        <tr *ngFor="let document of reviewSupportingDocuments">
                            <td>
                                <div>{{getDocumentTypeName(document)}}</div>
                            </td>
                            <td>
                                {{document.documentName}}
                            </td>
                            <td *ngIf="showPublishedDate">{{document.publishedDate | date: "mediumDate"}}</td>
                            <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                            <td>{{document.documentExtension}}</td>
                            <td>{{document.documentSize}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="reviewSupportingDocuments?.length == 0">
                        <tr>
                            <td colspan="5">No applicant-submitted supporting documents found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </td>
    </tr>

    <p>&nbsp;</p>

    <div *ngIf="review.reviewMethodTypeId == reviewMethodTypes.Oral" class="html2pdf__page-break"></div>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                        style="font-weight: bold;font-size:16px;">Review Payment Information</span></strong></p>
        </td>
    </tr>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <div class="d-flex mb-3">
                <table style="margin-bottom: 0;" class="table">
                    <thead>
                        <tr>
                            <th>Payment Method</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="review?.payments != null && review?.payments?.length > 0">
                        <tr *ngFor="let payment of review.payments">
                            <td>{{review.isSubmittedByRegistry?
                                "Registry":
                                payment.cardType + " ending in " + payment.cardLastFourDigits}}</td>
                            <td *ngIf="!payment.edited">{{review.isSubmittedByRegistry?
                                payment.financialTransaction.transactionId :
                                (payment.financialTransactionId | transactionid) }}</td>
                            <td>
                                <div class="payment-amount-column">{{payment.amount | currency}}</div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="review?.payments == null || review?.payments?.length == 0">
                        <tr>
                            <td colspan="5">No review payments found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </td>
    </tr>

    <p>&nbsp;</p>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                        style="font-weight: bold;font-size:16px;">Activity Log</span></strong></p>
        </td>
    </tr>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <table style="margin-left:-.25pt;border-collapse:collapse;border:none;">
                <tbody>
                    <tr *ngFor="let event of review.events">
                        <td
                            style="width: 575.5pt;border-image: initial;border-top: none;padding: 8.65pt;vertical-align: top;">
                            <p>{{ event.eventDate | date: 'MMMM d, y, HH:mm' }}</p>
                            <p>by {{ event.userName }}</p>
                            <p style="margin-bottom:.0001pt;"><strong><span style="font-weight: bold;font-size:13px;">{{
                                        getEventTypeName(event.eventTypeId) }}</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</div>

<div #lateReviewRequestNotice style="padding-left: 2%;" *ngIf="lateReviewRequest && showNoticesInformation">
    <p
        style='margin:0in;margin-bottom:.0001pt;line-height:107%;font-size:13px;font-family:"Calibri",sans-serif;text-align:center;'>
        <strong><span
                style="font-size:24px;line-height:107%;color:#333333;">Advanced&nbsp;</span></strong><strong><span
                style="font-size:24px;line-height:107%;color:#333333;">Projects Information&nbsp;System (APIS)
                Export</span></strong>
    </p>
    <div style="border:none;padding:6.0pt 0in 0in 0in;">
        <p style="border:none;padding:0in;"><strong><span
                    style="font-weight: bold;font-size:27px;line-height:107%;">Request for
                    Late Review</span></strong></p>
    </div>
    <p>No. {{ lateReviewRequest.lateReviewRequestNumber }}</p>
    <table style="border-collapse:collapse;border:none;margin-top: 1%;">
        <tbody>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                    <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                style="font-weight: bold;font-size:16px;">Applicant Information</span></strong></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 191.8pt;border-top: none;border-right: none;border-bottom: none;border-image: initial;border-left: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Recipient Name</p>
                    <p><span style="font-size:13px;">{{ lateReviewApplicantName }}</span></p>
                </td>
                <td style="width: 191.85pt;border: none;padding: 8.65pt;vertical-align: top;">
                    <p>Email Address</p>
                    <p><span style="font-size:13px;">{{ lateReviewRequest.email }}</span></p>
                </td>
                <td
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p><span style="font-size:13px;">&nbsp;</span></p>
                </td>
            </tr>
            <ng-container *ngIf="lateReviewRequest.representation">
                <tr>
                    <td colspan="3"
                        style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                        <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                    style="font-weight: bold;font-size:16px;">Representation Information</span></strong>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td
                        style="width: 191.8pt;border-top: none;border-right: none;border-bottom: none;border-image: initial;border-left: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                        <p>Type of Representation</p>
                        <p><span style="font-size:13px;">{{ lateReviewRepresentationTypeName }}</span></p>
                    </td>
                    <td style="width: 191.85pt;border: none;padding: 8.65pt;vertical-align: top;">
                        <p>{{ lateReviewRepTitle }} Name</p>
                        <p><span style="font-size:13px;">{{ lateReviewRequest.representation.firstName }} {{
                                lateReviewRequest.representation.lastName }}</span></p>
                    </td>
                    <td *ngIf="lateReviewRequest.representation.representationTypeId == RepresentationTypes.Agent || lateReviewRequest.representation.representationTypeId == RepresentationTypes.Lawyer"
                        style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                        <p>{{ lateReviewRepTitle }} Practice Name</p>
                        <p><span style="font-size:13px;">{{ lateReviewRequest.representation.practiceName }}</span></p>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                    <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                style="font-weight: bold;font-size:16px;">Review
                                Details</span></strong></p>
                </td>
            </tr>

            <tr>
                <td colspan="3"
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: 0.5pt solid rgb(191, 191, 191);border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Review Requested On</p>
                    <p><span style="font-size:13px;">{{ lateReviewRequest.requestDate | date: "mediumDate" }}</span></p>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: 0.5pt solid rgb(191, 191, 191);border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Reason for Request</p>
                    <p><span style="font-size:13px;">{{ lateReviewRequest.requestReason }}</span></p>
                </td>
            </tr>

            <tr>
                <td colspan="3"
                    style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
                    <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                                style="font-weight: bold;font-size:16px;">Contact
                                Information</span></strong></p>
                </td>
            </tr>
            <tr>
                <td
                    style="width: 191.8pt;border-top: none;border-right: none;border-bottom: none;border-image: initial;border-left: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>Preferred Contact Method</p>
                    <p><span style="font-size:13px;">{{ lateReviewContactMethodTypeName }}</span></p>
                </td>
                <td *ngIf="lateReviewRequest.contactMethodTypeId == ContactMethodTypes.Email"
                    style="width: 191.85pt;border-top: none;border-right: none;border-left: none;border-image: initial;padding: 8.65pt;vertical-align: top;">
                    <p>Email Address</p>
                    <p><span style="font-size:13px;">{{ lateReviewRequest.email }}</span></p>
                </td>
                <td *ngIf="lateReviewRequest.contactMethodTypeId == ContactMethodTypes.Phone"
                    style="width: 191.85pt;border-top: none;border-right: none;border-left: none;border-image: initial;padding: 8.65pt;vertical-align: top;">
                    <p>Phone</p>
                    <p><span style="font-size:13px;">{{ lateReviewRequest.phoneNumber }}</span></p>
                </td>
                <td *ngIf="lateReviewRequest.contactMethodTypeId == ContactMethodTypes.SMS"
                    style="width: 191.85pt;border-top: none;border-right: none;border-left: none;border-image: initial;padding: 8.65pt;vertical-align: top;">
                    <p>SMS</p>
                    <p><span style="font-size:13px;">{{ lateReviewRequest.phoneNumber }}</span></p>
                </td>
                <td
                    style="width: 191.85pt;border-top: none;border-bottom: none;border-left: none;border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    style="width: 191.85pt;border-top: none;border-bottom: 0.5pt solid rgb(191, 191, 191);border-left: 0.5pt solid rgb(191, 191, 191);border-image: initial;border-right: 0.5pt solid rgb(191, 191, 191);padding: 8.65pt;vertical-align: top;">
                    <p>{{lateReviewTermsConditionsStatement}}</p>
                    <p><span style="font-size:13px;">Yes</span></p>
                </td>
            </tr>
        </tbody>
    </table>

    <p>&nbsp;</p>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                        style="font-weight: bold;font-size:16px;">Applicant-Submitted Supporting
                        Documents</span></strong></p>
        </td>
    </tr>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 0;vertical-align: top;">
            <div class="d-flex mb-3">

                <table style="margin-bottom: 0;" class="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Filename</th>
                            <th *ngIf="showPublishedDate">Date Published</th>
                            <th>Date Submitted</th>
                            <th>Format</th>
                            <th>File Size</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="lateReviewDocuments?.length > 0">

                        <tr *ngFor="let document of lateReviewDocuments">
                            <td>
                                <div>{{getDocumentTypeName(document)}}</div>
                            </td>
                            <td>
                                {{document.documentName}}
                            </td>
                            <td *ngIf="showPublishedDate">{{document.publishedDate | date: "mediumDate"}}</td>
                            <td>{{document.uploadedDate | date: "mediumDate"}}</td>
                            <td>{{document.documentExtension}}</td>
                            <td>{{document.documentSize}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="lateReviewDocuments?.length == 0">
                        <tr>
                            <td colspan="5">No applicant-submitted supporting documents found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </td>
    </tr>

    <p>&nbsp;</p>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                        style="font-weight: bold;font-size:16px;">Review Payment Information</span></strong></p>
        </td>
    </tr>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 0;vertical-align: top;">
            <div class="d-flex mb-3">
                <table style="margin-bottom: 0;" class="table">
                    <thead>
                        <tr>
                            <th>Payment Method</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="lateReviewRequest?.payments != null && lateReviewRequest?.payments?.length > 0">
                        <tr *ngFor="let payment of lateReviewRequest.payments">
                            <td>{{lateReviewRequest.isSubmittedByRegistry?
                                "Registry":
                                payment.cardType + " ending in " + payment.cardLastFourDigits}}</td>
                            <td *ngIf="!payment.edited">{{lateReviewRequest.isSubmittedByRegistry?
                                payment.financialTransaction.transactionId :
                                (payment.financialTransactionId | transactionid) }}</td>
                            <td>
                                <div class="payment-amount-column">{{payment.amount | currency}}</div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="lateReviewRequest?.payments == null || lateReviewRequest?.payments?.length == 0">
                        <tr>
                            <td colspan="5">No review payments found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </td>
    </tr>

    <p>&nbsp;</p>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <p style="margin-bottom:.0001pt;line-height:  normal;"><strong><span
                        style="font-weight: bold;font-size:16px;">Activity Log</span></strong></p>
        </td>
    </tr>

    <tr>
        <td colspan="3"
            style="width: 575.5pt;border: 0.5pt solid rgb(191, 191, 191);padding-left: 8.65pt;padding-right: 8.65pt;padding-bottom: 8.65pt;vertical-align: top;">
            <table style="margin-left:-.25pt;border-collapse:collapse;border:none;">
                <tbody>
                    <tr *ngFor="let event of lateReviewRequest.events">
                        <td
                            style="width: 575.5pt;border-image: initial;border-top: none;padding: 8.65pt;vertical-align: top;">
                            <p>{{ event.eventDate | date: 'MMMM d, y, HH:mm' }}</p>
                            <p>by {{ event.userName }}</p>
                            <p style="margin-bottom:.0001pt;"><strong><span style="font-weight: bold;font-size:13px;">{{
                                        getEventTypeName(event.eventTypeId) }}</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</div>
