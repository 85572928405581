<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Change Review Status</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="mb-3">
                <label class="modal-body-label" for="reviewStatusTypeId">New Status</label>
                <select id="reviewStatusTypeId" 
                    name="reviewStatusTypeId" 
                    [(ngModel)]="reviewStatusTypeId"
                    class="form-select modal-select">
                    <option class="modal-select-option" [ngValue]="0">Select a Review Status</option>
                    <option class="modal-select-option"                        
                        *ngFor="let reviewStatusType of reviewStatusTypes"                         
                        [disabled]="+reviewStatusType.id==+selectedReviewStatusTypeId"
                        [ngValue]="reviewStatusType.id">{{reviewStatusType.name}}</option>
                </select>
            </div>
            <div class="mb-3">
                <label class="modal-body-label" for="eventDetails">Note</label>
                <textarea 
                    id="eventDetails" 
                    name="eventDetails"
                    [(ngModel)]="eventDetails"
                    class="modal-textarea"                                   
                    (input)="onTextareaInput($event)"></textarea>
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            </div>            
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelModalClick()" >Cancel</div>
            <div id="changeButton" class="change-button" [ngClass]="{'disabled': isChangeButtonDisabled() }" (click)="onChangeModalClick($event)">
                Change
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>